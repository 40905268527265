import { useState } from "react";
import { useForm } from "react-hook-form";
import "./Login.css"
import { styled } from "@mui/system";


import { Box, Button, Chip, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Stack, TextField, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { Visibility, VisibilityOff } from "@mui/icons-material";

//const imageURL = 'https://th.bing.com/th/id/R.14ee812e8a94871fd5ab0fec76fc7584?rik=aOFdt8E1gNv6wA&riu=http%3a%2f%2fcontent.tegna-media.com%2fphoto%2f2016%2f07%2f02%2f636030465725623304-Mideast-Egypt-Plane-May_3671351_ver1.0.jpg&ehk=%2bc%2fFSkvMORMfsGqQbTkPL8bI9XKt8TFCYP%2fCRT5VLb4%3d&risl=&pid=ImgRaw&r=0';
const imageURL = 'R.jpeg';
const imageURL0 = '36.png';


const Background0 = styled("div")({
  top:'0px',
  position:'absolute',
  width: "250px",
  height: `100px`,
  backgroundImage: `url(${imageURL0})`,
  backgroundPosition: "left",
  // backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  marginTop:0
});
const Background = styled("div")({
  top:'0px',
  position:'absolute',
  width: "100%",
  height: `calc( 100% - 1px )`,
  backgroundImage: `url(${imageURL})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  marginTop:0
});



export default function Login() {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  return (

<div >

  
  <Background /><Background0 />
    <Box sx={{  boxShadow: 3 ,bgcolor:'white'}} className="Login" >

       <Box sx={{  width: '300px',height:'50px',bgcolor:'#1976d2',color:'white',borderRadius: '8px 8px 0px 0px', }}>
      <Typography py={'10px'} className="Title"  >EGME Safety Management System</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>


        <TextField sx={{ m: 1, width: '250px',background:'#E0F4FD' }} label="Username" variant="standard" size="small"
          {...register("username")} />
        <FormControl sx={{ m: 1, width: '250px',background:'#E0F4FD'  }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }

            {...register("password")} />
        </FormControl>

        <Button type="submit" startIcon={<LoginIcon />}
          sx={{ mt: 3, mb: 4, ml: 2, mr: 2, width: '250px', textTransform: 'capitalize' }}
          variant="contained">
          Login</Button>
      </form>
    </Box >
    </div>

  );
}
