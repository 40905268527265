import { CloudUpload, Delete, Folder } from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import { useForm } from "react-hook-form";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const Resource = [
  {
    value: "Aircraft",
    label: "Aircraft",
  },
  {
    value: "Staff",
    label: "Staff",
  },
  {
    value: "Tool/Equipment",
    label: "Tool/Equipment",
  },
  {
    value: "Facilty",
    label: "Facilty",
  },
];

export default function OcceranceReport() {
  const { register, handleSubmit } = useForm();
  const [dense, setDense] = React.useState(true);
  const onSubmit = (data) => {
    console.log(data);
  };

  return (

    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        gap: 2,
      }}
      noValidate
      autoComplete="off"
    >
      <Typography >When/Where</Typography>
      <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField
          label="UTC Date"
          type="date"
          variant="filled"
          {...register("occ_date")}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="UTC Time"
          type="time"
          variant="filled"
          {...register("occ_time")}
          InputLabelProps={{ shrink: true }}
        />
        <TextField select label="Country" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="City" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Occerance Location"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />


      </Stack>

      {/*------------------------------------------------------------*/}

      <Typography >What</Typography>
      <TextField
        label="Occerance Title"
        variant="filled"
        sx={{ flex: 1 }}
        fullWidth
        {...register("header")}
      />
      <Typography >Occurrence</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField select label="Occurrence class" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="Detection phase" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="Occurrence category" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>


      </Stack>
      <Typography >Damage</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>

        <TextField
          label="Highest  damage"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />
        <TextField
          label="Third party damage"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />
      </Stack>
      <Typography >Injuries</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>

        <TextField
          label="Injury level"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />

      </Stack>
      <Typography >Aircraft Identification</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField select label="Aircraft registration" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="State of registry" variant="filled" sx={{ flex: 1 }}>
          {Resource.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Aircraft total time"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />
        <TextField
          label="Aircraft total cycles"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />


      </Stack>

      <Typography >ENGINE / PART / TOOL INFORMATION</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>

        <TextField
          label="Part Number"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />
        <TextField
          label="Serial Number"
          sx={{ flex: 1 }}
          variant="filled"
          {...register("ext_occno")}
        />


      </Stack>
      <Typography >NARRATIVE ( Description )</Typography>

      <Stack sx={{ gap: 2 }} direction={"row"}>

        <TextField
          label="Narrative  text"
          sx={{ flex: 1 }}
          multiline
          maxRows={4}
          variant="filled"
          {...register("ext_occno")}
        />


      </Stack>



      <Box>
        <FormLabel component="legend" sx={{ marginBottom: "5px" }}>
          Cause of Occerance
        </FormLabel>
        <Stack
          spacing={{ xs: 4, sm: 4, md: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          sx={{ width: "80ch", minWidth: 0, pl: "25px" }}
        >
          <FormControlLabel control={<Checkbox />} label="Design          " />
          <FormControlLabel control={<Checkbox />} label="Maintenace      " />
          <FormControlLabel control={<Checkbox />} label="Fatigue         " />
          <FormControlLabel control={<Checkbox />} label="Unapproved Parts" />
          <FormControlLabel control={<Checkbox />} label="Operational     " />
          <FormControlLabel control={<Checkbox />} label="Prodction       " />
          <FormControlLabel control={<Checkbox />} label="Repair          " />
          <FormControlLabel control={<Checkbox />} label="Corrosion       " />
          <FormControlLabel control={<Checkbox />} label="Human Factor    " />
          <FormControlLabel control={<Checkbox />} label="Not Determined  " />
          <FormControlLabel control={<Checkbox />} label="Others.." />
        </Stack>
      </Box>

      <Box>
        <FormLabel component="legend" sx={{ marginBottom: "5px" }}>
          Classification
        </FormLabel>
        <Stack sx={{ width: "80ch", minWidth: 0, pl: "25px" }}>
          <FormControlLabel control={<Checkbox />} label="Hazard ID       " />
          <FormControlLabel control={<Checkbox />} label="Accdent/Incident" />
          <FormControlLabel control={<Checkbox />} label="Confidential  " />
          <FormControlLabel control={<Checkbox />} label="Publish" />
        </Stack>
      </Box>
      <Stack direction={'row'}>

        <TextField type="file" sx={{ width: '50%' }} />
        <Button component="label" variant="contained" sx={{ width: '250px' }} startIcon={<CloudUpload />}>
          Upload file

        </Button>
      </Stack>
      <TextField
          label="Attached files"
          sx={{ flex: 1 }}
          multiline
          minRows={4}
          variant="filled"
          {...register("ext_occno")}
        />
       <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Attached files
          </Typography>
          <Demo>
            <List dense={dense}>
              {generate(
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  }
                >

                  <ListItemText
                    primary="Single-line item"
                   
                  />
                </ListItem>,
              )}
            </List>
          </Demo>
        </Grid>





      <Box sx={{ textAlign: "center" }}>
        <Button
          type="submit"
          sx={{ textTransform: "capitalize" }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
