import React from "react";
import Card from "../../components/Card";



export default function Menu(props) {

  var priv=4;
  const cards = props.data.map((item, index) => {
    if (item.priv.length>=priv)
    {
    return <Card key={index} title={item.title} path={item.path} />;
    }
  });
  console.log(cards);

  return (
    <div>
   
      <div className="main-view">
      
        <div className="main">{cards}</div>
      </div>
    </div>
  );
}
