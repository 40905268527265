export default [
  {
    title: "Hazard identification",
    path: "/HazardIdetification",
    priv: "user",
  },
  {
    title: "Risk assessment & mitigation",
    path: "/RiskAssessment",
    priv: "user",
  },
];
