export default [
  {
    title: "Training and education",
    path: "/Training_and_education",
    priv: "user",
  },
  {
    title: "Safety communication",
    path: "/Safety_communication",
    priv: "user",
  },
   
];
