import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { DataGrid } from '@mui/x-data-grid';
import { FormLabel, Grid, Paper } from '@mui/material';
import OcceranceReport from '../occerancereport/OcceranceReport';

const rows = [
    { id: 1, col1: '1914', col2: 'CARGO FIRE BOTTLE DISCHARGED' },
    { id: 2, col1: '1912', col2: 'MLG WHEEL' },
    { id: 3, col1: '1915', col2: 'OIL FILTER BYPASS ENG#1 SHUTDOWN INFLIGHT' },
];

const columns = [
    { field: 'col1', headerName: 'Occ No', width: 150 },
    { field: 'col2', headerName: 'Title', width: 300 },
];

export default function Task() {
    return (
        <Grid container alignItems="stretch" spacing={1}>
            <Grid item xs={3}>
                <Paper elevation={3}>
                <FormLabel component="legend" >
          Search
        </FormLabel>
                    <div style={{ height: '80vh', width: '100%' }}>
                    
                        <DataGrid rows={rows} columns={columns} />
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={9}>
                <Paper elevation={3}>
                   
               <OcceranceReport />
                </Paper>
            </Grid>
        </Grid>
    )
}

