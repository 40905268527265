
import "../components/Card.css";
import { Link } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";

export default function Card(props) {
  const theme = useTheme();
  return (
    <Box sx={{
      boxShadow: 4, bgcolor: theme.palette.mode === "light" ? '#E0F4FD' : 'text.secondary',
    }} className="card">
      
      <Link to={props.path}>

        <Typography  > {props.title}</Typography>


      </Link>

    </Box>
  );
}
