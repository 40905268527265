import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App1 from "./App1";

import Login from "./pages/login/Login";
//////
import Login_as from "./Login_as";
import Login_first_time from "./Login_first_time";
import Login2 from "./Login2";
import Need_ride_create_request from "./Need_ride_create_request";
import Need_ride_filter from "./Need_ride_filter";
import Need_ride_main from "./Need_ride_main";
import Need_ride_messages from "./Need_ride_messages";
import Register from "./Register";
import Start from "./Start";
 
/////
import OcceranceReport from "./pages/OcceranceReport";
import OcceranceReport1 from "./pages/occerancereport/OcceranceReport";
import AddOcceranceReport from "./pages/occerancereport/AddOcceranceReport";
import UpdateOcceranceReport from "./pages/occerancereport/UpdateOcceranceReport";
import SmsAdminDashBoard from "./pages/occerancereport/SmsAdminDashBoard";
import PrintInitialReport from "./pages/occerancereport/PrintInitialReport";

import AdminPageOcceranceReport from "./pages/occerancereport/AdminPageOcceranceReport";
import AdminPageOcceranceReport_Analysis from "./pages/occerancereport/AdminPageOcceranceReport_Analysis";


import Task from "./pages/tasks/Task";
import Sidebar2 from "./components/SideBar2";
import Menu from "./pages/menu/Menu";
import maindata from "./menudata/maindata";
import SafetyPolicyData from "./menudata/SafetyPolicyData";
import SafetyRiskData from "./menudata/SafetyRiskData";
import SafetyAssuranceData from "./menudata/SafetyAssuranceData";
import SafetyPromotionData from "./menudata/SafetyPromotionData";
import ReportsData from "./menudata/ReportsData";
import PrintReport from "./menudata/PrintReport";


import OcceraneReportData from "./menudata/OcceraneReportData";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App1 />}>
        <Route index element={<Menu data={maindata}/>} />
        <Route path="SafetyPolicy" element={<Menu data={SafetyPolicyData} />} />
        <Route path="SafetyRisk" element={<Menu data={SafetyRiskData} />} />
        <Route path="SafetyAssurance" element={<Menu data={SafetyAssuranceData} />} />
        <Route path="SafetyPromotion" element={<Menu data={SafetyPromotionData} />} />
        <Route path="Reports" element={<Menu data={ReportsData} />} />
        <Route path="PrintReport" element={<Menu data={PrintReport} />} />
        <Route path="AddOcceranceReport" element={<AddOcceranceReport />} />
        <Route path="OcceranceReport" element={<Menu data={OcceraneReportData} />} />
        <Route path="UpdateOcceranceReport" element={<UpdateOcceranceReport />} />
        <Route path="SmsAdminDashBoard" element={<SmsAdminDashBoard />} />
        <Route path="PrintInitialReport" element={<PrintInitialReport />} />
        <Route path="AdminPageOcceranceReport" element={<AdminPageOcceranceReport />} />
        <Route path="AdminPageOcceranceReport_Analysis" element={<AdminPageOcceranceReport_Analysis />} />
        <Route path="task" element={<Task />} />
        <Route path="CoordinationERP" element={<Sidebar2 />} />
      </Route>
      <Route path="/login" element={<Login />} />

      <Route path="/Start" element={<Start />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Login_as" element={<Login_as />} />
      <Route path="/Login_first_time" element={<Login_first_time />} />
      <Route path="/Login2" element={<Login2 />} />
      <Route path="/Need_ride_create_request" element={<Need_ride_create_request />} />
      <Route path="/Need_ride_filter" element={<Need_ride_filter />} />
      <Route path="/Need_ride_main" element={<Need_ride_main />} />
      <Route path="/Need_ride_messages" element={<Need_ride_messages />} />
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
