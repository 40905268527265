import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    gridClasses,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
  } from "@mui/material";
  import { useTheme } from "@mui/material";
  import { DataGrid, GridToolbar,useGridApiRef } from "@mui/x-data-grid";
  import { useNavigate } from "react-router-dom";
  
  import { useEffect, useState,useRef } from "react";
  import { green, red } from "@mui/material/colors";
  
   
  import Cookies from 'universal-cookie';
  import axios from "axios";
  
  const rows = [
    {
      id: 1,
      lastName: "عبد الحميد احمد محمد ",
      firstName: "محمد احمد",
      type: "update",
      age: 14,
    },
    { id: 2, lastName: "Lannister", firstName: "Cersei", type: "true", age: 31 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", type: "true", age: 31 },
    { id: 4, lastName: "Stark", firstName: "Arya", type: "true", age: 11 },
    {
      id: 5,
      lastName: "Targaryen",
      firstName: "Daenerys",
      type: "true",
      age: null,
    },
  ];
  
  
  export default function PrintInitialReport() {
  
    const [rowid, setRowId] = useState(null);
    const  thecode = useRef("");
    const the_row_data=useRef([]);
    const apiRef1 = useGridApiRef();
    const apiRef2 = useGridApiRef();
    const apiRef3 = useGridApiRef();
    const theme = useTheme();
  
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [students2, setStudents2] = useState([]);
    const [students3, setStudents3] = useState([]);
    
    const cookies = new Cookies();
    var usercookie="";
    var passcookie="";
    //navigate("/login"); 
  
    useEffect(() => {
      getInitialReports("xx");
 
    }, []);

    const getInitialReports = async (xx) => {
       const post = {
        rpnum : xx ,
      };
          
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];
  
       res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/getallreportsforinitialprint',
        
       post); 
     
       console.log("res.data");
       console.log(res.data); 
      
      setStudents(res.data);
    };
  
    const printReport  = async (xx) => {
      const post = {
       rpnum : xx ,
     };
         
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
 
      res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/printinitialreport',
       
      post); 
    
      console.log("generated_file");
      console.log(res.data[0].val); 
      var red=res.data[0].val;

      window.location.href = red;

     /*
     setStudents(res.data);
     */
   };
 
  
    const rows1 = students.map((student) => ({
  
      report_type : student.report_type,
      UTC_time : student.UTC_time,
      signature : student.signature,
      id : student.id,
      report_name : student.report_name,

      Headline : student.Headline,
      ac_registeration : student.ac_registeration,
      state_of_registry : student.state_of_registry,
      part_name : student.part_name,
      part_number : student.part_number,

       
      
    }));
     
    const columns = [
      { field: "id", headerName: "ID", width: 90 },
      { field: "signature",headerName: "Code",width: 150, editable: true,},
      { field: "report_type",headerName: "Report",width: 150, editable: true,},
      { field: "report_name",headerName: "Report Number",width: 150, editable: true,},
      { field: "UTC_time",headerName: "Date & Time",width: 150, editable: true,},
      
      { field: "Headline",headerName: "Headline",width: 250, editable: true,},
      { field: "ac_registeration",headerName: "A/C Registeration",width: 150, editable: true,},
      { field: "state_of_registry",headerName: "State of Registry",width: 150, editable: true,},
      { field: "part_name",headerName: "part Name",width: 150, editable: true,},
      { field: "part_number",headerName: "Part Number",width: 150, editable: true,},
       
    ];
    
   
    const show_report=()=>
    {
      const rows2 = apiRef1.current.getSelectedRows();
      var xx;
      rows2.forEach((values, keys) => {
       xx=values.report_name;
       the_row_data.current=values;
       })
       if ( (xx===undefined)  || (xx===null))
       {
        alert("Please Select   Row");
        return;
       }
       printReport(xx);
       console.log("print" );
       console.log(xx );
 
    }
     
    
   
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent:'center',
          //alignItems:'center',
          gap: 2,
        }}
      >
        <Box 
        sx={{color:green[700],
          display:'flex' ,
           justifyContent:"center",
           alignItems:'center',
          flexDirection:'row',
          height:'100px',
          width:'100%',
          }}   
        
       
       >
          Initial Report 
        </Box>
        <Box
        sx={{
          display: "flex",
          justifyContent:'center',
          flexDirection: "row",
          alignItems:"center",
          gap: 2,zIndex:100,
        }}
      >
       
       <Button
         
         sx={{
           width: "200px",
           fontSize: "15",
           fontWeight: "900",
           textTransform: "capitalize",
           backgroundColor: green[200],
         }}
         onClick={() => {
          // navigate("/addstudent");  updatestudentsstaticaccept  student_code  
        
          show_report();
         }}
       >
         Print Report
       </Button>
        
        </Box>

        <Box
        sx={{
          display: "flex",
         // justifyContent:'center',
          flexDirection: "column",
         // alignItems:"center",
          gap: 2,
          width:'100%',
         
        }}
      >
  
       
          <div style={{ height: "80%", width: "100%",marginBottom:"50px" }}>
          All Reports
            <DataGrid
              apiRef={apiRef1}
              rows={rows1}
              columns={columns}
              density="standard"
              sx={{
                [`& .${gridClasses.item}`]: {
                  bgcolor: theme.palette.mode === "light" ? green : red,
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              slots={{ toolbar: GridToolbar }}
              getRowSpacing={(Params) => ({
                top: Params.isFirstVisible ? 0 : 5,
                bottom: Params.isLastVisible ? 0 : 5,
              })}
              onRowSelectionModelChange={(x) => {
                setRowId(x);
                console.log(x);
                if (x===null)
                {
                  alert("please confirm row selection");
                }
              }}
            />
          </div>
         
 
      </Box> 
      
      </Box>
    );
  
   
  }
  