import React from "react";
import Login from "./Login2";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Menus from "./components/Menus";
import maindata from "./menudata/maindata";
import SafetyPolicyData from "./menudata/SafetyPolicyData";
import SafetyRiskData from "./menudata/SafetyRiskData";
import SafetyAssuranceData from "./menudata/SafetyAssuranceData";
import SafetyPromotionData from "./menudata/SafetyPromotionData";
import ReportsData from "./menudata/ReportsData";
import OcceraneReportData from "./menudata/OcceraneReportData";
import OcceranceReport from "./pages/OcceranceReport";

import "./App.css";

function App() {
  return (
    <div className="main-app">
     
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Menus data={maindata} />} />
          <Route path="/SafetyPolicy" element={<Menus data={SafetyPolicyData} />} />
          <Route path="/SafetyRisk" element={<Menus data={SafetyRiskData} />} />
          <Route path="/SafetyAssurance" element={<Menus data={SafetyAssuranceData} />} />
          <Route path="/SafetyPromotion" element={<Menus data={SafetyPromotionData} />} />
          <Route path="/Reports" element={<Menus data={ReportsData} />} />
          <Route path="/group5" element={<Menus />} />
          <Route path="/group6" element={<Menus />} />
          <Route path="/group7" element={<Menus />} />
          <Route path="/AddOcceranceReport" element={<OcceranceReport />} />
          <Route path="/OcceranceReport" element={<Menus data={OcceraneReportData} />} />
   
        </Routes>
    
    </div>
  );
}

export default App;
