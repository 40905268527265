export default [
    {
      title: "Initial Report",
      path: "/PrintInitialReport",
      priv: "user",
    },
    {
      title: "Final Report",
      path: "/Final_Report",
      priv: "user",
    },
    {
      title: "Analysis Report",
      path: "/Analysis_Report",
      priv: "admin",
    }
     
    
  ];
  