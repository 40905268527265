import { CloudUpload } from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import {
  Box,
  Button,
  Select,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef } from "react";

import { useForm } from "react-hook-form";


import Country from "../../country.js";
import Resource from "../../resource.js";
import { brown, green ,grey,red,blue,blueGrey} from "@mui/material/colors";
import Cookies from 'universal-cookie';
 
import axios from "axios";

import "../../matrix.css";


function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Occurrence_Category = [
  {
  value: "Select Occurrence Category",
  label: "Select Occurrence Category",
},{
  value: "Grounded",
  label: "Grounded",
},
{
  value: "Delay",
  label: "Delay",
},
{
  value: "Return to Stand",
  label: "Return to Stand",
},
{

  value: "Divert",
  label: "Divert",
},
{
  value: "Return  from flight",
  label: "Return  from flight",
},]

const Detection_Phase = [
  {
  value: "Select Detection Phase",
  label: "Select Detection Phase",
},
  {
    value: "Operation",
    label: "Operation",
  },
  {
    value: "Taxi",
    label: "Taxi",
  },
  {
    value: "Take-off",
    label: "Take-off",
  },
  {
    value: "Climb",
    label: "Climb",
  },
  {
    value: "Enroute",
    label: "Enroute",
  },
  {
    value: "Descent",
    label: "Descent",
  },
  {
    value: "Approach",
    label: "Approach",
  },
  {
    value: "Landing",
    label: "Landing",
  },
  {
    value: "Hovering",
    label: "Hovering",
  },
  {
    value: "Ground Handling",
    label: "Ground Handling",
  },
  {
    value: "Other",
    label: "Other",
  },
]

const Occurrence_Class = [
  
  {
    value: "Select Occurrence Class",
    label: "Select Occurrence Class",
  },
  {
    value: "Accident",
    label: "Accident",
  },
  {
    value: "Incidet",
    label: "Incidet",
  },
  {
    value: "Serious Incident",
    label: "Serious Incident",
  },
  {
    value: "Technicaal Occurrence Report",
    label: "Technicaal Occurrence Report",
  },
]
const Report_Type = [
  
  {
    value: "Select Report Type",
    label: "Select Report Type",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]
const Resource_ac_reg=[
  {
  value: "Select Aircraft Registeration",
  label: "Select Aircraft Registeration",
  }
]


export default function AdminPageOcceranceReport() {

  const API_URL = process.env.REACT_APP_BASE_URL+"/api/Product/posted_filesAsync_admin_update";
  const API_URL_rprt_action_attachment = process.env.REACT_APP_BASE_URL+"/api/Product/getattachmentforreportbyrpnum";

  const API_METHOD = 'POST'
  const STATUS_IDLE = 0
  const STATUS_UPLOADING = 1
  const [files, setFiles] = React.useState([])
//=================================
const [dat, setdat] = React.useState('');
const [tim, settim] = React.useState('');
const [country, setcountry] = React.useState('');
const [cityval, setcityval] = React.useState('');
const [location, setlocation] = React.useState('');
const [headline, setheadline] = React.useState('');
const [type, settype] = React.useState('');
const [theclass, setclass] = React.useState('');
const [phase, setphase] = React.useState('');
const [category, setcategory] = React.useState('');
const [highestd, sethighestd] = React.useState('');
const [thirdpd, setthirdpd] = React.useState('');
const [injury, setinjury] = React.useState('');
const [acregis, setacregis] = React.useState('');
const [stateofreg, setstateofreg] = React.useState('');
const [thours, setthours] = React.useState('');
const [tcycles, settcycles] = React.useState('');
const [pnum, setpnum] = React.useState('');
const [ser, setser] = React.useState('');
const [NARRATIVE ,setNARRATIVE] = React.useState('');
const  the_NARRATIVE  =  useRef('');

//=================================
const [action ,setaction] = React.useState([]);
const [attachment ,setattachment] = React.useState([]);
const  theaction = useRef([]);
const  theattachment = useRef([]);
const  theselectedreport  = useRef('');
//=======================================
  const [ac, setac] = React.useState([])
  const [thecity, setcity] = React.useState([]);
  const [hours, sethours] = React.useState('');
  const [cycles, setcycles] = React.useState('')
 
  const [acval,setacval]= React.useState('Select A/C');

  const [Cityval, setCityval] = React.useState('Select City');
  const [Countryval, setCountryval] = React.useState('Select Country');

  const [status, setStatus] = React.useState(STATUS_IDLE)
  const [theres, settheres] = React.useState("");
  const ac_lines=useRef([]);

  const TheOtherAc=useRef("");
  const [TheOtherAcus, setTheOtherAcus] = React.useState("");
  
  
  const the_Report_data=useRef([]);
  
  const [ShowOtherAC, setShowOtherAC] = React.useState(false);

  const city = React.useRef( [ {"city": "Select City"},{"city": "cairo"},]);


  const [directorateval, setdirectorateval] = React.useState('Select Directorate');
  const [departmentval, setdepartmentval] = React.useState('Select Department');
  const directorate = React.useRef( [ {"directorate": "Select Directorate"},]);
  const department = React.useRef( [ {"department": "Select Department"}, ]);

  const [directorate_us, setdirectorate_us] = React.useState([]);
 


  const [Mandatory_Or_Not_us, setMandatory_Or_Not_us] = React.useState([])
  const Mandatory_Or_Not = React.useRef( [{value: "0",label: "Not Mandatory",}, ] );
  
  const [Mandatory_Or_Not_val, setMandatory_Or_Not_val] = React.useState('')
  
  const get_reportable=async(data)=>
    {
      const api_lst=process.env.REACT_APP_BASE_URL+"/api/Product/getreportableslist";
      const post = {
        country : data,
      };
      
    
       let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( api_lst, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
   
       var temp_mat1=[];
       temp_mat1.push({value: "0",label: "Not Mandatory",});
       
       
      for (let y=0;y<cc.length;y++)
      {
        temp_mat1.push({"value": cc[y].number , "label":cc[y].reportableittem });
      }
      Mandatory_Or_Not.current=temp_mat1;
      console.log(Mandatory_Or_Not.current);
  
      setMandatory_Or_Not_us(Mandatory_Or_Not.current);
  /* */   
  get_ac(" ");
  
    }

  const uploadFiles = async (data)=> {
      setStatus(STATUS_UPLOADING);

      const response1 = await fetch(API_URL, {
          method: API_METHOD,
          body: data,
      })
      .then((res) => res.json())
          
      .then((data) => {
        console.log(data);
     
        console.log(data[0].val);
      })
      .catch((err) => console.error(err))
      .finally(() => 
      {
        setStatus(STATUS_IDLE);
       
        alert("Upload done"+theres);
      })

     // const result = await response1.json();console.log(result);  
  }

  const packFiles = (files,data1)=> {
      const data = new FormData();
      
      [...files].forEach((file, i) => {
          data.append(`file-${i}`, file, file.name)
      })
      console.log("data1 before append");
      console.log(data1);
      data.append('occ_date', dat);
      data.append('occ_time', tim);
      data.append('country', Countryval);
      data.append('city', Cityval);
      data.append('ext_occno', directorateval+"*"+departmentval );
      data.append('header', headline);
      data.append('occ_class', theclass);
      data.append('detection_phase', phase);
      data.append('occ_category', category);
      //  dat   tim Countryval Cityval  location headline type
             // theclass phase category highestd thirdpd injury acval
             // stateofreg hours cycles pnum  ser NARRATIVE

      console.log("dat "+dat+" tim "+tim+" Countryval "+Countryval+" Cityval "+Cityval+
                  " location "+location+" headline "+headline+ " type " + type +
                  " theclass "+theclass+" phase "+phase+" category "+ category+
                  " highestd "+highestd+" thirdpd "+thirdpd+" injury "+injury+
                  " acval "+acval+ "  stateofreg "+stateofreg+" hours  "+hours+
                  "  cycles "+cycles+" pnum "+pnum+" ser  "+ser+
                  "  NARRATIVE "+NARRATIVE+" OtherAc "+ TheOtherAcus ); 

      data.append('highest_damage', highestd);
      data.append('third_party_damage', thirdpd);
      data.append('injury_level', injury);
      data.append('ac_reg', acval);
      data.append('state_reg', stateofreg);
      data.append('ac_total_h', hours);
      data.append('ac_total_c', cycles);
      data.append('part_number', pnum);
      data.append('serial_number', ser);
      data.append('narrative_text', NARRATIVE);
      data.append('report_type', type);
      data.append('OtherAc', TheOtherAcus );//   TheOtherAc.current);rpnum
      data.append('rpnum', usercookie );//   TheOtherAc.current);rpnum
 
      data.append('Mandatory_Or_Not',  Mandatory_Or_Not_val); 

      data.append('reporter', '65479');
      data.append('user_id', '65479');
      console.log("data"); 
      console.log(data);
      return data
  }

  const handleUploadClick = () => {
      if (files.length) {
          const data = packFiles(files)
          uploadFiles(data)
      }
  }

  const renderFileList = () => (
  <ol className="flist">
      {[...files].map((f, i) => (
          <li key={i}>
            {f.name} 
            {/* - {f.type} */}
            </li>
      ))}
  </ol>)

  const renderButtonStatus = () => (
      (status === STATUS_IDLE) ? 
          'Send to server' : 
          <img src = "load.svg" style={{height:"40px"}} />
  )
 
  const { register, handleSubmit } = useForm();
  const [dense, setDense] = React.useState(true);
  const the_ac_list=useRef(
    [
      {
        "ac": "Select A/C"
      },
      
  ]);

  const onSubmit = (data1) => {

    console.log(data1);

   // if (files.length) {}
      const data = packFiles(files,data1)
      uploadFiles(data)
  

  };


  const get_ac=async(data)=>
  {
    const api_ac=process.env.REACT_APP_BASE_URL+"/api/Product/get_ac_list";

    const response1 = await fetch( api_ac , {
      method: API_METHOD,
      body: data,
    })
    .then((res) => res.json())
      
    .then((data) => {

    //console.log(data[0].val);
    var data_total=data[0].val;
    ac_lines.current =data_total.split(';');
    // console.log(ac_lines);
    //  const the_ac_list=useRef([{"ac": "Select A/C"},]);
    the_ac_list.current=[];
    the_ac_list.current.push({"ac":"Select A/C"});
    for (let y=0;y<ac_lines.current.length-1;y++)
    {
      the_ac_list.current.push({"ac": ac_lines.current[y].split(',')[0]});
    }
    the_ac_list.current.splice(124, 1);
    the_ac_list.current.push({"ac": "Other"});
    console.log(the_ac_list.current);

    setac(the_ac_list.current);
    })
    .catch((err) => console.error(err))
    .finally(() => 
    {
    
    })
    get_report_data(usercookie);
  }
  
  const get_city=async(datac)=>
  {
   // https://inventory.internet-online.net/api/Product/selectcitiesforcountry

   const post = {
    country : datac,
  };
  

   const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectcitiesforcountry";
   let res = axios.create();
   res.defaults.timeout = 10000;
   //--------------------------------
   axios.defaults.headers.post["Content-Type"] =
     "application/x-www-form-urlencoded";
   axios.defaults.transformRequest = [
     function (data, headers) {
       var str = [];
       for (var p in data)
         if (data.hasOwnProperty(p) && data[p]) {
           str.push(
             encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
           );
         }
       return str.join("&");
     },
   ];

    res  = await axios.post( api_ac, post); 
   let cc= res.data;//['data'];  
 
   console.log("cc"); 
   console.log(cc); 
   city.current=[];
   city.current.push({"city": "Select City"});
   for (let y=0;y<cc.length;y++)
   {
    city.current.push({"city": cc[y].name});
   }
   console.log(city.current);
   setcity(city.current);
   
   

  }
  const get_directorate=async( )=>
    {
     // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
      
     const post = {
      country : " ",
    };
    
  
     const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectdirectorate";
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( api_ac, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
     directorate.current=[];
     directorate.current.push( {"directorate": "Select Directorate"});
     for (let y=0;y<cc.length;y++)
     {
      directorate.current.push({"directorate": cc[y].directorate_val    });
     }
     console.log(directorate.current);
    // setdirectorateval(directorate.current);
       
  
  }
  const get_department=async(datac)=>
  {
       // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
    
       const post = {
        directorate : datac,
      };
      
    
       const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectdepartments";
       let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( api_ac, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
       department.current=[];
       department.current.push({"department": "Select Department"});
       for (let y=0;y<cc.length;y++)
       {
        department.current.push({"department": cc[y].department_val  });
       }
        console.log("department.current");
        console.log(department.current);
       setdirectorate_us(department.current);
       setdepartmentval("Select Department");
     
  }
  const get_department2=async(datac,dep)=>
    {
         // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
      
         const post = {
          directorate : datac,
        };
        
      
         const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectdepartments";
         let res = axios.create();
         res.defaults.timeout = 10000;
         //--------------------------------
         axios.defaults.headers.post["Content-Type"] =
           "application/x-www-form-urlencoded";
         axios.defaults.transformRequest = [
           function (data, headers) {
             var str = [];
             for (var p in data)
               if (data.hasOwnProperty(p) && data[p]) {
                 str.push(
                   encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
                 );
               }
             return str.join("&");
           },
         ];
      
          res  = await axios.post( api_ac, post); 
         let cc= res.data;//['data'];  
       
         console.log("cc"); 
         console.log(cc); 
         department.current=[];
         department.current.push({"department": "Select Department"});
         for (let y=0;y<cc.length;y++)
         {
          department.current.push({"department": cc[y].department_val  });
         }
          console.log("department.current");
          console.log(department.current);
         setdirectorate_us(department.current);
         setdepartmentval(dep);
       
    }
     
  const cookies = new Cookies();
  var usercookie = cookies.get('amosmobility1rep');
   
  const get_report_data = async(xx) => {
    var tt="65479";
    const post = {
      rpnum : xx,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getreportbyrpnum', post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
      
     the_Report_data.current=cc;

     console.log("cc length"); 
     console.log(cc.length); 
    
     
   //  the_Reports.current.push({"report_name": "Select Report" });
     
   set_report_values(the_Report_data.current[0]);
      //setReportval('Select Report');
  
  }

  const set_report_values = (data) => {
    // alert ( event.target.value );
    console.log("Selected report"); 
    console.log(data ); 

   
    console.log("data.UTC_date"); 
    console.log(data.UTC_date); 
    
 
    setdat(data.UTC_date);
    settim(data.UTC_time);


    setcountry(data.State_of_occ_Level1);
    setCountryval(data.State_of_occ_Level1);
    setCityval(data.area_of_occ_level2);
    setcityval(data.area_of_occ_level2);


    setlocation(data['Location name']);
    console.log("data['Location name'].split('*')[0]");
    console.log(data['Location name'].split('*')[0]);
    
    setdirectorateval(data['Location name'].split('*')[0]);

    console.log("data['Location name'].split('*')[1]");
    console.log(data['Location name'].split('*')[1]);
    //-----------------------------------------------------------

    //setdepartmentval(data['Location name'].split('*')[1]);
    get_department2(data['Location name'].split('*')[0],data['Location name'].split('*')[1]);
    //-------------------------------------------------------------
    setheadline(data.Headline);
    settype(data.report_type);
    setclass(data.Occurrence_class);
    setphase(data.Detection_phase);
    setcategory(data.Occurrence_category);
    sethighestd(data.highest_damage);
    setthirdpd(data.third_party_damage);
    setinjury(data.injury_level);
   
    setstateofreg(data.state_of_registry);
    sethours(data.Aircraft_total_time);
    setcycles(data.Aircraft_total_cycles);
    setpnum(data.part_number);
    setser(data.part_serial);
    setMandatory_Or_Not_val(data.signature_name);


    the_NARRATIVE.current=data.NARRATIVE ;
    var ac_controlled=false;

    for (let rr=0;rr<the_ac_list.current.length;rr++)
    {
      if (the_ac_list.current[rr].ac==data.ac_registeration)
      {
        ac_controlled=true;
        break;
      }
    }


    if(ac_controlled==true)
    {
        setacval(data.ac_registeration);
        setShowOtherAC(false);
    }
   else{
    setacval("Other");
    setShowOtherAC(true);
    setTheOtherAcus(data.ac_registeration);
   }

    get_action(usercookie);

  }

  const get_action=async(trt)=>
  {
    
     
      const post = {
        rpnum : trt,
      };
      
     let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( API_URL_rprt_action_attachment, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
        
       theaction.current=cc;
  
       console.log("cc length"); 
       console.log(cc.length); 
      
       theaction.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theaction.current.push(cc[y].action );
        console.log("theaction.current"); 
        console.log(theaction.current); 
      
       }

       
  
       
      
       theattachment.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theattachment.current.push(cc[y].attachements );
        console.log("theattachment.current"); 
        console.log(theattachment.current); 
      
       }

       //  attachements
       console.log("getting action"); 
      

       //  setNARRATIVE
       for (let pp=0;pp<theaction.current.length;pp++)
       {
        the_NARRATIVE.current=the_NARRATIVE.current + "\r\n" +  theaction.current[pp]+"\r\n";
       }
       setNARRATIVE(the_NARRATIVE.current);

       setaction(theaction.current);
       setattachment(theattachment.current);
    
  }

  var theselectedcolor=red[200];
  var thediv_id1="c"+5+"a";
  var thediv_id2="c"+4+"d";


  useEffect(()=>
  {
    //--------------------------------------------
   // document.getElementById(thediv_id1).style.color= theselectedcolor ; 
   // document.getElementById(thediv_id2).style.color= theselectedcolor ; 
    //----------------------------------------------
    get_directorate(" "); 
   console.log("transferred");
   console.log(usercookie);
   get_reportable(" "); 
  
  },[]);

  const handle_country_Change = (event) => {
    setCountryval(event.target.value);
    get_city(event.target.value);
   // alert ( event.target.value );
  };
  const handle_city_Change = (event) => {
    setCityval(event.target.value);
   // alert ( event.target.value );
  };
  
  const handle_directorate_Change = (event) => {
    setdirectorateval(event.target.value);
    get_department(event.target.value);
   // alert ( event.target.value );
  };
 
  const handle_department_Change = (event) => {
    setdepartmentval(event.target.value);
   // alert ( event.target.value );
  };
 
  const handle_ac_Change = (event) => {

   // hours.current cycles.current
   if (event.target.value.trim()==="Other")
   {

    alert ( "Other" );
    setacval(event.target.value);
    sethours("");
    setcycles("");
    console.log("return");
    setShowOtherAC(true);
    return ;
   }

   setShowOtherAC(false);

   console.log("not return");

   for (let y=0;y<ac_lines.current.length-1;y++)
   {
    console.log(ac_lines.current[y].split(',')[0]);
     if (ac_lines.current[y].split(',')[0]===event.target.value)
     {
      console.log('found');
      sethours(ac_lines.current[y].split(',')[1]);

      setcycles(ac_lines.current[y].split(',')[2]);
         
    
 
     }  
   }

    setacval(event.target.value);


    alert ( event.target.value );
  };
  
  const Close =(event)=>
  {

  };

 
  return (

    <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <Box

      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}

    >
      <Box  sx={{  display: "flex",  alignItems: "center",
                  width:'100%',justifyContent:'center',
                   flexDirection: "row",  gap: 2,}} >
       Admin Occurance Report
      </Box>
      <Paper
        elevation={17}
        sx={{
          p: "20px",
          width: "100%",
          minWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          fontSize: "25px",
          fontWeight: 800,
        }}
      >
        <Typography >When/Where</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField
            label="UTC Date"
      
            defaultValue={dat}
            value={dat}
            onChange={(e)=>{setdat(e.target.value);}}
            variant="filled"
            
            InputLabelProps={{ shrink: true }}
          />
         
          <TextField
            label="UTC Time"
             
            variant="filled"
            defaultValue={tim}
            value={tim}
            onChange={(e)=>{settim(e.target.value);}}
            
            InputLabelProps={{ shrink: true }}
          />
          <FormControl  variant="filled" style={{marginTop:0}} sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label"
           id="demo-simple-select-autowidth"
           autoWidth
           label="Country"   
           defaultValue={Countryval} 
           value={Countryval}  
           onChange={handle_country_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            Country.map((option ) => (
            
              <MenuItem 
              key={option.country_name} 
              value={option.country_name}>
                {option.country_name }
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
           {/* <InputLabel id="demo-simple-select-label2">City</InputLabel>
           */}
          <TextField
           labelId="demo-simple-select-autowidth-label2"
           id="demo-simple-select-autowidth2"
           autoWidth
           label="City"
           defaultValue={Cityval} 
           value={Cityval}  
           onChange={handle_city_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            city.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.city} 
              value={option.city}>
                    {option.city}
              </MenuItem>
          
            ))
           }    
           
          </TextField>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label21">Directorate</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label21"
           id="demo-simple-select-autowidth21"
           autoWidth
           label="Directorate" 
           value={directorateval}  
           onChange={handle_directorate_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            directorate.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.directorate} 
              value={option.directorate}>
                    {option.directorate}
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label22">Department</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label22"
           id="demo-simple-select-autowidth22"
           autoWidth
           label="Department" 
           value={departmentval}  
           onChange={handle_department_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            department.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.department} 
              value={option.department}>
                    {option.department}
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

           {/*  <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
         
       <TextField
           labelId="demo-simple-select-autowidth-label2loc"
           id="demo-simple-select-autowidth2loc"
           autoWidth
            label="Occerance Location" 
            onChange={(e)=>{setlocation(e.target.value)}  }  
            value= {location} 
           defaultValue={location}
           >
            
          </TextField>
          </FormControl>  */}


        </Stack>

        {/*------------------------------------------------------------*/}

        <Typography >What</Typography>
        <TextField
          label="Occerance Title"
          variant="filled"
          value={headline} 
          sx={{ flex: 1 }}
          fullWidth
          onChange={(e)=>{setheadline(e.target.value)}}
        />

        <Typography >Report Type</Typography>
        <Select   
        labelId="demo-simple-select-autowidth-label3"
        id="demo-simple-select-autowidth3"
        autoWidth
          label="Report Type" 
          defaultValue={type}
          value={type} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>settype(e.target.value)}
          >
            {Report_Type.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>

         {/*------------------------------------------------------------*/}
         <Typography >Mandatory Or Not</Typography>
        
        <Select   
        labelId="demo-simple-select-autowidth-label321"
        id="demo-simple-select-autowidth321"
        autoWidth
          label="Mandatory Or Not " 
          defaultValue={Mandatory_Or_Not_val}
          value={Mandatory_Or_Not_val} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setMandatory_Or_Not_val(e.target.value)}
        >
          {Mandatory_Or_Not.current.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        
        {/**                                             */}
       
       <Typography >Occurrence</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField select label="Occurrence class" 
          defaultValue={theclass}
           value={theclass} 
            
            onChange={(e)=>{setclass(e.target.value)}}
          variant="filled" sx={{ flex: 1 }}
          >
            {Occurrence_Class.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField select label="Detection phase" 
          defaultValue={phase} 
          value={phase}
          onChange={(e)=>{setphase(e.target.value)}}
        
          variant="filled" sx={{ flex: 1 }}
          
          >
            {Detection_Phase.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
                  
          <TextField select label="Occurrence category" 
            defaultValue={category} 
          value={category}
          onChange={(e)=>{setcategory(e.target.value)}}
           variant="filled" sx={{ flex: 1 }}  
          >
            {Occurrence_Category.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

        </Stack>

        <Typography >Damage</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Highest damage"
            sx={{ flex: 1 }}
            variant="filled"
            defaultValue={highestd} 
            value={highestd}
            onChange={(e)=>{sethighestd(e.target.value)}}
             
          />
          <TextField
            label="Third party damage"
            sx={{ flex: 1 }}
            variant="filled"
            defaultValue={thirdpd} 
            value={thirdpd}
            onChange={(e)=>{setthirdpd(e.target.value)}}
             
          />
        </Stack>
        
        <Typography >Injuries</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Injury level"
            sx={{ flex: 1 }}
            variant="filled"
            defaultValue={injury} 
            value={injury}
            
            onChange={(e)=>{setinjury(e.target.value)}}
            
          />

        </Stack>

        
        <Typography >Aircraft Identification</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
        
         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label2">Aircraft Registration</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label2"
           id="demo-simple-select-autowidth2"
           autoWidth
           label="Select A/C" 
           value={acval}  
           onChange={handle_ac_Change}
           
          >
          {
              the_ac_list.current.map((option,index ) => (
                <MenuItem 
                key={index+option.ac} 
                value={option.ac}>
                  {option.ac }
                </MenuItem>
            
              ))
           }    
           
          </Select>
         </FormControl>


   { ShowOtherAC &&  (
         <TextField
            label="Other Ac Registeration"
            sx={{ flex: 1 }}
            
            variant="filled"
            value={TheOtherAcus}  
            defaultValue={TheOtherAcus}  
            onChange={(e)=>{setTheOtherAcus(e.target.value)}}
            TheOtherAcus
            onKeyDown={(e) => { 
            console.log(e.target.value);
            TheOtherAc.current=e.target.value;
            
          }} 
  
            placeholder="   "
          />
             
           )}

          <TextField select label="State of registry" 
           defaultValue={stateofreg} 
           value={stateofreg}
           onChange={(e)=>{setstateofreg(e.target.value)}}
          
           variant="filled" sx={{ flex: 1 }}   >
            {Country.map((option) => (
              <MenuItem 
              key={option.country_name} 
              value={option.country_name}>
                {option.country_name }
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Aircraft total time"
            sx={{ flex: 1 }}
            defaultValue={hours} 
            value={hours}
            onChange={(e)=>{sethours(e.target.value)}}
            
            variant="filled"
             
          />
         <TextField
            label="Aircraft total cycles"
            sx={{ flex: 1 }}
            value={cycles}
            variant="filled"
            defaultValue={cycles} 
            onChange={(e)=>{setcycles(e.target.value)}}
           
          />


        </Stack>

        <Typography >ENGINE / PART / TOOL INFORMATION</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Part Number"
            sx={{ flex: 1 }}
            variant="filled"
            value={pnum}
            defaultValue={pnum} 
            onChange={(e)=>{setpnum(e.target.value)}}
             
          />
          {/*                 */}         
  
          <TextField
            label="Serial Number"
            sx={{ flex: 1 }}
            variant="filled"
            value={ser}
            defaultValue={ser} 
            onChange={(e)=>{  setser(e.target.value)}}

          />


        </Stack>

{}

        <Box sx={{ 
          textAlign: "center",
          width:"100%" ,
          display:"flex",
          justifyContent:'center',
          flexDirection:'column',
          fontSize:"14px",
          }}>
          Added Actions:
           <Box sx={{ textAlign: "center" ,color:green[800],width:"90%"  }}>

           <ol className="flist">
            {[...action].map((f, i) => (
                <li key={i}>
                  {f} 
                
                  </li>
            ))}
            </ol>
           
           </Box>

           Added Attachments:
           <Box sx={{ textAlign: "center" ,color:green[800],width:"90%"  }}>

           <ol className="flist">
            {[...attachment].map((f, i) => (
                <li key={i}>
                <a href='https://www.google,com' > {f} </a>
                  
                
                  </li>
            ))}
            </ol>
           
           </Box>
        </Box>
        
{}
        <Typography >NARRATIVE ( Description )</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Narrative  text"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            value={NARRATIVE}
            defaultValue={NARRATIVE}
            onChange={(e)=>{setNARRATIVE(e.target.value)}}
             
          />

        </Stack>
        <Stack direction={'row'}>
        <label  id="file-upload" className="file-upload-c">
         <input type="file" multiple 
         onChange={(e)=> setFiles(e.target.files)}/>
         Select Files
        </label>
          {/* <input type="file" multiple /> 
          <Button 
          onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}
          component="label"  variant="contained" sx={{ width: '250px' }} startIcon={<CloudUpload />}>
          {renderButtonStatus()}   
          </Button>*/}
        </Stack>
        {/* <TextField
          label="Attached files"
          sx={{ flex: 1 }}
          multiline
          minRows={4}
          variant="filled"
          {...register("attached_files")}
        /> ,*/}
        <Box sx={{ 
          textAlign: "center",
          width:"100%" ,
          display:"flex",
          justifyContent:'center',
          fontSize:"14px",
          }}>
        
           <Box sx={{ textAlign: "center" ,color:green[800],width:"50%"  }}>
           {renderFileList()}
           </Box>
        </Box>

         {/**     style={{color: theselectedcolor }}   */}
        {/* <Box sx={{ textAlign: "center" }}>
        <div className="matrixrow">
        <div className="c5a" id="c5a">5a</div>
        <div className="c5b" id="c5b">5b</div>
        <div className="c5c" id="c5c">5c</div>
        <div className="c5d" id="c5d">5d</div>
        <div className="c5e" id="c5e">5e</div>
        </div>
        <div className="matrixrow">
        <div className="c4a" id="c4a">4a</div>
        <div className="c4b" id="c4b">4b</div>
        <div className="c4c" id="c4c">4c</div>
        <div className="c4d" id="c4d">4d</div>
        <div className="c4e" id="c4e">4e</div>
        </div>
        <div className="matrixrow">
        <div className="c3a" id="c3a">3a</div>
        <div className="c3b" id="c3b">3b</div>
        <div className="c3c" id="c3c">3c</div>
        <div className="c3d" id="c3d">3d</div>
        <div className="c3e" id="c3e">3e</div>
        </div>
        <div className="matrixrow">
        
        <div className="c2a" id="c2a">2a</div>
        <div className="c2b" id="c2b">2b</div>
        <div className="c2c" id="c2c">2c</div>
        <div className="c2d" id="c2d">2d</div>
        <div className="c2e" id="c2e">2e</div>
        </div>
        <div className="matrixrow">
         
        <div className="c1a" id="c1a">1a</div>
        <div className="c1b" id="c1b">1b</div>
        <div className="c1c" id="c1c">1c</div>
        <div className="c1d" id="c1d">1d</div>
        <div className="c1e" id="c1e">1e</div>
        </div>
        </Box> */} 

        <Box sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            sx={{ textTransform: "capitalize",backgroundColor:brown[300] }}
            variant="contained"
            startIcon={<CloudUpload />}
          >
           Save
          </Button>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Button
            type="button"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<CloudUpload />}
            onClick={(e)=>{Close(e);}}
          >
           Close
          </Button>
        </Box>
      </Paper>
    </Box>
    </form>

  );
}
