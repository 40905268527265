export default [
  {
    title: "Occurrence Report",
    path: "/OcceranceReport",
    priv: "user",
  },

   {
    title: "SAFA",
    path: "/SAFA",
    priv: "user",
  },
];
