import { CloudUpload, Phone } from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import {
  Box,
  Button,
  Select,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef } from "react";

import { useForm } from "react-hook-form";


import Country from "../../country.js";
import Resource from "../../resource.js";
import { green } from "@mui/material/colors";
import axios from "axios";
import { useNavigate } from "react-router-dom";
  
function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Occurrence_Category = [
  {
  value: "Select Occurrence Affect",
  label: "Select Occurrence Affect",
},{
  value: "Grounded",
  label: "Grounded",
},
{
  value: "Delay",
  label: "Delay",
},
{
  value: "Return to Stand",
  label: "Return to Stand",
},
{

  value: "Divert",
  label: "Divert",
},
{
  value: "Return  from flight",
  label: "Return  from flight",
},]

const Detection_Phase = [
  {
  value: "Select Detection Phase",
  label: "Select Detection Phase",
},
  {
    value: "Operation",
    label: "Operation",
  },
  {
    value: "Taxi",
    label: "Taxi",
  },
  {
    value: "Take-off",
    label: "Take-off",
  },
  {
    value: "Climb",
    label: "Climb",
  },
  {
    value: "Enroute",
    label: "Enroute",
  },
  {
    value: "Descent",
    label: "Descent",
  },
  {
    value: "Approach",
    label: "Approach",
  },
  {
    value: "Landing",
    label: "Landing",
  },
  {
    value: "Hovering",
    label: "Hovering",
  },
  {
    value: "Ground Handling",
    label: "Ground Handling",
  },
  {
    value: "Lightning Strike",
    label: "Lightning Strike",
  },
  {
    value: "Engine Run",
    label: "Engine Run",
  },
  {
    value: "Towing",
    label: "Towing",
  },
  {
    value: "Bird Strike",
    label: "Bird Strike",
  },
  {
    value: "Reposition",
    label: "Reposition",
  },
]


const Detection_Phase_Maintenance = [
  {
  value: "Select Detection Phase Maintenance",
  label: "Select Detection Phase Maintenance",
},
  {
    value: "A/C Maintenance (A1 rating)",
    label: "A/C Maintenance (A1 rating)",
  },
  {
    value: "Engine Maintenance (B1 rating)",
    label: "Engine Maintenance (B1 rating)",
  },
  {
    value: "Component Maintenance (C rating)",
    label: "Component Maintenance (C rating)",
  },
  {
    value: "Tools / Equipments",
    label: "Tools / Equipments",
  },
  {
    value: "Maintenance Facilities",
    label: "Maintenance Facilities",
  },
  {
    value: "Personnel",
    label: "Personnel",
  },
   
  
]

const ATA = [
  
  {
    value: "Select ATA Chapter",
    label: "Select ATA Chapter",
  },
   

{
  value: "05",
  label: "05",
},
{
  value: "06",
  label: "06",
},
{
  value: "07",
  label: "07",
},
{
  value: "08",
  label: "08",
},
{
  value: "09",
  label: "09",
},
{
  value: "10",
  label: "10",
},
{
  value: "11",
  label: "11",
},
{
  value: "12",
  label: "12",
},
{
  value: "20",
  label: "20",
},
{
    value: "21",
    label: "21",
  },
  {
    value: "22",
    label: "22",
  },
  {
    value: "23",
    label: "23",
  },
  {
    value: "24",
    label: "24",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "26",
    label: "26",
  },
  {
    value: "27",
    label: "27",
  },
  {
    value: "28",
    label: "28",
  },
  {
    value: "29",
    label: "29",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "31",
    label: "31",
  },
  {
    value: "32",
    label: "32",
  },
  {
    value: "33",
    label: "33",
  },
  {
    value: "34",
    label: "34",
  },
  {
    value: "35",
    label: "35",
  },
  {
    value: "36",
    label: "36",
  },
  {
    value: "38",
    label: "38",
  },
  {
    value: "44",
    label: "44",
  },
  {
    value: "46",
    label: "46",
  },
  {
    value: "47",
    label: "47",
  },
  {
    value: "49",
    label: "49",
  },
  {
    value: "51",
    label: "51",
  },
  {
    value: "52",
    label: "52",
  },
  {
    value: "53",
    label: "53",
  },
  {
    value: "54",
    label: "54",
  },
  {
    value: "55",
    label: "55",
  },
  {
    value: "56",
    label: "56",
  },
  {
    value: "57",
    label: "57",
  },
  {
    value: "70",
    label: "70",
  },
  {
    value: "71",
    label: "71",
  },
  {
    value: "72",
    label: "72",
  },
  {
    value: "73",
    label: "73",
  },
  {
    value: "74",
    label: "74",
  },
  {
    value: "75",
    label: "75",
  },
  {
    value: "76",
    label: "76",
  },
  {
    value: "77",
    label: "77",
  },
  {
    value: "78",
    label: "78",
  },
  {
    value: "79",
    label: "79",
  },
  {
    value: "80",
    label: "80",
  },
   
  
]


const Occurrence_Class = [
  
  {
    value: "Select Occurrence Class",
    label: "Select Occurrence Class",
  },
  {
    value: "Accident",
    label: "Accident",
  },
  {
    value: "Incidet",
    label: "Incidet",
  },
  {
    value: "Serious Incident",
    label: "Serious Incident",
  },
  {
    value: "Technicaal Occurrence Report",
    label: "Technicaal Occurrence Report",
  },
]
const Report_Type = [
  
  {
    value: "Select Report Type",
    label: "Select Report Type",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]

const Resource_ac_reg=[
  {
  value: "Select Aircraft Registeration",
  label: "Select Aircraft Registeration",
  }
]

export default function AddOcceranceReport() {

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_BASE_URL+"/api/Product/posted_filesAsync";
  const API_METHOD = 'POST'
  const STATUS_IDLE = 0
  const STATUS_UPLOADING = 1
  const [files, setFiles] = React.useState([])

  const [ac, setac] = React.useState([])
  const [thecity, setcity] = React.useState([]);
  const [hours, sethours] = React.useState('');
  const [cycles, setcycles] = React.useState('')
 
  const [acval,setacval]= React.useState('Select A/C');

  
  const [status, setStatus] = React.useState(STATUS_IDLE)
  const [theres, settheres] = React.useState("");
  const ac_lines=useRef([]);

  const TheOtherAc=useRef("");

  const data_collection_1_phone_mail_ata_partdesc_partnm_partser=useRef("");

  const data_collection_2_action_=useRef("");


  
  const [name_from_code, setname_from_code] = React.useState('Waiting...');
  
  const [ShowOtherAC, setShowOtherAC] = React.useState(false);
  
  const [Cityval, setCityval] = React.useState('Select City');
  const [Countryval, setCountryval] = React.useState('Select Country');
  const city = React.useRef( [ {"city": "Select City"},{"city": "cairo"},]);

  const [directorateval, setdirectorateval] = React.useState('Select Directorate');
  const [departmentval, setdepartmentval] = React.useState('Select Department');
  const directorate = React.useRef( [ {"directorate": "Select Directorate"},]);
  const department = React.useRef( [ {"department": "Select Department"}, ]);

  const [directorate_us, setdirectorate_us] = React.useState([]);
 


  const [Mandatory_Or_Not_us, setMandatory_Or_Not_us] = React.useState([])
  const Mandatory_Or_Not = React.useRef( [{value: "0",label: "Not Mandatory",}, ] );
  
  const uploadFiles = async (data)=> {
      setStatus(STATUS_UPLOADING);

      const response1 = await fetch(API_URL, {
          method: API_METHOD,
          body: data,
      })
      .then((res) => res.json())
          
      .then((data) => {
        console.log(data);
     
        console.log(data[0].val);
      })
      .catch((err) => console.error(err))
      .finally(() => 
      {
        setStatus(STATUS_IDLE);
       
        alert("Upload done"+theres);
      })
      navigate("/OcceranceReport");
     // const result = await response1.json();console.log(result);  
  }

  const packFiles = (files,data1)=> {
      const data = new FormData();
      
      [...files].forEach((file, i) => {
          data.append(`file-${i}`, file, file.name)
      })
      console.log("data1 before append");
      console.log(data1);
      data.append('occ_date', data1.occ_date);
      data.append('occ_time', data1.occ_time);
      data.append('country', Countryval);
      data.append('city', Cityval);
      data.append('ext_occno',  directorateval+"*"+departmentval);
      data.append('header', data1.header);
      data.append('occ_class', data1.occ_class);
      data.append('detection_phase', data1.detection_phase+"*"+data1.detection_phase_Maintenance);
      data.append('occ_category', data1.occ_category);
      //-------------------------------------------------------------------
      // data_collection_1_phone_mail_ata_partdesc_partnm_partser
      data_collection_1_phone_mail_ata_partdesc_partnm_partser.current=
      data1.Phone 
      +"#01550834905#"
      +data1.Email
      +"#01550834905#"
      +data1.Select_ATA_Chapter
      +"#01550834905#"
      +data1.partDescription
      +"#01550834905#"
      +data1.part_number
      +"#01550834905#"
      +data1.serial_number 
      +"#01550834905#"
      +data1.section 
      +"#01550834905#"
      +data1.shift_type 
      +"#01550834905#"
      +data1.Type_of_Maintenance 
      +"#01550834905#"
      +data1.Tech_Log_Ref_WO;
      data_collection_2_action_.current =data1.Action_Taken+"#01550834905#";
      //--------------------  section  shift_type Type_of_Maintenance Tech_Log_Ref_WO -----------------------------------------------
      data.append('highest_damage',
        data_collection_1_phone_mail_ata_partdesc_partnm_partser.current );//data1.highest_damage
      data.append('third_party_damage',data_collection_2_action_.current );  // data1.third_party_damage
      
      //----------------------------------------------------------------
      data.append('injury_level', data1.injury_level);
      data.append('ac_reg', acval);
      data.append('state_reg', data1.state_reg);
      data.append('ac_total_h', hours);
      data.append('ac_total_c', cycles);
      data.append('partDescription', data1.partDescription);
      data.append('part_number', data1.part_number);
      data.append('serial_number', data1.serial_number);
      data.append('narrative_text', data1.narrative_text);
      data.append('report_type', data1.report_type);
      data.append('OtherAc', data1.OtherAc );//   TheOtherAc.current);
 
      data.append('Mandatory_Or_Not', data1.Mandatory_Or_Not); 

      data.append('user_id', '65479');
      console.log("data"); 
      console.log(data);
      return data
  }

  const handleUploadClick = () => {
      if (files.length) {
          const data = packFiles(files)
          uploadFiles(data)
      }
  }

  const renderFileList = () => (
  <ol className="flist">
      {[...files].map((f, i) => (
          <li key={i}>
            {f.name} 
            {/* - {f.type} */}
            </li>
      ))}
  </ol>)

  const renderButtonStatus = () => (
      (status === STATUS_IDLE) ? 
          'Send to server' : 
          <img src = "load.svg" style={{height:"40px"}} />
  )
 
  const { register, handleSubmit } = useForm();
  const [dense, setDense] = React.useState(true);
  const the_ac_list=useRef(
    [
      {
        "ac": "Select A/C"
      },
      
  ]);

  const onSubmit = (data1) => {

    console.log(data1);

   // if (files.length) {}
      const data = packFiles(files,data1)
      uploadFiles(data)
  

  };


  const get_ac=async(data)=>
  {
    const api_ac=process.env.REACT_APP_BASE_URL+"/api/Product/get_ac_list";

    const response1 = await fetch( api_ac , {
      method: API_METHOD,
      body: data,
    })
    .then((res) => res.json())
      
    .then((data) => {

    //console.log(data[0].val);
    var data_total=data[0].val;
    ac_lines.current =data_total.split(';');
    // console.log(ac_lines);
    //  const the_ac_list=useRef([{"ac": "Select A/C"},]);
    the_ac_list.current=[];
    the_ac_list.current.push({"ac":"Select A/C"});
    for (let y=0;y<ac_lines.current.length-1;y++)
    {
      the_ac_list.current.push({"ac": ac_lines.current[y].split(',')[0]});
    }
    the_ac_list.current.splice(124, 1);
    the_ac_list.current.push({"ac": "Other"});
    console.log(the_ac_list.current);

    setac(the_ac_list.current);
    })
    .catch((err) => console.error(err))
    .finally(() => 
    {
    
    })

  }
  const get_reportable=async(data)=>
  {
    const api_lst=process.env.REACT_APP_BASE_URL+"/api/Product/getreportableslist";
    const post = {
      country : data,
    };
    
  
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( api_lst, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
 
     var temp_mat1=[];
     temp_mat1.push({value: "0",label: "Not Mandatory",});
     
     
    for (let y=0;y<cc.length;y++)
    {
      temp_mat1.push({"value": cc[y].number , "label":cc[y].reportableittem });
    }
    Mandatory_Or_Not.current=temp_mat1;
    console.log(Mandatory_Or_Not.current);

    setMandatory_Or_Not_us(Mandatory_Or_Not.current);
/* */   
    

  }
  const get_city=async(datac)=>
  {
   // https://inventory.internet-online.net/api/Product/selectcitiesforcountry

   const post = {
    country : datac,
  };
  

   const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectcitiesforcountry";
   let res = axios.create();
   res.defaults.timeout = 10000;
   //--------------------------------
   axios.defaults.headers.post["Content-Type"] =
     "application/x-www-form-urlencoded";
   axios.defaults.transformRequest = [
     function (data, headers) {
       var str = [];
       for (var p in data)
         if (data.hasOwnProperty(p) && data[p]) {
           str.push(
             encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
           );
         }
       return str.join("&");
     },
   ];

    res  = await axios.post( api_ac, post); 
   let cc= res.data;//['data'];  
 
   console.log("cc"); 
   console.log(cc); 
   city.current=[];
   city.current.push({"city": "Select City"});
   for (let y=0;y<cc.length;y++)
   {
    city.current.push({"city": cc[y].name});
   }
   console.log(city.current);
   setcity(city.current);
   
   

  }
  const get_directorate=async( )=>
    {
     // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
      
     const post = {
      country : " ",
    };
    
  
     const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectdirectorate";
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( api_ac, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
     directorate.current=[];
     directorate.current.push( {"directorate": "Select Directorate"});
     for (let y=0;y<cc.length;y++)
     {
      directorate.current.push({"directorate": cc[y].directorate_val    });
     }
     console.log(directorate.current);
    // setdirectorateval(directorate.current);
       
  
  }
  const get_department=async(datac)=>
  {
       // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
    
       const post = {
        directorate : datac,
      };
      
    
       const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectdepartments";
       let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( api_ac, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
       department.current=[];
       department.current.push({"department": "Select Department"});
       for (let y=0;y<cc.length;y++)
       {
        department.current.push({"department": cc[y].department_val  });
       }
        console.log("department.current");
        console.log(department.current);
       setdirectorate_us(department.current);
       setdepartmentval("Select Department");
     
  }
  const get_name_from_code=async(datac)=>
    {
         // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
      
         const post = {
          code : datac,
        };
        
      
         const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/getnamefromcode";
         let res = axios.create();
         res.defaults.timeout = 10000;
         //--------------------------------
         axios.defaults.headers.post["Content-Type"] =
           "application/x-www-form-urlencoded";
         axios.defaults.transformRequest = [
           function (data, headers) {
             var str = [];
             for (var p in data)
               if (data.hasOwnProperty(p) && data[p]) {
                 str.push(
                   encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
                 );
               }
             return str.join("&");
           },
         ];
      
          res  = await axios.post( api_ac, post); 
         let cc= res.data;//['data'];  
       
         console.log("name from code"); 
         console.log(cc); 
 
         setname_from_code(cc[0].val);
        
       
    }    
  useEffect(()=>
  {
    get_name_from_code("65479");
    get_directorate(" ");
    get_reportable(" ");
    get_ac(" ");
  },[]);

  const handle_country_Change = (event) => {
    setCountryval(event.target.value);
    get_city(event.target.value);
   // alert ( event.target.value );
  };
  const handle_city_Change = (event) => {
    setCityval(event.target.value);
   // alert ( event.target.value );
  };
  
  const handle_directorate_Change = (event) => {
    setdirectorateval(event.target.value);
    get_department(event.target.value);
   // alert ( event.target.value );
  };
 
  const handle_department_Change = (event) => {
    setdepartmentval(event.target.value);
   // alert ( event.target.value );
  };
 
  const handle_ac_Change = (event) => {

   // hours.current cycles.current
   if (event.target.value.trim()==="Other")
   {

    alert ( "Other" );
    setacval(event.target.value);
    sethours("");
    setcycles("");
    console.log("return");
    setShowOtherAC(true);
    return ;
   }

   setShowOtherAC(false);

   console.log("not return");

   for (let y=0;y<ac_lines.current.length-1;y++)
   {
    console.log(ac_lines.current[y].split(',')[0]);
     if (ac_lines.current[y].split(',')[0]===event.target.value)
     {
      console.log('found');
      sethours(ac_lines.current[y].split(',')[4]);

      setcycles(ac_lines.current[y].split(',')[5]);
         
    
 
     }  
   }

    setacval(event.target.value);


    alert ( event.target.value );
  };
  

  return (

    <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <Box

      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}

    >
      <Paper
        elevation={17}
        sx={{
          p: "20px",
          width: "100%",
          minWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          fontSize: "25px",
          fontWeight: 800,
        }}
      >
        <Typography >Submitter Data</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
        
        <TextField
          label="Name"
          value={name_from_code}
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          {...register("Name")}
        />
         <TextField
          label="Code"
          value={'65479'}
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          {...register("Code")}
        />
        <TextField
          label="Phone"
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          {...register("Phone")}
        />

          <TextField
          label="Email"
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          {...register("Email")}
         />
          <TextField select label="Select ATA Chapter" 
          defaultValue='Select ATA Chapter'  
          variant="filled" sx={{ flex: 1 }} {...register("Select_ATA_Chapter")}>
            {ATA.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>


        </Stack>

        {/*------------------------------------------------------------*/}
        
        {/** --------------------------------------------------------- */}
        <Typography >When/Where</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField
            label="UTC Date"
            type="date"
            variant="filled"
            {...register("occ_date")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="UTC Time"
            type="time"
            variant="filled"
            {...register("occ_time")}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl  variant="filled" style={{marginTop:0}} sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label"
           id="demo-simple-select-autowidth"
           autoWidth
           label="Country" 
           value={Countryval}  
           onChange={handle_country_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            Country.map((option ) => (
            
              <MenuItem 
              key={option.country_name} 
              value={option.country_name}>
                {option.country_name }
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label2">City</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label2"
           id="demo-simple-select-autowidth2"
           autoWidth
           label="City" 
           value={Cityval}  
           onChange={handle_city_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            city.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.city} 
              value={option.city}>
                    {option.city}
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label21">Directorate</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label21"
           id="demo-simple-select-autowidth21"
           autoWidth
           label="Directorate" 
           value={directorateval}  
           onChange={handle_directorate_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            directorate.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.directorate} 
              value={option.directorate}>
                    {option.directorate}
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label22">Department</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label22"
           id="demo-simple-select-autowidth22"
           autoWidth
           label="Department" 
           value={departmentval}  
           onChange={handle_department_Change}
          // variant="filled" sx={{ flex: 1 }} ()=>{alert ("changed" );} 
          //{...register("country")}
         
          >
          {
            department.current.map((option,index ) => (
            
              <MenuItem 
              key  ={index+option.department} 
              value={option.department}>
                    {option.department}
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

 
      {   /* <TextField  label="Occerance Location"
            sx={{ flex: 1 }} variant="filled" {...register("ext_occno")} /> */}

        </Stack>

        {/*------------------       ------------------------------------------*/}
        <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField
          label="Section" variant="filled" sx={{ flex: 1 }}
          {...register("section")} />
         <TextField
          label="Shift Type" variant="filled" sx={{ flex: 1 }}
          {...register("shift_type")} />
         <TextField
          label="Type of Maintenance" variant="filled" sx={{ flex: 1 }}
          {...register("Type_of_Maintenance")} />
         <TextField
          label="Tech Log Ref/W O" variant="filled" sx={{ flex: 1 }}
          {...register("Tech_Log_Ref_WO")} />
        
        </Stack>
        {/*------------------ section  shift_type Type_of_Maintenance Tech_Log_Ref_WO ------------------------------------------*/}
       
        <Typography >Mandatory Or Not</Typography>
        
        <TextField select label="Mandatory Or Not" 
        defaultValue='0'
         
        variant="filled" sx={{ flex: 1 }} {...register("Mandatory_Or_Not")}>
          {Mandatory_Or_Not.current.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        
        {/**                                             */}
        
        <Typography >What</Typography>
        <TextField
          label="Occurrence Title"
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          {...register("header")}
        />
        {/**                                             */}
        <Typography >Report Type</Typography>
        <TextField select label="Report Type" 
          defaultValue='Select Report Type'
           
          variant="filled" sx={{ flex: 1 }} {...register("report_type")}>
            {Report_Type.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

        {/**                                             */}
        <Typography >Occurrence</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField select label="Occurrence class" 
          defaultValue='Select Occurrence Class'
           
          variant="filled" sx={{ flex: 1 }} {...register("occ_class")}>
            {Occurrence_Class.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Detection phase Maintenance" 
          defaultValue='Select Detection Phase Maintenance'  
          variant="filled" sx={{ flex: 1 }} {...register("detection_phase_Maintenance")}>
            {Detection_Phase_Maintenance.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Detection phase" 
          defaultValue='Select Detection Phase'  
          variant="filled" sx={{ flex: 1 }} {...register("detection_phase")}>
            {Detection_Phase.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Occurrence Affect" 
          defaultValue='Select Occurrence Affect' 
          variant="filled" sx={{ flex: 1 }} {...register("occ_category")}>
            {Occurrence_Category.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>


        </Stack>
{/*         
        <Typography >Damage</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Highest damage"
            sx={{ flex: 1 }}
            variant="filled"
            {...register("highest_damage")}
          />
          <TextField
            label="Third party damage"
            sx={{ flex: 1 }}
            variant="filled"
            {...register("third_party_damage")}
          />
        </Stack> */}

        <Typography >Injuries</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Injury level"
            sx={{ flex: 1 }}
            variant="filled"
            {...register("injury_level")}
          />

        </Stack>
        <Typography >Aircraft Identification</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
         
          
         <FormControl  variant="filled" style={{marginTop:0}}  sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label2">Aircraft Registration</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label2"
           id="demo-simple-select-autowidth2"
           autoWidth
           label="Select A/C" 
           value={acval}  
           onChange={handle_ac_Change}
           
          >
          {
              the_ac_list.current.map((option,index ) => (
                <MenuItem 
                key={index+option.ac} 
                value={option.ac}>
                  {option.ac }
                </MenuItem>
            
              ))
           }    
           
          </Select>
         </FormControl>


   { ShowOtherAC &&  (
         <TextField
            label="Other Ac Registeration"
            sx={{ flex: 1 }}
            
            variant="filled"
            {...register("OtherAc")}
            onKeyDown={(e) => { 
            console.log(e.target.value);
            TheOtherAc.current=e.target.value;
            
          }} 
  
            placeholder="   "
          />
             
           )}

          <TextField select label="State of registry" 
          defaultValue='Select Country'
          variant="filled" sx={{ flex: 1 }}  {...register("state_reg")}>
            {Country.map((option) => (
              <MenuItem 
              key={option.country_name} 
              value={option.country_name}>
                {option.country_name }
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Aircraft total time"
            sx={{ flex: 1 }}
            value={hours}
            variant="filled"
            {...register("ac_total_h")}
          />
          <TextField
            label="Aircraft total cycles"
            sx={{ flex: 1 }}
            value={cycles}
            variant="filled"
            {...register("ac_total_c")}
          />


        </Stack>

        <Typography >(ENGINE / UNIT / TOOL ) INFORMATION</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Description  "
            sx={{ flex: 1 }}
            variant="filled"
            {...register("partDescription")}
          />
          <TextField
            label="Part Number"
            sx={{ flex: 1 }}
            variant="filled"
            {...register("part_number")}
          />
          <TextField
            label="Serial Number"
            sx={{ flex: 1 }}
            variant="filled"
            {...register("serial_number")}
          />


        </Stack>
         {/** */}
        <Typography >Description  ( NARRATIVE )</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Description  text"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            {...register("narrative_text")}
          />

        </Stack>
        {/** */}
        <Typography >Action Taken   </Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Action Taken"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            {...register("Action_Taken")}
          />

        </Stack>
        {/** */}


        <Stack direction={'row'}>
        <label  id="file-upload" className="file-upload-c">
         <input type="file" multiple 
         onChange={(e)=> setFiles(e.target.files)}/>
         Select Files
        </label>
          {/* <input type="file" multiple /> 
          <Button 
          onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}
          component="label"  variant="contained" sx={{ width: '250px' }} startIcon={<CloudUpload />}>
          {renderButtonStatus()}   
          </Button>*/}
        </Stack>
        {/* <TextField
          label="Attached files"
          sx={{ flex: 1 }}
          multiline
          minRows={4}
          variant="filled"
          {...register("attached_files")}
        /> ,*/}
        <Box sx={{ 
          textAlign: "center",
          width:"100%" ,
          display:"flex",
          justifyContent:'center',
          fontSize:"14px",
          }}>
        
           <Box sx={{ textAlign: "center" ,color:green[800],width:"50%"  }}>
           {renderFileList()}
           </Box>
        </Box>
        
        <Box sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<CloudUpload />}
          >
           Save
          </Button>
        </Box>
      </Paper>
    </Box>
    </form>

  );
}
