export default [
  {
    title: "Mangagement Commitment & responsibility",
    path: "/Mangagement_Commitment_responsibility",
    priv: "user",
  },
  {
    title: "Safety accountabilities",
    path: "/SafetyAccountabilities",
    priv: "user",
  },
  {
    title: "Appointment of key safety personnel",
    path: "/Appointment_of_key_safety_personnel",
    priv: "user",
  },
  {
    title: "Coordination of ERP",
    path: "/CoordinationERP",
    priv: "user",
  },
  {
    title: "SMS documentation",
    path: "/SMS_documentation",
    priv: "user",
  },
];
