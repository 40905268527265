import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import Topbar from "./components/Topbar";
import Sidebar1 from "./components/SideBar1";
import { ThemeProvider, createTheme, styled } from "@mui/material";
import { getDesignTokens } from "./theme";
import { Outlet, Route, Routes } from "react-router-dom";

const drawerWidth = 350;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const imageURL = 'https://th.bing.com/th/id/R.14ee812e8a94871fd5ab0fec76fc7584?rik=aOFdt8E1gNv6wA&riu=http%3a%2f%2fcontent.tegna-media.com%2fphoto%2f2016%2f07%2f02%2f636030465725623304-Mideast-Egypt-Plane-May_3671351_ver1.0.jpg&ehk=%2bc%2fFSkvMORMfsGqQbTkPL8bI9XKt8TFCYP%2fCRT5VLb4%3d&risl=&pid=ImgRaw&r=0';

export default function App1() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [mode, setMode] = useState(
    localStorage.getItem("currentMode")
      ? localStorage.getItem("currentMode")
      : "light"
  );
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Topbar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          drawerWidth={drawerWidth}
          setMode={setMode}
        />
        <Sidebar1
          drawerWidth={drawerWidth}
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
      
            <Outlet />
         
        </Box>
      </Box>
    </ThemeProvider>
  );
}
