export default [
  {
    title: "New Occurrence Report",
    path: "/AddOcceranceReport",
    priv: "user",
  },
  {
    title: "Update Occurrence Report",
    path: "/UpdateOcceranceReport",
    priv: "user",
  },
  {
    title: "Sms Admin DashBoard",
    path: "/SmsAdminDashBoard",
    priv: "user",
  }
  ,
  {
    title: "Print",
    path: "/PrintReport",
    priv: "user",
  }
  
];
