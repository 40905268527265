export default [
  {
    title: "Safety performance monitoring & measurement",
    path: "/SafetyPerformance",
    priv: "user",
  },
  {
    title: "The management of change",
    path: "/ManagementChange",
    priv: "user",
  },
  {
    title: "Continuous improvements of the SMS",
    path: "/ContinuousImprovements",
    priv: "user",
  },
];
