import { CloudUpload } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
   
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import AddBoxIcon from '@mui/icons-material/AddBox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';


import {
  Box,
  Button,
  Select,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Checkbox ,
  FormGroup,
  FormControlLabel
} from "@mui/material";

import React, { useEffect, useRef } from "react";

import { useForm } from "react-hook-form";


import Country from "../../country.js";
import Resource from "../../resource.js";
import { brown, green ,grey,red,blue,blueGrey} from "@mui/material/colors";
import Cookies from 'universal-cookie';
 
import axios from "axios";

import "../../matrix.css";
import "../../probability_cls.css";


import { flexibleCompare } from "@fullcalendar/core/internal";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { useStopwatch } from 'react-timer-hook';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
  
//====================================================================
const emails = ['username@gmail.com', 'user02@gmail.com'];
const AllSubjects=[ 
  {
  value: "English",
  label: "officers",
  },
  {
    value: "Passenger",
    label: "officers",
  },
  {
    value: "Baggage",
    label: "officers",
  },
  {
    value: "English",
    label: "maintenance",
  },
  {
    value: "Avionics",
    label: "maintenance",
  },
  {
    value: "Airfarme",
    label: "maintenance",
  },
  {
    value: "English",
    label: "operation",
  },
  {
    value: "Operation",
    label: "operation",
  },
  {
    value: "Weather",
    label: "operation",
  },
  {
    value: "English",
    label: "crew_italy",
  },
  {
    value: "Italy",
    label: "crew_italy",
  },
  {
    value: "علوم طيران",
    label: "crew_italy",
  },
  {
    value: "أعمال شركات",
    label: "crew_italy",
  },
  {
    value: "English",
    label: "crew_frensh",
  },
  {
    value: "Frensh",
    label: "crew_frensh",
  },
  {
    value: "علوم طيران",
    label: "crew_frensh",
  },
  {
    value: "أعمال شركات",
    label: "crew_frensh",
  },
    
  ]

function SimpleDialog(props) {
  const { register, handleSubmit } = useForm();
  const { onClose, selectedValue,selectedValue1, open } = props;
  const Subject=useRef([ {value: "Select Subject",label: "Select Subject", } ]);
  const [table_rows_arr, settable_rows_arr] = React.useState([]);
  const navigate = useNavigate();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  const thhhecode=useRef("");

  useEffect(()=>
  {
   // 
   console.log("selectedValue"  );  
   console.log( selectedValue  );
   // console.log("selectedValue1");
   // console.log(selectedValue1 );
    
  },[seconds])


  useEffect(()=>
  {     
    console.log("selectedValue=> code" );
    console.log( selectedValue  );
   var tt=[];
   for (let yy=0;yy<selectedValue.length;yy++)
    {
      tt.push(
        <tr>
         <td>{yy+1}</td>
         <td>{selectedValue[yy].report_name}</td>
         <td> {selectedValue[yy].UTC_time}  </td>
        
        <td>{selectedValue[yy].Generic_Hazard}</td>
        <td>{selectedValue[yy].Specific_Hazard}</td>
        <td>{selectedValue[yy].Recommendations}</td>

       </tr>

      );
    }

    settable_rows_arr(tt);
          
  },[selectedValue])

    const year=[ 
      {
      value: "1",
      label: "1",
      },
      {
      value: "2",
      label: "2",
      },]
   
    const Type=[ 
      {
      value: "Mid Term",
      label: "Mid Term",
      },
      {
        value: "Oral",
        label: "Oral",
      },
      {
        value: "Evaluation",
        label: "Evaluation",
      },
      {
        value: "Final Exam",
        label: "Final Exam",
      },]
      
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleOpen = () => {
    console.log("selectedValue");
    console.log(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const onSubmit = (data1) => {
     // e.preventDefault();
    console.log(data1);

    add_exam(data1);
    return false;
  }
  const add_exam = async(data ) => {
    
      
  
    handleClose();
  };
  const handleclosedialog = async(data ) => {

    handleClose();
  
  }
  return (

    <Dialog onClose={handleClose} open={open}
    //  onOpen={handleOpen}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          height:"500px",
          width: "100%",
          maxWidth: "1000px",  // Set your width here
        },
      },
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center' ,
    }}
    >
      <DialogTitle>Probability Details</DialogTitle>

      {/* <form onSubmit={  handleSubmit(onSubmit) } noValidate>
       </form> */}
      <Box   sx={{width:'900px', display:'flex', justifyContent:'center',
       flexDirection:'column',alignItems:'center' }}>
      
       
       
{/*        
  منع الاخطاء قدر الامكان
   ، الصلاحيات 
  ، تسجيل التحركات 
  just culture,
   

        */}
 <table className="proptable">
  <tr>
    <th className="col1">Number</th>
    <th className="col2">Report number</th>
    <th className="col3">Report Date</th>
    <th className="col4">Generic Hazard</th>
    <th className="col5">Specific Hazard</th>
    <th className="col6">Rating Hazard</th>
  </tr>
  
  {table_rows_arr}

</table>
       </Box>
  
       
      <Box   sx={{display:'flex', justifyContent:'center',
       flexDirection:'column',alignItems:'center',margin:'50px' }}>
         <Button
            type="button"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<CloudUpload />}
            onClick={(event) => handleclosedialog(event )} 
          >
           Close
          </Button>
        {/* <Button  variant="outlined" onClick={()=>{add_exam();}}></Button>
       */}
       </Box>
     
     
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.array .isRequired,
  selectedValue1: PropTypes.string.isRequired,
};



//=======================================================================

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Occurrence_Category = [
  {
  value: "Select Occurrence Category",
  label: "Select Occurrence Category",
},{
  value: "Grounded",
  label: "Grounded",
},
{
  value: "Delay",
  label: "Delay",
},
{
  value: "Return to Stand",
  label: "Return to Stand",
},
{

  value: "Divert",
  label: "Divert",
},
{
  value: "Return  from flight",
  label: "Return  from flight",
},]

const Detection_Phase = [
  {
  value: "Select Detection Phase",
  label: "Select Detection Phase",
},
  {
    value: "Operation",
    label: "Operation",
  },
  {
    value: "Taxi",
    label: "Taxi",
  },
  {
    value: "Take-off",
    label: "Take-off",
  },
  {
    value: "Climb",
    label: "Climb",
  },
  {
    value: "Enroute",
    label: "Enroute",
  },
  {
    value: "Descent",
    label: "Descent",
  },
  {
    value: "Approach",
    label: "Approach",
  },
  {
    value: "Landing",
    label: "Landing",
  },
  {
    value: "Hovering",
    label: "Hovering",
  },
  {
    value: "Ground Handling",
    label: "Ground Handling",
  },
  {
    value: "Other",
    label: "Other",
  },
]

const Occurrence_Class = [
  
  {
    value: "Select Occurrence Class",
    label: "Select Occurrence Class",
  },
  {
    value: "Accident",
    label: "Accident",
  },
  {
    value: "Incidet",
    label: "Incidet",
  },
  {
    value: "Serious Incident",
    label: "Serious Incident",
  },
  {
    value: "Technicaal Occurrence Report",
    label: "Technicaal Occurrence Report",
  },
]
const Report_Type = [
  
  {
    value: "Select Report Type",
    label: "Select Report Type",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]

var Scope_of_work_arr = [
  
  {
    value: "Select Rating",
    label: "Select Rating",
  },
  {
    value: "A L",
    label: "A L",
  },
  {
    value: "A B",
    label: "A B",
  },
  {
    value: "B rating",
    label: "B rating",
  },
  {
    value: "C rating",
    label: "C rating",
  },
 
]
var Effectiveness_arr = [
  
  {
    value: "Select If Follow up Required",
    label: "Select If Follow up Required",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  
 
]

var Generic_Hazard_arr  = [
  
  {
    value: "Select Generic Hazard",
    label: "Select Generic Hazard",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]
var Specific_Hazard_arr  = [
  
  {
    value: "Select Specific Hazard",
    label: "Select Specific Hazard",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]
var List_item_arr  = [
  
  // {
  //   value: "Item no 1",
  //   label: "Item no 1",
  // },
  
   
]



{/**    Event_type     select   Technical - Human - orgizational -  enviromental  */}

var Event_type_arr = [ {
  value: "Select",
  label: "Select",
},{
  value: "Technical",
  label: "Technical",
},{
  value: "Human",
  label: "Human",
},{
  value: "orgizational",
  label: "orgizational",
},{
  value: "enviromental",
  label: "enviromental",
},] 



{/**    Classification1     select    HAZARD - ERROR   -  NEARMISS   */}

var Classification1_arr = [{
  value: "Select",
  label: "Select",
},{
  value: "HAZARD",
  label: "HAZARD",
},{
  value: "ERROR",
  label: "ERROR",
},{
  value: "NEARMISS",
  label: "NEARMISS",
},]  


{/**    Classification2     select  Interal -  External */}

var Classification2_arr = [{
  value: "Select",
  label: "Select",
},{
  value: "Interal",
  label: "Interal",
},{
  value: "External",
  label: "External",
},] 

{/**    Classification3     select  Confidential  -  Not Confidential */}

var Classification3_arr = [{
  value: "Select",
  label: "Select",
},{
  value: "Confidential",
  label: "Confidential",
},{
  value: "Not Confidential",
  label: "Not Confidential",
},]

{/**    Classification4     select   Proactive -  Reactive    */}

var Classification4_arr = [{
  value: "Select",
  label: "Select",
},{
  value: "Proactive",
  label: "Proactive",
},{
  value: "Reactive",
  label: "Reactive",
},]  


var Severity_arr = [{
  value: "Select",
  label: "Select",
},{
  value: "a-Catastrophic",
  label: "A-Catastrophic",
},{
  value: "b-Hazardous",
  label: "B-Hazardous",
},{
  value: "c-Major",
  label: "C-Major",
},{
  value: "d-Minor",
  label: "D-Minor",
},{
  value: "e-Negligible",
  label: "E-Negligible",
},] 
  
const Report_status_arr  = [
  
  {
    value: "Select Report status",
    label: "Select Report status",
  },
  {
    value: "Initial notification",
    label: "Initial notification",
  },
  {
    value: "Open ( In progress)",
    label: "Open ( In progress)",
  },
  {
    value: "Closed",
    label: "Closed",
  },
   
]

const treeViewArr = [
  {
    id: "1",
    title: "First Parent Node",
    childNodes: [
      {
        id: "2",
        parentId: "1",
        title: "First Child Node of First Parent Node",
        childNodes: [
          {
            id: "3",
            parentId: "2",
            title: "First Child Node of First Child Node",
          },
        ],
      },
      {
        id: "4",
        parentId: "1",
        title: "Second Child Node of First Parent Node",
        childNodes: [
          {
            id: "5",
            parentId: "4",
            title: "First Child Node of Second Child Node",
          },
        ],
      },
    ],
  },
  {
    id: "6",
    title: "Second Parent Node",
    childNodes: [
      {
        id: "7",
        parentId: "6",
        title: "First Child Node of Second Parent Node",
        childNodes: [
          {
            id: "8",
            parentId: "7",
            title: "First Child Node of First Child Node of Second Parent Node",
          },
        ],
      },
      {
        id: "9",
        parentId: "6",
        title: "Second Child Node",
        childNodes: [
          {
            id: "10",
            parentId: "9",
            title:
              "First Child Node of Second Child Node of Second Parent Node",
          },
        ],
      },
    ],
  },
];

const Resource_ac_reg=[
  {
  value: "Select Aircraft Registeration",
  label: "Select Aircraft Registeration",
  }
]


export default function AdminPageOcceranceReport_Analysis() {

  //=============================
  var analizer_code="111116";
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    console.log("SelectedIndex");
    console.log(index);
    console.log("List_item_arr");
    console.log(List_item_arr);
    
    
  };
  //================================

  const API_URL = process.env.REACT_APP_BASE_URL+"/api/Product/posted_filesAsync_admin_update";
  const API_URL_rprt_action_attachment = process.env.REACT_APP_BASE_URL+"/api/Product/getattachmentforreportbyrpnum";

  const API_METHOD = 'POST'
  const STATUS_IDLE = 0
  const STATUS_UPLOADING = 1
  const [files, setFiles] = React.useState([])
//=================================
const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);
const  thesectionnn = React.useRef("");
//=========================================
const [Report_status, setReport_status] = React.useState('');

const [Parties_informed_1_checked, setParties_informed_1_checked] = React.useState(false);
const [Parties_informed_2_checked, setParties_informed_2_checked] = React.useState(false);
const [Parties_informed_3_checked, setParties_informed_3_checked] = React.useState(false);
const [Parties_informed_4_checked, setParties_informed_4_checked] = React.useState(false);
const [Parties_informed_5_checked, setParties_informed_5_checked] = React.useState(false);

const [Generic_Hazard, setGeneric_Hazard] = React.useState('');
const [Specific_Hazard, setSpecific_Hazard] = React.useState('');
const   setSpecific_Hazard_ref = React.useRef('');
const   Root_cause_list = React.useRef('');

const [Scope_of_work, setScope_of_work] = React.useState('Select Rating');

const [Consequences, setConsequences] = React.useState('');
const [repAnalysis, setrepAnalysis] = React.useState('');

const  [action2_v, set_action2_v] = React.useState(false);
const  [action3_v, set_action3_v] = React.useState(false);
const  [action4_v, set_action4_v] = React.useState(false);
const  [action5_v, set_action5_v] = React.useState(false);
const  [action6_v, set_action6_v] = React.useState(false);
const  [action7_v, set_action7_v] = React.useState(false);
const  [action8_v, set_action8_v] = React.useState(false);

const  Risk_mitigation_action_tot  = React.useRef('');

const [Risk_mitigation_action1, setRisk_mitigation_action1] = React.useState('');

const [Risk_mitigation_action2, setRisk_mitigation_action2] = React.useState('');

const [Risk_mitigation_action3, setRisk_mitigation_action3] = React.useState('');

const [Risk_mitigation_action4, setRisk_mitigation_action4] = React.useState('');

const [Risk_mitigation_action5, setRisk_mitigation_action5] = React.useState('');

const [Risk_mitigation_action6, setRisk_mitigation_action6] = React.useState('');

const [Risk_mitigation_action7, setRisk_mitigation_action7] = React.useState('');

const [Risk_mitigation_action8, setRisk_mitigation_action8] = React.useState('');


const [Recommendations, setRecommendations] = React.useState('');
const [Conclusions, setConclusions] = React.useState('');
const [the_selected_node_id, setthe_selected_node_id] = React.useState('');

const [ rootchild, setrootchild] = React.useState([]);
const [ rootroot , setrootroot] = React.useState([]);

const [ childNodes_arr_us , setchildNodes_arr_us] = React.useState([]);




const [ rootchild_val, setrootchild_val] = React.useState('Select Root cause Item');
const [ rootroot_val , setrootroot_val] = React.useState('Select Root cause Category');

const   Rrootchild   = React.useRef([]);
const   Rrootroot    = React.useRef([]);
const   my_tree_arr    = React.useRef([]);

const [ list_obj , setlist_obj] = React.useState([]);



const   childNodes_arr    = React.useRef(['Select Root cause Item']);

 
const   List_item_obj_arr    = React.useRef([]);
 


const [List_item_arr_us , setList_item_arr_us] = React.useState([]);


const [expanded, setexpanded] = React.useState([]);
const [selectedItems, setSelectedItems] = React.useState([]);

const [ generic, setgeneric] = React.useState([]);
const [ specific , setspecific] = React.useState([]);

const   Rgeneric   = React.useRef([]);
const   Rspecific    = React.useRef([]);
 
const [Event_type, setEvent_type] = React.useState('');
const [Classification1, setClassification1] = React.useState('');
const [Classification2, setClassification2] = React.useState('');
const [Classification3, setClassification3] = React.useState('');
const [Classification4, setClassification4] = React.useState('');
const [Severity, setSeverity] = React.useState('');
const [Risk_index, setRisk_index] = React.useState('');
const [Risk_index_total, setRisk_index_total] = React.useState('');
const [JUST_CULTURE_sequence, setJUST_CULTURE_sequence] = React.useState('');
const [JUST_CULTURE, setJUST_CULTURE] = React.useState('');
   
const   setSelectedItems_val   = React.useRef(" ");

const [risk_index, setrisk_index] = React.useState('');
const [Selected_Root_Cause, setSelected_Root_Cause] = React.useState('');

const   new_or_existing    = React.useRef(true);
  {/**    
   Event_type_arr      Event_type, setEvent_type
   Classification1_arr Classification1, setClassification1
   Classification2_arr Classification2, setClassification2
   Classification3_arr Classification3, setClassification3
   Classification4_arr Classification4, setClassification4
   Severity_arr        Severity, setSeverity 

*/}
const  num_of_actions  =  useRef(1);


const [actiondat1L, setactiondat1L] = React.useState('');
const [actiondat1R, setactiondat1R] = React.useState('');
const [reviewedby1, setreviewedby1] = React.useState('');
const [Effectiveness1, setEffectiveness1] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat2L, setactiondat2L] = React.useState('');
const [actiondat2R, setactiondat2R] = React.useState('');
const [reviewedby2, setreviewedby2] = React.useState('');
const [Effectiveness2, setEffectiveness2] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat3L, setactiondat3L] = React.useState('');
const [actiondat3R, setactiondat3R] = React.useState('');
const [reviewedby3, setreviewedby3] = React.useState('');
const [Effectiveness3, setEffectiveness3] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat4L, setactiondat4L] = React.useState('');
const [actiondat4R, setactiondat4R] = React.useState('');
const [reviewedby4, setreviewedby4] = React.useState('');
const [Effectiveness4, setEffectiveness4] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat5L, setactiondat5L] = React.useState('');
const [actiondat5R, setactiondat5R] = React.useState('');
const [reviewedby5, setreviewedby5] = React.useState('');
const [Effectiveness5, setEffectiveness5] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat6L, setactiondat6L] = React.useState('');
const [actiondat6R, setactiondat6R] = React.useState('');
const [reviewedby6, setreviewedby6] = React.useState('');
const [Effectiveness6, setEffectiveness6] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat7L, setactiondat7L] = React.useState('');
const [actiondat7R, setactiondat7R] = React.useState('');
const [reviewedby7, setreviewedby7] = React.useState('');
const [Effectiveness7, setEffectiveness7] = React.useState('Select If Follow up Required');
//////////////////////////////////
const [actiondat8L, setactiondat8L] = React.useState('');
const [actiondat8R, setactiondat8R] = React.useState('');
const [reviewedby8, setreviewedby8] = React.useState('');
const [Effectiveness8, setEffectiveness8] = React.useState('Select If Follow up Required');
//////////////////////////////////

const [update_list, setupdate_list] = React.useState(true);

 const [Mandatory_name, setMandatory_name] = React.useState('');

const [dat, setdat] = React.useState('');
const [tim, settim] = React.useState('');
const [country, setcountry] = React.useState('');
const [cityval, setcityval] = React.useState('');
const [location, setlocation] = React.useState('');
const [headline, setheadline] = React.useState('');
const [type, settype] = React.useState('');
const [theclass, setclass] = React.useState('');
const [phase, setphase] = React.useState('');
const [category, setcategory] = React.useState('');
const [highestd, sethighestd] = React.useState('');
const [thirdpd, setthirdpd] = React.useState('');
const [injury, setinjury] = React.useState('');
const [acregis, setacregis] = React.useState('');
const [stateofreg, setstateofreg] = React.useState('');
const [thours, setthours] = React.useState('');
const [tcycles, settcycles] = React.useState('');
const [pnum, setpnum] = React.useState('');
const [ser, setser] = React.useState('');
const [NARRATIVE ,setNARRATIVE] = React.useState('');
const  the_NARRATIVE  =  useRef('');

//=================================
const [action ,setaction] = React.useState([]);
const [attachment ,setattachment] = React.useState([]);
const  theaction = useRef([]);
const  theattachment = useRef([]);
const  theselectedreport  = useRef('');
//=======================================
  const [ac, setac] = React.useState([])
  const [thecity, setcity] = React.useState([]);
  const [hours, sethours] = React.useState('');
  const [cycles, setcycles] = React.useState('')
 
  const [acval,setacval]= React.useState('Select A/C');

  const [Cityval, setCityval] = React.useState('Select City');
  const [Countryval, setCountryval] = React.useState('Select Country');
  
  const [Mandatory_Counter_g, setMandatory_Counter_g] = React.useState(0);
  const [Mandatory_Counter_y, setMandatory_Counter_y] = React.useState(0);
  const [Mandatory_Counter_r, setMandatory_Counter_r] = React.useState(0);
  const [Mandatory_Counter_total, setMandatory_Counter_total] = React.useState(0);
  const [if_Parties__informed_1, setif_Parties__informed_1] = React.useState(true);

   

  

  const [status, setStatus] = React.useState(STATUS_IDLE)
  const [theres, settheres] = React.useState("");
  const ac_lines=useRef([]);

  const TheOtherAc=useRef("");
  const [TheOtherAcus, setTheOtherAcus] = React.useState("");
  
  
  const the_Report_data=useRef([]);
  
  const the_last_Report_data=useRef([]);

  const [ShowOtherAC, setShowOtherAC] = React.useState(false);

  const city = React.useRef( [ {"city": "Select City"},{"city": "cairo"},]);

  //================================================================
  const handleClose = (value) => {
    setOpen(false);
    //setSelectedValue(value);
  };
  //==================================================================
  const handle_Parties_informed_1_checked_Change = (event) => {
    setParties_informed_1_checked(event.target.checked);
  };
  
  const handle_Parties_informed_2_checked_Change = (event) => {
    setParties_informed_2_checked(event.target.checked);
  };
  
  const handle_Parties_informed_3_checked_Change = (event) => {
    setParties_informed_3_checked(event.target.checked);
  };
  
  const handle_Parties_informed_4_checked_Change = (event) => {
    setParties_informed_4_checked(event.target.checked);
  };
  
  const handle_Parties_informed_5_checked_Change = (event) => {
    setParties_informed_5_checked(event.target.checked);
  };
   

  const uploadFiles = async (data)=> {
      setStatus(STATUS_UPLOADING);

      const response1 = await fetch(API_URL, {
          method: API_METHOD,
          body: data,
      })
      .then((res) => res.json())
          
      .then((data) => {
        console.log(data);
     
        console.log(data[0].val);
      })
      .catch((err) => console.error(err))
      .finally(() => 
      {
        setStatus(STATUS_IDLE);
       
        alert("Upload done"+theres);
      })

     // const result = await response1.json();console.log(result);  
  }

  const packFiles = (files,data1)=> {
      const data = new FormData();
      
      [...files].forEach((file, i) => {
          data.append(`file-${i}`, file, file.name)
      })
      console.log("data1 before append");
      console.log(data1);
      data.append('occ_date', dat);
      data.append('occ_time', tim);
      data.append('country', Countryval);
      data.append('city', Cityval);
      data.append('ext_occno',location );
      data.append('header', headline);
      data.append('occ_class', theclass);
      data.append('detection_phase', phase);
      data.append('occ_category', category);
      //  dat   tim Countryval Cityval  location headline type
             // theclass phase category highestd thirdpd injury acval
             // stateofreg hours cycles pnum  ser NARRATIVE

      console.log("dat "+dat+" tim "+tim+" Countryval "+Countryval+" Cityval "+Cityval+
                  " location "+location+" headline "+headline+ " type " + type +
                  " theclass "+theclass+" phase "+phase+" category "+ category+
                  " highestd "+highestd+" thirdpd "+thirdpd+" injury "+injury+
                  " acval "+acval+ "  stateofreg "+stateofreg+" hours  "+hours+
                  "  cycles "+cycles+" pnum "+pnum+" ser  "+ser+
                  "  NARRATIVE "+NARRATIVE+" OtherAc "+ TheOtherAcus ); 

      data.append('highest_damage', highestd);
      data.append('third_party_damage', thirdpd);
      data.append('injury_level', injury);
      data.append('ac_reg', acval);
      data.append('state_reg', stateofreg);
      data.append('ac_total_h', hours);
      data.append('ac_total_c', cycles);
      data.append('part_number', pnum);
      data.append('serial_number', ser);
      data.append('narrative_text', NARRATIVE);
      data.append('report_type', type);
      data.append('OtherAc', TheOtherAcus );//   TheOtherAc.current);rpnum
      data.append('rpnum', usercookie );//   TheOtherAc.current);rpnum
 
      data.append('reporter', '65479');
      data.append('user_id', '65479');
      console.log("data"); 
      console.log(data);
      return data
  }

  const handleUploadClick = () => {
      if (files.length) {
          const data = packFiles(files)
          uploadFiles(data)
      }
  }

  const renderFileList = () => (
  <ol className="flist">
      {[...files].map((f, i) => (
          <li key={i}>
            {f.name} 
            {/* - {f.type} */}
            </li>
      ))}
  </ol>)

  const renderButtonStatus = () => (
      (status === STATUS_IDLE) ? 
          'Send to server' : 
          <img src = "load.svg" style={{height:"40px"}} />
  )
 
  const { register, handleSubmit } = useForm();
  const [dense, setDense] = React.useState(true);
  const the_ac_list=useRef(
    [
      {
        "ac": "Select A/C"
      },
      
  ]);

  const onSubmit = (data1) => {

    console.log(data1);

   // if (files.length) {}
      const data = packFiles(files,data1)
      uploadFiles(data)
  

  };



  const get_city=async(datac)=>
  {
   //  

   const post = {
    country : datac,
  };
  

   const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectcitiesforcountry";
   let res = axios.create();
   res.defaults.timeout = 10000;
   //--------------------------------
   axios.defaults.headers.post["Content-Type"] =
     "application/x-www-form-urlencoded";
   axios.defaults.transformRequest = [
     function (data, headers) {
       var str = [];
       for (var p in data)
         if (data.hasOwnProperty(p) && data[p]) {
           str.push(
             encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
           );
         }
       return str.join("&");
     },
   ];

    res  = await axios.post( api_ac, post); 
   let cc= res.data;//['data'];  
 
   console.log("cc"); 
   console.log(cc); 
   city.current=[];
   city.current.push({"city": "Select City"});
   for (let y=0;y<cc.length;y++)
   {
    city.current.push({"city": cc[y].name});
   }
   console.log(city.current);
   setcity(city.current);
   
   

  }

  const get_mandatory_reportable_data = async(xx) => {
    var tt="65479";
    const post = {
      rpnum : xx,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getreportableduration', post); 
     let cc= res.data;//['data'];  
   
     console.log("report_mandatory_data"); 
     console.log(cc[0].val); 
     var mandatory_counter=  parseInt(cc[0].val)+1;
     setMandatory_Counter_total(mandatory_counter);
     if (mandatory_counter< 24)
      {
        setMandatory_Counter_g(mandatory_counter*10);
        setMandatory_Counter_y( 0);
        setMandatory_Counter_r( 0);
       
      }
      if  (  (mandatory_counter>= 24) && (mandatory_counter < 48 )  )
        {
          setMandatory_Counter_g(240);
          setMandatory_Counter_y((mandatory_counter-24) *10);
          setMandatory_Counter_r( 0);
         
        }
        if  (  (mandatory_counter>= 48) && (mandatory_counter < 72 )  )
          {
            setMandatory_Counter_g(240);
            setMandatory_Counter_y(240);
            setMandatory_Counter_r( (mandatory_counter-48) *10);
             
          }
          if  (   mandatory_counter >= 72 )  
            {
              setMandatory_Counter_g(240);
              setMandatory_Counter_y(240);
              setMandatory_Counter_r(240+(mandatory_counter-72) *10);
                
            }
           
 
     //---------------------------------------------------
 
       
  
  }
  
  const cookies = new Cookies();
  var usercookie = cookies.get('amosmobility1rep');
  ////////  report_last_modified
  const report_last_modified= async(xx) =>
    {
      var tt="65479";
      const post = {
        rpnum : xx,
      };
      
     let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getreportbyrpnum', post); 
       let cc= res.data;//['data'];  
     
       console.log("last reported cc"); 
       console.log(cc); 
        
      
  
       console.log("last reported cc length"); 
       console.log(cc.length); 
      
        the_last_Report_data.current=cc;
     //  the_Reports.current.push({"report_name": "Select Report" });
       
     //set_report_values(the_Report_data.current[0]);
        //setReportval('Select Report');
        get_reportable(cc[0].signature_name);
    } 
  ////////////////////////////////  
  const get_reportable=async(data)=>
    {
      const api_lst=process.env.REACT_APP_BASE_URL+"/api/Product/getreportableslist";
      const post = {
        country : data,
      };
      
    
       let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( api_lst, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
   
       console.log("report syntex of "+data); 
       for (let xx=0;xx<cc.length;xx++)
        {
          if (cc[xx]['number']==data)
          {
          console.log(cc[xx]['reportableittem']); 
          setMandatory_name(cc[xx]['reportableittem']);
          }
        }
       console.log(cc); 
    
        
  
      
  /* */   
   
  
    }

  //////////////////////////////////
  const get_report_data = async(xx) => {
    var tt="65479";
    const post = {
      rpnum : xx,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getreportforadminanalysis', post); 
     let cc= res.data;//['data'];  
   
     console.log("report_data"); 
     console.log(cc); 
      
     the_Report_data.current=cc;

     console.log("report_data length"); 
     console.log(cc.length); 
     //---------------------------------------------------
     var rnod=[];
     for (let rr=0;rr<Rrootroot.current.length;rr++)
     {
      rnod.push(rr.toString());
  
     }
     console.log("rnod");
     console.log(rnod);
    setexpanded(rnod) ;
    //----------------------------------------------------
     if (cc.length===0)
     {
       fill_for_new_report();
       new_or_existing.current=true;
     }
      else
      {
        fill_for_existing_report(cc);
        new_or_existing.current=false;
      }
      get_mandatory_reportable_data(xx);
   //  the_Reports.current.push({"report_name": "Select Report" });
     
   //set_report_values(the_Report_data.current[0]);
      //setReportval('Select Report');
  
  }
  ////////  new_or_existing.current

  const  fill_for_new_report = () => {
  
    setReport_status("Initial notification");

    setGeneric_Hazard("Select Generic Hazard");
    setSpecific_Hazard(""); 

     
setEvent_type('Select');
setClassification1('Select');
setClassification2('Select');
setClassification3('Select');
setClassification4('Select');
setSeverity('Select');
  

    Specific_Hazard_arr=[];
   // setexpanded(["7"]) ;    setSelectedItems(["7_47"]);
   

   // setexpanded(["7"]) ;
  }

  const  fill_for_existing_report = async (data) => {
  
   // setexpanded(["7"]) ;     
  /*
   {
     "Parties__informed_2": "true",
    "Parties__informed_3": "",
    "Parties__informed_4": "",
    "Parties__informed_5": "",
    "Parties__informed_6": "",
  }*/

  if (data[0].Parties__informed_1=="true")
    {
      setParties_informed_1_checked(true);
      setif_Parties__informed_1(false);
    }
  else{
      setParties_informed_1_checked(false);
      setif_Parties__informed_1(true);
    }
  //---------
  if (data[0].Parties__informed_2=="true"){setParties_informed_2_checked(true);}
  else{  setParties_informed_2_checked(false);}
  //---------
  if (data[0].Parties__informed_3=="true"){setParties_informed_3_checked(true);}
  else{  setParties_informed_3_checked(false);}
  //---------
  if (data[0].Parties__informed_4=="true"){setParties_informed_4_checked(true);}
  else{  setParties_informed_4_checked(false);}
  //---------
  if (data[0].Parties__informed_5=="true"){setParties_informed_5_checked(true);}
  else{  setParties_informed_5_checked(false);}
  //---------
          
setdat(data[0].Reporting_date);
setRisk_index_total(data[0].Risk_index);
setrisk_index(data[0].Risk_index.substring(0, 1));
setConsequences(data[0].Consequences);
setrepAnalysis(data[0].Analysis);
//setRisk_mitigation_action1(data[0].Risk_mitigation_action);
setScope_of_work(data[0].Recommendations);
setConclusions(data[0].Conclusions);
setReport_status(data[0].Report_status);
setEvent_type(data[0].Event_type);

setSelectedItems(data[0].Root_cause);
setSelected_Root_Cause(data[0].Root_cause);
setSelectedItems_val.current=data[0].Root_cause;

setSeverity(data[0].Risk_assessment);
setClassification1(data[0].Classification1);
setClassification2(data[0].Classification2);
setClassification3(data[0].Classification3);
setClassification4(data[0].Classification4); 

setGeneric_Hazard(data[0].Generic_Hazard);

calc_mitigation(data[0].Risk_mitigation_action);
calc_root_cause(data[0].Root_cause);
//-----------------------------------
var thediv_id1="c"+data[0].Risk_index.substring(0, 1)+data[0].Risk_assessment.split('-')[0];

     console.log("selected_div");
     console.log(thediv_id1);
     
     document.getElementById(thediv_id1).style.color= theselectedcolor ; 
     setRisk_index_total(data[0].Risk_index.substring(0, 1)+data[0].Risk_assessment.split('-')[0]);
//---------------------------------
const post = {
  generic : data[0].Generic_Hazard,
};

let res = axios.create();
 res.defaults.timeout = 10000;
 //--------------------------------
 axios.defaults.headers.post["Content-Type"] =
   "application/x-www-form-urlencoded";
 axios.defaults.transformRequest = [
   function (data, headers) {
     var str = [];
     for (var p in data)
       if (data.hasOwnProperty(p) && data[p]) {
         str.push(
           encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
         );
       }
     return str.join("&");
   },
 ];

  res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getspecificforgenericforadminanalysis', post); 
 let cc= res.data;//['data'];  

 console.log(cc) ;
//   generic  specific  Rgeneric  Rspecific  Specific_Hazard_arr
setspecific(cc);
Rspecific.current=cc;
console.log("Rspecific") ;
console.log(Rspecific.current.length) ;
console.log(Rspecific.current) ;

setSpecific_Hazard(data[0].Specific_Hazard); 

Specific_Hazard_arr=[];
//setSpecific_Hazard("Select specific Hazard");
var ttt=[];  
ttt.push({ value: "Select specific Hazard",label: "Select specific Hazard"});

for (let y=0;y<Rspecific.current.length;y++)
{

var cv={ value: Rspecific.current[y].specific_value ,label: Rspecific.current[y].specific_value  };
ttt.push(cv);
}

console.log("ttt") ;
console.log(ttt) ;
Specific_Hazard_arr=ttt;

//----------------------------------

  }

//----------------------------------
const  calc_mitigation=(xx)=>
{
   /*
    Risk_mitigation_action1
    actiondat1L
    actiondat1R
    reviewedby1
    Effectiveness1
    */
 
    console.log("xx.split('^')[1].split('`')[0].toString().trim().length") ;
    console.log(xx.split('^')[1].split('`')[0].toString().trim().length) ;

    
    if (xx.split('^')[1].split('`')[0].toString().trim().length>0)
    {
    setRisk_mitigation_action1(xx.split('^')[1].split('`')[0]);
    setactiondat1L(xx.split('^')[1].split('`')[1]);
    setactiondat1R(xx.split('^')[1].split('`')[2]);
    setreviewedby1(xx.split('^')[1].split('`')[3]);
    setEffectiveness1(xx.split('^')[1].split('`')[4]);
    }
    //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[2].split('`')[0].toString().trim().length>0)
      {
      set_action2_v(true); 
      num_of_actions.current= num_of_actions.current+1;

      setRisk_mitigation_action2(xx.split('^')[2].split('`')[0]);
      setactiondat2L            (xx.split('^')[2].split('`')[1]);
      setactiondat2R            (xx.split('^')[2].split('`')[2]);
      setreviewedby2            (xx.split('^')[2].split('`')[3]);
      setEffectiveness2         (xx.split('^')[2].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[3].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        /*
        set_action4_v(true);set_action5_v(true);
        set_action6_v(true);set_action7_v(true);
        set_action8_v(true);  
        */

      setRisk_mitigation_action3(xx.split('^')[3].split('`')[0]);
      setactiondat3L            (xx.split('^')[3].split('`')[1]);
      setactiondat3R            (xx.split('^')[3].split('`')[2]);
      setreviewedby3            (xx.split('^')[3].split('`')[3]);
      setEffectiveness3         (xx.split('^')[3].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[4].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        set_action4_v(true);
        /*set_action5_v(true);
        set_action6_v(true);set_action7_v(true);
        set_action8_v(true);  
        */
 
      setRisk_mitigation_action4(xx.split('^')[4].split('`')[0]);
      setactiondat4L            (xx.split('^')[4].split('`')[1]);
      setactiondat4R            (xx.split('^')[4].split('`')[2]);
      setreviewedby4            (xx.split('^')[4].split('`')[3]);
      setEffectiveness4         (xx.split('^')[4].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[5].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        set_action4_v(true);set_action5_v(true);
        /*set_action6_v(true);set_action7_v(true);
        set_action8_v(true);  
        */
 
      setRisk_mitigation_action5(xx.split('^')[5].split('`')[0]);
      setactiondat5L            (xx.split('^')[5].split('`')[1]);
      setactiondat5R            (xx.split('^')[5].split('`')[2]);
      setreviewedby5            (xx.split('^')[5].split('`')[3]);
      setEffectiveness5         (xx.split('^')[5].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[6].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        set_action4_v(true);set_action5_v(true);
        set_action6_v(true);
         /*
         set_action7_v(true);
         set_action8_v(true);  
        */
 
      setRisk_mitigation_action6(xx.split('^')[6].split('`')[0]);
      setactiondat6L            (xx.split('^')[6].split('`')[1]);
      setactiondat6R            (xx.split('^')[6].split('`')[2]);
      setreviewedby6            (xx.split('^')[6].split('`')[3]);
      setEffectiveness6         (xx.split('^')[6].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[7].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        set_action4_v(true);set_action5_v(true);
        set_action6_v(true);set_action7_v(true);
        /* set_action8_v(true);  
        */
  
      setRisk_mitigation_action7(xx.split('^')[7].split('`')[0]);
      setactiondat7L            (xx.split('^')[7].split('`')[1]);
      setactiondat7R            (xx.split('^')[7].split('`')[2]);
      setreviewedby7            (xx.split('^')[7].split('`')[3]);
      setEffectiveness7         (xx.split('^')[7].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
   //-------------------------------------------------------------
    //-------------------------------------------------------------
    if (xx.split('^')[8].split('`')[0].toString().trim().length>0)
      {
        num_of_actions.current= num_of_actions.current+1;
        set_action2_v(true);set_action3_v(true);
        set_action4_v(true);set_action5_v(true);
        set_action6_v(true);set_action7_v(true);
        set_action8_v(true);  
       /*  */
 
      setRisk_mitigation_action8(xx.split('^')[8].split('`')[0]);
      setactiondat8L            (xx.split('^')[8].split('`')[1]);
      setactiondat8R            (xx.split('^')[8].split('`')[2]);
      setreviewedby8            (xx.split('^')[8].split('`')[3]);
      setEffectiveness8         (xx.split('^')[8].split('`')[4]);
      }

    //-------------------------------------------------------------
    //-------------------------------------------------------------
    
}
const  calc_root_cause=(yy)=>
{
  var tt=[];
  for (let d=0;d<yy.split('`').length-1;d++)
    {
       tt.push({label:yy.split('`')[d],value:yy.split('`')[d]});
    }
  setList_item_arr_us(tt);
  List_item_arr=tt;
  console.log("setList_item_arr_us(tt);");
  console.log( tt );
  //-----------------------------------
  var xx=[];
        
  for (let y=0;y<tt.length;y++)
   {
     xx.push(
             <ListItemButton  key={"lib"+y}
               selected={selectedIndex === y}
               onClick={(event) => handleListItemClick(event, y)} >
             <ListItemText  key={"lit"+y} primary={tt[y].label} />
             </ListItemButton>
            );
   }

 setlist_obj(xx);
  setupdate_list(true);
  //-------------------------------------
}

//------------------------------------------
  const set_report_values = (data) => {
    // alert ( event.target.value );
    console.log("Selected report"); 
    console.log(data ); 

   
    console.log("data.UTC_date"); 
    console.log(data.UTC_date); 
    
 
    setdat(data.UTC_date);
    settim(data.UTC_time);


    setcountry(data.State_of_occ_Level1);
    setCountryval(data.State_of_occ_Level1);
    setCityval(data.area_of_occ_level2);
    setcityval(data.area_of_occ_level2);


    setlocation(data['Location name']);
    setheadline(data.Headline);
    settype(data.report_type);
    setclass(data.Occurrence_class);
    setphase(data.Detection_phase);
    setcategory(data.Occurrence_category);
    sethighestd(data.highest_damage);
    setthirdpd(data.third_party_damage);
    setinjury(data.injury_level);
   
    setstateofreg(data.state_of_registry);
    sethours(data.Aircraft_total_time);
    setcycles(data.Aircraft_total_cycles);
    setpnum(data.part_number);
    setser(data.part_serial);
    the_NARRATIVE.current=data.NARRATIVE ;
    var ac_controlled=false;

    for (let rr=0;rr<the_ac_list.current.length;rr++)
    {
      if (the_ac_list.current[rr].ac==data.ac_registeration)
      {
        ac_controlled=true;
        break;
      }
    }


    if(ac_controlled==true)
    {
        setacval(data.ac_registeration);
        setShowOtherAC(false);
    }
   else{
    setacval("Other");
    setShowOtherAC(true);
    setTheOtherAcus(data.ac_registeration);
   }

    get_action(usercookie);

  }

  const get_action=async(trt)=>
  {
    
     
      const post = {
        rpnum : trt,
      };
      
     let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( API_URL_rprt_action_attachment, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
        
       theaction.current=cc;
  
       console.log("cc length"); 
       console.log(cc.length); 
      
       theaction.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theaction.current.push(cc[y].action );
        console.log("theaction.current"); 
        console.log(theaction.current); 
      
       }

       
  
       
      
       theattachment.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theattachment.current.push(cc[y].attachements );
        console.log("theattachment.current"); 
        console.log(theattachment.current); 
      
       }

       //  attachements
       console.log("getting action"); 
      

       //  setNARRATIVE
       for (let pp=0;pp<theaction.current.length;pp++)
       {
        the_NARRATIVE.current=the_NARRATIVE.current + "\r\n" +  theaction.current[pp]+"\r\n";
       }
       setNARRATIVE(the_NARRATIVE.current);

       setaction(theaction.current);
       setattachment(theattachment.current);
    
  }

  var theselectedcolor=red[200];
  //var thediv_id1="c"+5+"a";
  var thediv_id2="c"+4+"d";

  const get_root_cause_only=async(data)=>
  {
 
    const post = {
      rpnum : data,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getrootcauseonlyadminanalysis', post); 
     let cc= res.data;//['data'];  
   
     console.log("Rrootroot.current") ;
     console.log(cc) ;
     
     setrootroot(cc);
     Rrootroot.current=cc;

     get_root_cause(" ");
  }
  
  const get_root_cause =async(data)=>
  {
     const post = {
      rpnum : data,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getallrootcauseadminanalysis', post); 
     let cc= res.data;//['data'];  
   
     console.log(cc) ;
   
  setrootchild(cc);
  Rrootchild.current=cc;
  console.log("rootroot") ;
  console.log(Rrootroot.current.length) ;
  //------------------------------------------------- my_tree_arr
  var ttt=[];
  for (let y=0;y<Rrootroot.current.length-1;y++)
  {
    var childNodes_arr=[];
    for (let s=0;s<Rrootchild.current.length-1;s++)
    { 
      

       if (Rrootroot.current[y].root_cause===Rrootchild.current[s].root_cause)
       {
        var item={id: y+"_"+s,parentId: y,title: Rrootchild.current[s].sub_cause}
        childNodes_arr.push(item);
          
       }
    }
    var cv={ id: y ,title: Rrootroot.current[y].root_cause,childNodes: childNodes_arr };
    ttt.push(cv);
  }

  console.log("ttt") ;
  console.log(ttt) ;
 
  my_tree_arr.current=ttt;
  //-----------------------------------------------------
  get_generic(" ");
  }
  

  //       //  //   generic  specific  Rgeneric  Rspecific
  const get_generic =async(data)=>
  {
     const post = {
      rpnum : data,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getallgenericforadminanalysis', post); 
     let cc= res.data;//['data'];  
   
     console.log(cc) ;
   
  setgeneric(cc);
  Rgeneric.current=cc;
  console.log("Rgeneric") ;
  console.log(Rgeneric.current.length) ;
  console.log(Rgeneric.current) ;
  
   Generic_Hazard_arr=[];
   var ttt=[];  
   ttt.push({ value: "Select Generic Hazard",label: "Select Generic Hazard"});

   for (let y=0;y<Rgeneric.current.length;y++)
  {
    
    var cv={ value: Rgeneric.current[y].generic_value ,label: Rgeneric.current[y].generic_value  };
    ttt.push(cv);
  }
    
  console.log("ttt") ;
  console.log(ttt) ;
  Generic_Hazard_arr=ttt;
  //-------------------------------------------------  
  
 
  //-----------------------------------------------------
  get_report_data(usercookie);
  
  }
  
  //--------------
  const show_Probability_details=(e)=>
  {
    
    //---------------------------------------------
    get_Probability_details();
    //---------------------------------------------
    
   
  }
  const get_Probability_details=async( )=>
    {
        
     

      console.log(" Scope_of_work");
      console.log(Scope_of_work);
      const post = {
        specific : Specific_Hazard,
        generic : Generic_Hazard,
        rating : Scope_of_work,
        report_number : usercookie
      };
      
     let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getriskindexdetails', post); 
       let cc= res.data;//['data'];  
     
       console.log("getriskindexdetails") ;
       console.log(cc ) ;
  
     //==================================
       
    //-----------------------------------------------------
    
      setSelectedValue(cc);
      setOpen(true);
    }
 
  //       //

  useEffect(()=>
  {
    setMandatory_Counter_g(240);
    setMandatory_Counter_y(240);
    setMandatory_Counter_r(240);
    setMandatory_Counter_total(720);
    //   
    //--------------------------------------------
    //document.getElementById(thediv_id1).style.color= theselectedcolor ; 
    //document.getElementById(thediv_id2).style.color= theselectedcolor ; 
    //----------------------------------------------
   console.log("transferred");
   console.log(usercookie);
    
    // get_ac(" ");
  get_root_cause_only(" ");


  report_last_modified(usercookie);

  //--------------------------------------
 
  List_item_obj_arr.current.push( "Trashing"  );

  //-----------------------------------------
  },[]);

  
  const handle_Generic_Hazard_Change = async(event) =>{

    setGeneric_Hazard(event);

    console.log("changed");
    console.log(event);
    const post = {
      generic : event,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getspecificforgenericforadminanalysis', post); 
     let cc= res.data;//['data'];  
   
     console.log(cc) ;
   //   generic  specific  Rgeneric  Rspecific  Specific_Hazard_arr
  setspecific(cc);
  Rspecific.current=cc;
  console.log("Rspecific") ;
  console.log(Rspecific.current.length) ;
  console.log(Rspecific.current) ;
  
  Specific_Hazard_arr=[];
  setSpecific_Hazard("Select specific Hazard");
   var ttt=[];  
   ttt.push({ value: "Select specific Hazard",label: "Select specific Hazard"});

   for (let y=0;y<Rspecific.current.length;y++)
  {
    
    var cv={ value: Rspecific.current[y].specific_value ,label: Rspecific.current[y].specific_value  };
    ttt.push(cv);
  }
    
  console.log("ttt") ;
  console.log(ttt) ;
  Specific_Hazard_arr=ttt;
  //-------------------------------------------------  
  /* 
   Generic_Hazard_arr  = [
  
  {
    value: "Select Generic Hazard",
    label: "Select Generic Hazard",
  }]
  */
 
  //-----------------------------------------------------
  
  }
  
  const handle_setSeverity =  (event) =>
  {
     setSeverity(event) ;
     
     var sever=event.split('-')[0];

     console.log("sever");
     console.log(sever);
     
     if ((sever===null)   || (sever===undefined)  || (sever=="Select")   )
     {
      return;
     }
     Reset_divs();
     var thediv_id1="c"+risk_index+sever;

     console.log("selected_div");
     console.log(thediv_id1);
     
     document.getElementById(thediv_id1).style.color= theselectedcolor ; 
     setRisk_index_total(risk_index+sever);
    
  }

  const Reset_divs =  ( ) =>{
    document.getElementById("c1a").style.color= "#000" ; 
    document.getElementById("c1b").style.color= "#000" ; 
    document.getElementById("c1c").style.color= "#000" ; 
    document.getElementById("c1d").style.color= "#000" ; 
    document.getElementById("c1e").style.color= "#000" ; 

    document.getElementById("c2a").style.color= "#000" ; 
    document.getElementById("c2b").style.color= "#000" ; 
    document.getElementById("c2c").style.color= "#000" ; 
    document.getElementById("c2d").style.color= "#000" ; 
    document.getElementById("c2e").style.color= "#000" ; 
  

    document.getElementById("c3a").style.color= "#000" ; 
    document.getElementById("c3b").style.color= "#000" ; 
    document.getElementById("c3c").style.color= "#000" ; 
    document.getElementById("c3d").style.color= "#000" ; 
    document.getElementById("c3e").style.color= "#000" ; 
   
    document.getElementById("c4a").style.color= "#000" ; 
    document.getElementById("c4b").style.color= "#000" ; 
    document.getElementById("c4c").style.color= "#000" ; 
    document.getElementById("c4d").style.color= "#000" ; 
    document.getElementById("c4e").style.color= "#000" ; 
  
    document.getElementById("c5a").style.color= "#000" ; 
    document.getElementById("c5b").style.color= "#000" ; 
    document.getElementById("c5c").style.color= "#000" ; 
    document.getElementById("c5d").style.color= "#000" ; 
    document.getElementById("c5e").style.color= "#000" ; 
  
    
  }
  
  const handle_Scope_of_work_Change = async(event) =>{
     
    setScope_of_work(event) ;
     

    console.log("rating changed");
    console.log(event);
    const post = {
      specific : Specific_Hazard,
      generic : Generic_Hazard,
      rating : event,
      report_number : usercookie
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( process.env.REACT_APP_BASE_URL+'/api/Product/getriskindex', post); 
     let cc= res.data;//['data'];  
   
     console.log("getriskindex") ;
     console.log(cc ) ;
     console.log("event") ;
     console.log(event) ;
   //   generic  specific  Rgeneric  Rspecific  Specific_Hazard_arr
   setrisk_index(cc );
   //==================================
    
     
   var sever=Severity.split('-')[0];

   console.log("sever");
   console.log(sever);
   
   if ((sever===null)   || (sever===undefined)  || (sever=="Select")   )
   {
    return;
   }
   Reset_divs();
   var thediv_id1="c"+cc+sever;

   console.log("selected_div");
   console.log(thediv_id1);
   
   document.getElementById(thediv_id1).style.color= theselectedcolor ; 
   setRisk_index_total(cc+sever);
  
  //-----------------------------------------------------
  
  }

  const handle_Specific_Hazard_Change = async(event) =>{
     
    setSpecific_Hazard(event) ;
    setSpecific_Hazard_ref.current=event;

    console.log("specific changed");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }
  const handle_Effectiveness1_Change = async(event) =>{
     
    setEffectiveness1(event) ;
     
    console.log("handle_Effectiveness1");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness2_Change = async(event) =>{
     
    setEffectiveness2(event) ;
     
    console.log("handle_Effectiveness2");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }
  const handle_Effectiveness3_Change = async(event) =>{
     
    setEffectiveness3(event) ;
     
    console.log("handle_Effectiveness3");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness4_Change = async(event) =>{
     
    setEffectiveness4(event) ;
     
    console.log("handle_Effectiveness4");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness5_Change = async(event) =>{
     
    setEffectiveness5(event) ;
     
    console.log("handle_Effectiveness5");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness6_Change = async(event) =>{
     
    setEffectiveness6(event) ;
     
    console.log("handle_Effectiveness6");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness7_Change = async(event) =>{
     
    setEffectiveness7(event) ;
     
    console.log("handle_Effectiveness7");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }

  const handle_Effectiveness8_Change = async(event) =>{
     
    setEffectiveness8(event) ;
     
    console.log("handle_Effectiveness8");
    console.log(event);
   
    
  //-----------------------------------------------------
  
  }



  const handle_country_Change = (event) => {
    setCountryval(event.target.value);
    get_city(event.target.value);
   // alert ( event.target.value );
  };
  const handle_city_Change = (event) => {
    setCityval(event.target.value);
   // alert ( event.target.value );
  };
  
  const handle_ac_Change = (event) => {

   // hours.current cycles.current
   if (event.target.value.trim()==="Other")
   {

    alert ( "Other" );
    setacval(event.target.value);
    sethours("");
    setcycles("");
    console.log("return");
    setShowOtherAC(true);
    return ;
   }

   setShowOtherAC(false);

   console.log("not return");

   for (let y=0;y<ac_lines.current.length-1;y++)
   {
    console.log(ac_lines.current[y].split(',')[0]);
     if (ac_lines.current[y].split(',')[0]===event.target.value)
     {
      console.log('found');
      sethours(ac_lines.current[y].split(',')[1]);

      setcycles(ac_lines.current[y].split(',')[2]);
         
    
 
     }  
   }

    setacval(event.target.value);


    alert ( event.target.value );
  };
  
  const Close =(event)=>
  {

  };
  const Add_Action =(event)=>
  {
    num_of_actions.current= num_of_actions.current+1;
if ( num_of_actions.current==2)
{
set_action2_v(true);
}
if ( num_of_actions.current==3)
{
set_action3_v(true);
}
if ( num_of_actions.current==4)
{
set_action4_v(true);
}
if ( num_of_actions.current==5)
{
set_action5_v(true);
}
if ( num_of_actions.current==6)
{
set_action6_v(true);
}
if ( num_of_actions.current==7)
{
  set_action7_v(true);
}
if ( num_of_actions.current==8)
  {
    set_action8_v(true);
  }
                                  
  };
   
   
  //-----------------------------------------
  const insert =async ( )=>
  {
    //------------
    /**
      report_num  Report_status  Reporting_date  
      Parties__informed_1  Parties__informed_2   Parties__informed_3  
      Parties__informed_4 Parties__informed_5 Parties__informed_6  
      Generic_Hazard Specific_Hazard   Consequences 
      Risk_index Risk_assessment  Analysis  Root_cause Risk_mitigation_action  
      Recommendations Conclusions  Event_type 
      Classification1  Classification2   Classification3   Classification4
      JUST_CULTURE_sequence   JUST_CULTURE 
      
     */
    /**
Report_status Parties_informed_1_checked  Parties_informed_2_checked
Parties_informed_3_checked Parties_informed_4_checked Parties_informed_5_checked
Generic_Hazard Specific_Hazard Consequences repAnalysis           
Risk_mitigation_action Recommendations Conclusions the_selected_node_id 
rootchild  rootroot   Rrootchild  Rrootroot  my_tree_arr 
expanded selectedItems  generic specific  Event_type 
Classification1 Classification2 Classification3 Classification4 Severity 
   

     */
    //--------------
    const post = {
      report_num : usercookie, 
      Report_status  : Report_status,  
      Reporting_date  : dat ,  
      Parties__informed_1 : Parties_informed_1_checked,
      Parties__informed_2  : Parties_informed_2_checked,
      Parties__informed_3  : Parties_informed_3_checked,  
      Parties__informed_4 : Parties_informed_4_checked,  
      Parties__informed_5 : Parties_informed_5_checked,  
      Parties__informed_6  : Parties_informed_5_checked,  
      Generic_Hazard : Generic_Hazard,  
      Specific_Hazard  : Specific_Hazard,   
      Consequences  : Consequences, 
      Risk_index : Risk_index_total,   
      Risk_assessment : Severity,   
      Analysis : repAnalysis,   
      Root_cause : Root_cause_list.current ,  
      Risk_mitigation_action :Risk_mitigation_action_tot.current ,   
      Recommendations : Scope_of_work ,  
      Conclusions  : Conclusions,  
      Event_type  : Event_type , 
      Classification1 : Classification1,   
      Classification2 : Classification2,    
      Classification3  : Classification3,   
      Classification4 : Classification4, 
      JUST_CULTURE_sequence : JUST_CULTURE_sequence,    
      JUST_CULTURE  : JUST_CULTURE, 
      analizer_code  : analizer_code, 
    };
    
  
     const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/adminanalysisinsert";
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( api_ac, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
    //------------

  };
  //-----------------------------------------
  const calc=()=>{
   // Root_cause_list.current
   // setlist_obj  setList_item_arr_us
   // Risk_mitigation_action_tot.current
   /*
    Risk_mitigation_action1
    actiondat1L
    actiondat1R
    reviewedby1
    Effectiveness1
    */
   for (let u=0;u<List_item_arr_us.length;u++)
    {
      Root_cause_list.current=Root_cause_list.current+List_item_arr_us[u].label+"`";
    }
    //------------------------------------------------------------------
    Risk_mitigation_action_tot.current=Risk_mitigation_action_tot.current+
    //
    "^"+
    Risk_mitigation_action1+"`"+actiondat1L+"`"+actiondat1R+"`"+
    reviewedby1+"`"+Effectiveness1+
    //
    "^"+
    Risk_mitigation_action2+"`"+actiondat2L+"`"+actiondat2R+"`"+
    reviewedby2+"`"+Effectiveness2+
    //
    "^"+
    Risk_mitigation_action3+"`"+actiondat3L+"`"+actiondat3R+"`"+
    reviewedby3+"`"+Effectiveness3+
    //
    "^"+
    Risk_mitigation_action4+"`"+actiondat4L+"`"+actiondat4R+"`"+
    reviewedby4+"`"+Effectiveness4+
    //
    "^"+
    Risk_mitigation_action5+"`"+actiondat5L+"`"+actiondat5R+"`"+
    reviewedby5+"`"+Effectiveness5+
    //
    "^"+
    Risk_mitigation_action6+"`"+actiondat6L+"`"+actiondat6R+"`"+
    reviewedby6+"`"+Effectiveness6+
    //
    "^"+
    Risk_mitigation_action7+"`"+actiondat7L+"`"+actiondat7R+"`"+
    reviewedby7+"`"+Effectiveness7+
    //
    "^"+
    Risk_mitigation_action8+"`"+actiondat8L+"`"+actiondat8R+"`"+
    reviewedby8+"`"+Effectiveness8;
    //


  }
  //-----------------------------------------
  const update =async( )=>
  {
    calc();
    //--   adminanalysisupdate

        //--------------
        const post = {
          report_num : usercookie, 
          Report_status  : Report_status,  
          Reporting_date  : dat ,  
          Parties__informed_1 : Parties_informed_1_checked,
          Parties__informed_2  : Parties_informed_2_checked,
          Parties__informed_3  : Parties_informed_3_checked,  
          Parties__informed_4 : Parties_informed_4_checked,  
          Parties__informed_5 : Parties_informed_5_checked,  
          Parties__informed_6  : Parties_informed_5_checked,  
          Generic_Hazard : Generic_Hazard,  
          Specific_Hazard  : Specific_Hazard,   
          Consequences  : Consequences, 
          Risk_index : Risk_index_total,   
          Risk_assessment : Severity,   
          Analysis : repAnalysis,   
          Root_cause : Root_cause_list.current,  
          Risk_mitigation_action :Risk_mitigation_action_tot.current ,   
          Recommendations : Scope_of_work ,  
          Conclusions  : Conclusions,  
          Event_type  : Event_type , 
          Classification1 : Classification1,   
          Classification2 : Classification2,    
          Classification3  : Classification3,   
          Classification4 : Classification4, 
          JUST_CULTURE_sequence : JUST_CULTURE_sequence,    
          JUST_CULTURE  : JUST_CULTURE, 
          analizer_code  : analizer_code, 
        };
        
      
         const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/adminanalysisupdate";
         let res = axios.create();
         res.defaults.timeout = 10000;
         //--------------------------------
         axios.defaults.headers.post["Content-Type"] =
           "application/x-www-form-urlencoded";
         axios.defaults.transformRequest = [
           function (data, headers) {
             var str = [];
             for (var p in data)
               if (data.hasOwnProperty(p) && data[p]) {
                 str.push(
                   encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
                 );
               }
             return str.join("&");
           },
         ];
      
          res  = await axios.post( api_ac, post); 
         let cc= res.data;//['data'];  
       
         console.log("cc"); 
         console.log(cc); 
        //------------
    
  };
 
  //--------------------------------------
  const SaveAnalysis =(event)=>
  {
    //-----  new_or_existing.current
    if (new_or_existing.current==true)
    {
    //-----  insert  
    insert();
    }
    else
    {
     // ---- update
     update();
    }

  };
  
  const Add_to_List =(event)=>
    {
       var total_cause=rootroot_val+"^"+rootchild_val;
       List_item_arr.push( {value:total_cause,label:   total_cause}) ; 
       setList_item_arr_us(List_item_arr);
       var xx=[];
        
       for (let y=0;y<List_item_arr.length;y++)
        {
          xx.push(
                  <ListItemButton  key={"lib"+y}
                    selected={selectedIndex === y}
                    onClick={(event) => handleListItemClick(event, y)} >
                  <ListItemText  key={"lit"+y} primary={List_item_arr[y].label} />
                  </ListItemButton>
                 );
        }

      setlist_obj(xx);
       setupdate_list(true);
       console.log(List_item_arr);
       console.log(List_item_arr_us);
  
    };
    const Remove_From_List =(event)=>
      {
        /*
        List_item_arr=[];
        setList_item_arr_us([]);
        setupdate_list(true);
        */
        //List_item_arr.pop();
        List_item_arr.splice(selectedIndex, 1);

      

        setList_item_arr_us(List_item_arr);
        var xx=[];
         
        for (let y=0;y<List_item_arr.length;y++)
         {
           xx.push(
                   <ListItemButton  key={"lib"+y}
                     selected={selectedIndex === y}
                     onClick={(event) => handleListItemClick(event, y)} >
                   <ListItemText  key={"lit"+y} primary={List_item_arr[y].label} />
                   </ListItemButton>
                  );
         }
 
       setlist_obj(xx);
        setupdate_list(true);
        console.log(List_item_arr);
        console.log(List_item_arr_us);
      };

     

  const prepare_cause_item =(event)=>
    {   
      childNodes_arr.current=[];

      childNodes_arr.current.push('Select Root cause Item');
      setrootroot_val(event);
     // Rrootchild.current=[];
     
        console.log("main");
        console.log(event);
   
        for (let s=0;s<Rrootchild.current.length-1;s++)
        { 
           if (event===Rrootchild.current[s].root_cause)
           {
             childNodes_arr.current.push(Rrootchild.current[s].sub_cause);
              
           }
        }
       setrootchild_val('Select Root cause Item');
      setchildNodes_arr_us(childNodes_arr.current);
    
  
    };
      
  //------------------------------------------
  const handle_node_selection=(e, nodeId)=>
  {
    console.log(e);
    console.log(nodeId);
    setSelectedItems_val.current=nodeId;
    setSelected_Root_Cause(nodeId);
  }
    
  const displayTreeView = (treeViewArray) => {
    if (!treeViewArray) {
      return null;
    }
    return treeViewArray.map((treeViewItem) => {
      return (
        <TreeItem
          key={treeViewItem.id}
          nodeId={`${treeViewItem.id}`}
          label={treeViewItem.title}
        >
          {displayTreeView(treeViewItem.childNodes)}
        </TreeItem>
      );
    });
  };

  //--------------------------------------------
 
  return (

    <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <Box

      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}

    >
      <Box  sx={{  display: "flex",  alignItems: "center",
                  width:'100%',justifyContent:'center',
                   flexDirection: "row",  gap: 2,}} >
       Admin Occurance Report Analysis
      </Box>
      <Paper
        elevation={17}
        sx={{
          p: "20px",
          width: "100%",
          minWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          fontSize: "25px",
          fontWeight: 800,
        }}
      >
        <Typography >REPORT MANAGEMENT</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
 

        <Select   
        labelId="demo-simple-select-autowidth-label3"
        id="demo-simple-select-autowidth3"
        autoWidth
          label="Report status" 
          defaultValue={Report_status}
          value={Report_status} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setReport_status(e.target.value)}
          >
            {Report_status_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
 
          <TextField
            label="UTC Date"
      
            defaultValue={dat}
            value={dat}
            onChange={(e)=>{setdat(e.target.value);}}
            variant="filled"
            
            InputLabelProps={{ shrink: true }}
          />
         
       
 

        </Stack>

        {/*----------------------
 
 Parties_informed_2_checked 
 Parties_informed_3_checked 
 Parties_informed_4_checked 
 Parties_informed_5_checked 
     
        --------------------------------------*/}
        {/*------    if_Parties__informed_1                      -----------*/}
        <Typography > Mandatory Or Not </Typography>
        <Typography > {Mandatory_name} </Typography>
    
       { true && 
        <> 
        <Typography > Mandatory Counter</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
        <div style={{display: 'flex' , flexDirection: 'row'}} >
        <div style={{paddingRight:'0px',paddingLeft:'0px' ,
                    marginRight:'0px',marginLeft:'0px',width: Mandatory_Counter_g+'px',
                    height:'50px',backgroundColor:'#00ff00'}}></div>

        <div style={{paddingRight:'0px',paddingLeft:'0px' ,
                    marginRight:'0px',marginLeft:'0px',width: Mandatory_Counter_y+'px',
                    height:'50px',backgroundColor:'#ffff00'}}></div>

        <div style={{paddingRight:'0px',paddingLeft:'0px' ,
                    marginRight:'0px',marginLeft:'0px',width: Mandatory_Counter_r+'px',
                    height:'50px',backgroundColor:'#ff0000'}}></div>

          </div>
          <Typography >{Mandatory_Counter_total } Hours </Typography>
        </Stack>
        </>
        }

        {/**          */}
        <Typography >Parties informed  </Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
       
        <FormGroup>

      <FormControlLabel control={
      <Checkbox
      checked={Parties_informed_1_checked}
      onChange={handle_Parties_informed_1_checked_Change}
      inputProps={{ 'aria-label': 'controlled' }}
    />
      } label="State of registry " />


      <FormControlLabel  control={
      <Checkbox
      checked={Parties_informed_2_checked}
      onChange={handle_Parties_informed_2_checked_Change}
      inputProps={{ 'aria-label': 'controlled' }}
    />
      } label="Type certificate / Approval holder" />
      <FormControlLabel  control={
      <Checkbox
      checked={Parties_informed_3_checked}
      onChange={handle_Parties_informed_3_checked_Change}
      inputProps={{ 'aria-label': 'controlled' }}
    />
      } label="Owner" />
      <FormControlLabel  control={
       <Checkbox
       checked={Parties_informed_4_checked}
       onChange={handle_Parties_informed_4_checked_Change}
       inputProps={{ 'aria-label': 'controlled' }}
     />
      } label="Operator" />
      <FormControlLabel  control={
      <Checkbox
      checked={Parties_informed_5_checked}
      onChange={handle_Parties_informed_5_checked_Change}
      inputProps={{ 'aria-label': 'controlled' }}
    />
      } label="CAMO" />


        </FormGroup>

       
       
         

        </Stack>

        {/**              */}



{/** */}


<Typography >Event type </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label32"
        id="demo-simple-select-autowidth32"
        autoWidth
          label="Event type " 
          defaultValue={Event_type}
          value={Event_type} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setEvent_type(e.target.value)}
          >
            {Event_type_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
{/** */}  
{/** */}

 
<Typography >Classification1 </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label33"
        id="demo-simple-select-autowidth33"
        autoWidth
          label="Classification1" 
          defaultValue={Classification1}
          value={Classification1} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setClassification1(e.target.value)}
          >
            {Classification1_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
{/** */}  
{/** */}
<Typography >Classification2 </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label34"
        id="demo-simple-select-autowidth34"
        autoWidth
          label="Classification2" 
          defaultValue={Classification2}
          value={Classification2} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setClassification2(e.target.value)}
          >
            {Classification2_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
{/** */}  
{/** */}

<Typography >Classification3 </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label35"
        id="demo-simple-select-autowidth35"
        autoWidth
          label="Classification3" 
          defaultValue={Classification3}
          value={Classification3} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setClassification3(e.target.value)}
          >
            {Classification3_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
{/** */}  
{/** */}
 {/**    
      
*/}

        <Typography >Classification4 </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label36"
        id="demo-simple-select-autowidth36"
        autoWidth
          label="Classification4" 
          defaultValue={Classification4}
          value={Classification4} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>setClassification4(e.target.value)}
          >
            {Classification4_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
{/** */}  
        {/*------------------------------------------------------------*/}
 
        <Typography >RISK</Typography>

        <Stack sx={{ gap: 2 }} direction={"column"}>

        <Select   
        labelId="demo-simple-select-autowidth-label3"
        id="demo-simple-select-autowidth3"
        autoWidth
          label="Generic Hazard" 
          defaultValue={Generic_Hazard}
          value={Generic_Hazard} 
          variant="filled" sx={{ flex: 1 }} 
          onChange={(e)=>handle_Generic_Hazard_Change(e.target.value)}
          >
            {Generic_Hazard_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
 
        <Select   
        labelId="demo-simple-select-autowidth-label3"
        id="demo-simple-select-autowidth3"
        autoWidth
          label="Specific Hazard" 
          defaultValue={Specific_Hazard}
          value={Specific_Hazard} 
          variant="filled" sx={{ flex: 1 }} 
          
          onChange={(e)=>handle_Specific_Hazard_Change(e.target.value)}
          >
            {Specific_Hazard_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
 
        <Select   
        labelId="demo-simple-select-autowidth-label33"
        id="demo-simple-select-autowidth33"
        autoWidth
          label="Scope of work" 
          defaultValue={Scope_of_work}
          value={Scope_of_work} 
          variant="filled" sx={{ flex: 1 }} 
          
          onChange={(e)=>handle_Scope_of_work_Change(e.target.value)}
          >
            {Scope_of_work_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
 
 
        </Stack>

 

      
{}
      {/** */}
      <Typography >Risk Index Probability </Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
        
        <TextField
            label="Risk Index Probability"
            sx={{marginRight:'20px', 
            textTransform: "capitalize",
            backgroundColor: red[50] ,
            color:green['A700'],
            width:'300px',
          }} 
            readOnly={true}
            value={risk_index}
            // onChange={(e)=>{setdat(e.target.value);}}
            variant="filled"
            
            InputLabelProps={{ shrink: true }}
          />
          
          <Button
            type="button"
            sx={{marginRight:'20px', 
            textTransform: "capitalize",
            backgroundColor:brown[200] ,
            //color:green['A700'],
            width:'300px',
          }}
            variant="contained"
            
            onClick={(e)=>{show_Probability_details(e);}}
          >
           Show Probability Details
          </Button>
         
        </Stack>
      {/** */}
        <SimpleDialog
            

          selectedValue={selectedValue}
          selectedValue1={thesectionnn.current}
           // selectedValue1={thesection}
        
          open={open}
          onClose={handleClose}
         />
     
      {/** */}
        <Typography >Severity </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Select   
        labelId="demo-simple-select-autowidth-label31"
        id="demo-simple-select-autowidth31"
        autoWidth
          label="Severity" 
          defaultValue={Severity}
          value={Severity} 
          variant="filled" sx={{ flex: 1 }} 
          
          onChange={(e)=>handle_setSeverity(e.target.value)}
          >
            {Severity_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </Stack>
      {/** */}
      <Typography >Risk Index </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <TextField
            label="Risk Index"
      
            readOnly={true}
            value={Risk_index_total}
            // onChange={(e)=>{setdat(e.target.value);}}
            variant="filled"
            
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      {/**   matrix */}
       
        <Box sx={{ textAlign: "center" }}>
        <div className="matrixrow">

        <div className="srd"  >Risk Index</div>  
        <div className="ccw"  >Severity  </div>
        </div>

        <div className="matrixrow">

        <div className="srd" id="prob">Probability</div>  
        <div className="cc"  >Catastrophic  <br/> A</div>
        <div className="cc"  >Hazardous <br/> B</div>
        <div className="cc"  >Major <br/> C</div>
        <div className="cc"  >Minor <br/>  D</div>
        <div className="cc"  >Negligible <br/> E</div>
        </div>
        <div className="matrixrow">

        <div className="srd" id="frequent">Frequent 5</div>  
        <div className="c5a" id="c5a">5A</div>
        <div className="c5b" id="c5b">5B</div>
        <div className="c5c" id="c5c">5C</div>
        <div className="c5d" id="c5d">5D</div>
        <div className="c5e" id="c5e">5E</div>
        </div>
        <div className="matrixrow">

        <div className="srd" id="Occasional">Occasional 4</div>
        <div className="c4a" id="c4a">4A</div>
        <div className="c4b" id="c4b">4B</div>
        <div className="c4c" id="c4c">4C</div>
        <div className="c4d" id="c4d">4D</div>
        <div className="c4e" id="c4e">4E</div>
        </div>
        <div className="matrixrow">

        <div className="srd" id="Remote">Remote 3</div>
        <div className="c3a" id="c3a">3A</div>
        <div className="c3b" id="c3b">3B</div>
        <div className="c3c" id="c3c">3C</div>
        <div className="c3d" id="c3d">3D</div>
        <div className="c3e" id="c3e">3E</div>
        </div>
        <div className="matrixrow">
        
        <div className="srd" id="IMP">Improbable 2</div>
        <div className="c2a" id="c2a">2A</div>
        <div className="c2b" id="c2b">2B</div>
        <div className="c2c" id="c2c">2C</div>
        <div className="c2d" id="c2d">2D</div>
        <div className="c2e" id="c2e">2E</div>
        </div>
        <div className="matrixrow">

        <div className="srd" id="EXTREMELYIMP">Extremely Improbable 1</div>
        <div className="c1a" id="c1a">1A</div>
        <div className="c1b" id="c1b">1B</div>
        <div className="c1c" id="c1c">1C</div>
        <div className="c1d" id="c1d">1D</div>
        <div className="c1e" id="c1e">1E</div>
        </div>
        </Box>  
     {/** */}
        <Typography >Consequences </Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

          <TextField
            label="Consequences "
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            value={Consequences}
            defaultValue={Consequences}
            onChange={(e)=>{setConsequences(e.target.value)}}
             
          />

        </Stack>
      
         {/**      */}

         {/**        */}
        <Typography >ASSESSMENT </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <TextField
            label="Analysis"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            value={repAnalysis}
            defaultValue={repAnalysis}
            onChange={(e)=>{setrepAnalysis(e.target.value)}}
             
          />
 
{/**    Root_cause  select  old -> treeViewArr */}
{false &&   <> 
        <Typography >Root Cause </Typography>
        <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded} 
        onNodeSelect={handle_node_selection}
        selected={selectedItems}
        sx={{ height: 440, flexGrow: 1, maxWidth: 800, overflowY: "auto" }}
        >
        {displayTreeView(my_tree_arr.current)}
      </TreeView>
      </>
         }
 {/** */}
     {false &&   <>  <Typography >Selected Root Cause  </Typography>
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <TextField
            label="Selected Root Cause"
      
            readOnly={true}
            value={Selected_Root_Cause}
            // onChange={(e)=>{setdat(e.target.value);}}
            variant="filled"
            
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
    </>
         }
        <Stack sx={{ gap: 2 }} direction={"column"}>
        
        <Box sx={{ textAlign: "left" }}>
        <Stack sx={{ gap: 2 }} direction={"column"}>
     
{/** */}
          <Typography >  Root Main  </Typography>
          <Select   
          labelId="demo-simple-select-autowidth-rootroot"
          id="demo-simple-select-autowidth3rootroot"
          autoWidth
          label="Root cause Category" 
          defaultValue={ rootroot_val}
          value={ rootroot_val} 
          variant="filled" sx={{ flex: 1 ,marginBottom:'20px'}} 
          onChange={(e)=>prepare_cause_item(e.target.value)}
          >
            <MenuItem key="rrk0" value={"Select Root cause Category"}>
                {"Select Root cause Category"}
            </MenuItem>

            {Rrootroot.current.map((option) => (
              <MenuItem key={option.root_cause} value={option.root_cause}>
                {option.root_cause}
              </MenuItem>
            ))}
          </Select>
          
          <Typography >  Root Sub  </Typography>
          <Select   
          labelId="demo-simple-select-autowidth-rootc"
          id="demo-simple-select-autowidth3rootc"
          autoWidth
          label="Root cause Item" 
          defaultValue={ rootchild_val}
          value={ rootchild_val} 
          variant="filled" sx={{ flex: 1 ,marginBottom:'20px'}} 
          onChange={(e)=>setrootchild_val(e.target.value)}
          >
            

            {childNodes_arr.current.map((option) => (
              <MenuItem key={option } value={option }>
                {option }
              </MenuItem>
            ))}
          </Select>

  {/** */}
        </Stack>

          <Button
            type="button"
            sx={{marginRight:'20px', textTransform: "capitalize",backgroundColor:brown[300] }}
            variant="contained"
            
            onClick={(e)=>{Add_to_List(e);}}
          >
           Add to List
          </Button>
          <Button
            type="button"
            sx={{ textTransform: "capitalize",backgroundColor:brown[300] }}
            variant="contained"
            
            onClick={(e)=>{Remove_From_List(e);}}
          >
           Remove From List
          </Button>
        </Box>

     { update_list && <List component="nav" sx={{height:'auto'}} key="list1" aria-label="secondary mailbox folder">
       {list_obj}  
       
      
      </List>
     } 

        </Stack>
      {/** */}
     
{/**    Risk_mitigation_action     */} 
<Box sx={{ textAlign: "center" }}>

{/**    Risk_mitigation_action 1    */} 
<Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 1 </Typography>
            <TextField
            label="Risk mitigation action"
            sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
            multiline
            minRows={4}
            variant="filled"
            value={Risk_mitigation_action1}
            defaultValue={Risk_mitigation_action1}
            onChange={(e)=>{setRisk_mitigation_action1(e.target.value)}}
             
          />
          <br/>

{ /**           Actions */       }

          <Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>
       
          <TextField
            label="Last Review"
            defaultValue={actiondat1L}
            value={actiondat1L}
            onChange={(e)=>{setactiondat1L(e.target.value);}}
            variant="filled"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Next Review"
            defaultValue={actiondat1R}
            value={actiondat1R}
            onChange={(e)=>{setactiondat1R(e.target.value);}}
            variant="filled"
            InputLabelProps={{ shrink: true }}
          />
           <TextField
            label="Reviewed by"
            defaultValue={reviewedby1}
            value={reviewedby1}
            onChange={(e)=>{setreviewedby1(e.target.value);}}
            variant="filled"
            InputLabelProps={{ shrink: true }}
          />
          {/* <Typography >Effectiveness  </Typography> */}
          <Select   
        labelId="demo-simple-select-autowidth-label344"
        id="demo-simple-select-autowidth344"
        autoWidth
          label="Effectiveness" 
          defaultValue={Effectiveness1}
          value={Effectiveness1} 
          variant="filled" sx={{ flex: 1 }} 
          
          onChange={(e)=>handle_Effectiveness1_Change(e.target.value)}
          >
            {Effectiveness_arr.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>

        </Stack>
  </Box>
{/**                                */} 

{/**    Risk_mitigation_action 2    */} 
 { action2_v &&  <Box sx={{ textAlign: "center" }}>


    <Typography  sx={{ textAlign:'left'}}>Action 2 </Typography>
    <TextField
    label="Risk mitigation action"
    sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
    multiline
    minRows={4}
    variant="filled"
    value={Risk_mitigation_action2}
    defaultValue={Risk_mitigation_action2}
    onChange={(e)=>{setRisk_mitigation_action2(e.target.value)}}
      
    />
    <br/>

    { /**           Actions */       }

    <Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

    <TextField
    label="Last Review"
    defaultValue={actiondat2L}
    value={actiondat2L}
    onChange={(e)=>{setactiondat2L(e.target.value);}}
    variant="filled"
    InputLabelProps={{ shrink: true }}
    />
    <TextField
    label="Next Review"
    defaultValue={actiondat2R}
    value={actiondat2R}
    onChange={(e)=>{setactiondat2R(e.target.value);}}
    variant="filled"
    InputLabelProps={{ shrink: true }}
    />
    <TextField
    label="Reviewed by"
    defaultValue={reviewedby2}
    value={reviewedby2}
    onChange={(e)=>{setreviewedby2(e.target.value);}}
    variant="filled"
    InputLabelProps={{ shrink: true }}
    />
    {/* <Typography >Effectiveness  </Typography> */}
    <Select   
    labelId="demo-simple-select-autowidth-label344"
    id="demo-simple-select-autowidth344"
    autoWidth
    label="Effectiveness" 
    defaultValue={Effectiveness2}
    value={Effectiveness2} 
    variant="filled" sx={{ flex: 1 }} 

    onChange={(e)=>handle_Effectiveness2_Change(e.target.value)}
    >
    {Effectiveness_arr.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
    </Select>

    </Stack>

    </Box>
 }
{/**                                */} 

{/**    Risk_mitigation_action 3    */} 
{ action3_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 3 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action3}
defaultValue={Risk_mitigation_action3}
onChange={(e)=>{setRisk_mitigation_action3(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat3L}
value={actiondat3L}
onChange={(e)=>{setactiondat3L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat3R}
value={actiondat3R}
onChange={(e)=>{setactiondat3R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby3}
value={reviewedby3}
onChange={(e)=>{setreviewedby3(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label344"
id="demo-simple-select-autowidth344"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness3}
value={Effectiveness3} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness3_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 

{/**    Risk_mitigation_action 4    */} 
{ action4_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 4 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action4}
defaultValue={Risk_mitigation_action4}
onChange={(e)=>{setRisk_mitigation_action4(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat4L}
value={actiondat4L}
onChange={(e)=>{setactiondat4L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat4R}
value={actiondat4R}
onChange={(e)=>{setactiondat4R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby4}
value={reviewedby4}
onChange={(e)=>{setreviewedby4(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label3444"
id="demo-simple-select-autowidth3444"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness4}
value={Effectiveness4} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness4_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 

{/**    Risk_mitigation_action 5    */} 
{ action5_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 5 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action5}
defaultValue={Risk_mitigation_action5}
onChange={(e)=>{setRisk_mitigation_action5(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat5L}
value={actiondat5L}
onChange={(e)=>{setactiondat5L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat5R}
value={actiondat5R}
onChange={(e)=>{setactiondat5R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby5}
value={reviewedby5}
onChange={(e)=>{setreviewedby5(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label3445"
id="demo-simple-select-autowidth3445"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness5}
value={Effectiveness5} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness5_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 

{/**    Risk_mitigation_action 6    */} 
{ action6_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 6 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action6}
defaultValue={Risk_mitigation_action6}
onChange={(e)=>{setRisk_mitigation_action6(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat6L}
value={actiondat6L}
onChange={(e)=>{setactiondat6L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat6R}
value={actiondat6R}
onChange={(e)=>{setactiondat6R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby6}
value={reviewedby6}
onChange={(e)=>{setreviewedby6(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label3446"
id="demo-simple-select-autowidth3446"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness6}
value={Effectiveness6} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness6_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 

{/**    Risk_mitigation_action 7    */} 
{ action7_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 7 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action7}
defaultValue={Risk_mitigation_action7}
onChange={(e)=>{setRisk_mitigation_action7(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat7L}
value={actiondat7L}
onChange={(e)=>{setactiondat7L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat7R}
value={actiondat7R}
onChange={(e)=>{setactiondat7R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby7}
value={reviewedby7}
onChange={(e)=>{setreviewedby7(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label3447"
id="demo-simple-select-autowidth3447"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness7}
value={Effectiveness7} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness7_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 

{/**    Risk_mitigation_action 8    */} 
{ action8_v &&  <Box sx={{ textAlign: "center" }}>


<Typography  sx={{ textAlign:'left'}}>Action 8 </Typography>
<TextField
label="Risk mitigation action"
sx={{ flex: 1 ,width:'100%',marginBottom:'20px'}}
multiline
minRows={4}
variant="filled"
value={Risk_mitigation_action8}
defaultValue={Risk_mitigation_action8}
onChange={(e)=>{setRisk_mitigation_action8(e.target.value)}}
  
/>
<br/>

{ /**           Actions */       }

<Stack sx={{ gap: 2,marginBottom:'20px' }} direction={"row"}>

<TextField
label="Last Review"
defaultValue={actiondat8L}
value={actiondat8L}
onChange={(e)=>{setactiondat8L(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Next Review"
defaultValue={actiondat8R}
value={actiondat8R}
onChange={(e)=>{setactiondat8R(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
<TextField
label="Reviewed by"
defaultValue={reviewedby8}
value={reviewedby8}
onChange={(e)=>{setreviewedby8(e.target.value);}}
variant="filled"
InputLabelProps={{ shrink: true }}
/>
{/* <Typography >Effectiveness  </Typography> */}
<Select   
labelId="demo-simple-select-autowidth-label3448"
id="demo-simple-select-autowidth3448"
autoWidth
label="Effectiveness" 
defaultValue={Effectiveness8}
value={Effectiveness8} 
variant="filled" sx={{ flex: 1 }} 

onChange={(e)=>handle_Effectiveness8_Change(e.target.value)}
>
{Effectiveness_arr.map((option) => (
  <MenuItem key={option.value} value={option.value}>
    {option.label}
  </MenuItem>
))}
</Select>

</Stack>

</Box>
}
{/**                                */} 


  <Box sx={{ textAlign: "center" }}>
          <Button
            type="button"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<AddBoxIcon />}
            onClick={(e)=>{Add_Action(e);}}
          >
          Add Action
          </Button>
  </Box>

</Box> 


{/**    Recommendations   
          <TextField
            label="Recommendations"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            value={Recommendations}
            defaultValue={Recommendations}
            onChange={(e)=>{setRecommendations(e.target.value)}}
             
          /> 
          
  */}

{/**    Conclusions    */}
            <TextField
            label="Conclusions"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            value={Conclusions}
            defaultValue={Conclusions}
            onChange={(e)=>{setConclusions(e.target.value)}}
             
          />



{/**    Event_type     select   Technical - Human - orgizational -  enviromental  */}

{/**    Classification1     select    HAZARD - ERROR   -  NEARMISS   */}


{/**    Classification2     select  Interal -  External */}


{/**    Classification3     select  Confidential  -  Not Confidential */}


{/**    Classification4     select   Proactive -  Reactive    */}

{/**   JUST CULTURE    */}


 
          
          
          
            

        </Stack>
{/** */}
  

        
{/**  new_or_existing.current  */}
        <Box sx={{ textAlign: "center" }}>
          <Button
            type="button"
            sx={{ textTransform: "capitalize",backgroundColor:brown[300] }}
            variant="contained"
            startIcon={<CloudUpload />}
            onClick={(e)=>{SaveAnalysis(e);}}
          >
           Save
          </Button>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Button
            type="button"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<CloudUpload />}
            onClick={(e)=>{Close(e);}}
          >
           Close
          </Button>
        </Box>
      </Paper>
    </Box>
    </form>

  );
}
