export default [
  {
    title: "Safety policy and objectives",
    path: "/SafetyPolicy",
    priv: "user",
  },
  {
    title: "Reporting Department",
    path: "/Reports",
    priv: "user",
  },
  {
    title: "Investigation Dep",
    path: "/group5",
    priv: "user",
  },

  {
    title: "Risk Dep",
    path: "/group6",
    priv: "user",
  },

  {
    title: "Safety risk management",
    path: "/SafetyRisk",
    priv: "user",
  },
  {
    title: "Safety assurance",
    path: "/SafetyAssurance",
    priv: "user",
  },
  {
    title: "Safety Promotion",
    path: "/SafetyPromotion",
    priv: "user",
  },
 
 
  {
    title: "Follow up analysis",
    path: "/Follow_up_analysis",
    priv: "user",
  },
];
