import { useState } from "react";
import axios from "axios";
import "./Login.css";
import { Link } from "react-router-dom";

export default function Login_as() {
  const [user, setUesr] = useState("");
  const [password, setPassword] = useState("");
  console.log(user);
  console.log(password);
  /* start */
  const dopost = async () => {
    const post = {
      user: user,
      password: password,
    };
    var res0 = "";
    try {
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];

      //----------------------------------

      res = await axios.post(
        "https://attendance1.internet-online.net/b1.aspx",
        post
      );

      console.log(res.data + " From " + res0.data);

      if (res.data.includes("doooooone") === true) {
      }
    } catch (error) {
      console.error(error.response.data);
    }
  };

  /* End   */
  function submit(e) {
    e.preventDefault();
    console.log(user + "      ========   " + password);
    if (user === "qqq" && password === "123") {
      console.log(user + "      ///////   " + password);
      return <Link to="/group">ttttt </Link>;
    }
  }

  return (
    <div className="container">
      <div className="center">
        <h1>SMS Login </h1>
        <form onSubmit={submit}>
          <div className="txt_field">
            <input
              type="text"
              required
              value={user}
              onChange={(e) => setUesr(e.target.value)}
            />
            <span></span>
            <label> Username </label>
          </div>
          <div className="txt_field">
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span></span>
            <label> Password </label>
          </div>
          <input className="btn-login" type="submit" value="Login" />
          <Link to="/" className="btn-login">
            Login
          </Link>
        </form>
      </div>
    </div>
  );
}
