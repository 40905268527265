import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    gridClasses,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
  } from "@mui/material";
  import { useTheme } from "@mui/material";
  import { DataGrid, GridToolbar,useGridApiRef } from "@mui/x-data-grid";
  import { useNavigate } from "react-router-dom";
  
  import { useEffect, useState,useRef } from "react";
  import { green, red } from "@mui/material/colors";
  
   
  import Cookies from 'universal-cookie';
  import axios from "axios";
  
  const rows = [
    {
      id: 1,
      lastName: "عبد الحميد احمد محمد ",
      firstName: "محمد احمد",
      type: "update",
      age: 14,
    },
    { id: 2, lastName: "Lannister", firstName: "Cersei", type: "true", age: 31 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", type: "true", age: 31 },
    { id: 4, lastName: "Stark", firstName: "Arya", type: "true", age: 11 },
    {
      id: 5,
      lastName: "Targaryen",
      firstName: "Daenerys",
      type: "true",
      age: null,
    },
  ];
  
  
  export default function SmsAdminDashBoard() {
  
    const [rowid, setRowId] = useState(null);
    const  thecode = useRef("");
    const the_row_data=useRef([]);
    const apiRef1 = useGridApiRef();
    const apiRef2 = useGridApiRef();
    const apiRef3 = useGridApiRef();
    const theme = useTheme();
  
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [students2, setStudents2] = useState([]);
    const [students3, setStudents3] = useState([]);
    
    const cookies = new Cookies();
    var usercookie="";
    var passcookie="";
    //navigate("/login"); 
  
    useEffect(() => {
      getNotClosedReports("xx");
      getClosedReports("xx");

    }, []);

    const getNotClosedReports = async (xx) => {
       const post = {
        rpnum : xx ,
      };
          
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];
  
       res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/getnotclosedreportsforadmin',
        
       post); 
     
       console.log("res.data");
       console.log(res.data); 
      
      setStudents(res.data);
    };
  
    const getClosedReports = async (xx) => {
      const post = {
       rpnum : xx ,
     };
         
     let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
 
      res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/getclosedreportsforadmin',
       
      post); 
    
      console.log("res.data");
      console.log(res.data); 
     
     setStudents2(res.data);
   };
 

    const getStudentDetails1 = async (xx) => {
      // https://skydreambackendhttps.skydreamsystem.org/api/Product/selectstudentsstatic
      // https://skydreambackendhttps.skydreamsystem.org/api/Product/selectstudentsstaticintervw
      //var xx="5";
      const post = {
        student_code : xx ,
      };
          
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];
  
       res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/selectstudentsstaticbycode',
        
       post); 
     
       console.log("res.data");
       console.log(res.data); 
      
      setStudents(res.data);
    };
  
    const getStudentDetails2 = async (xx) => {
        const post = {
        student_code : xx ,
      };
          
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];
  
       res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/selectstudentsstaticbycode2',
        
       post); 
     
       console.log("res.data");
       console.log(res.data); 
      
      setStudents2(res.data);
    };
  
    const getStudentDetails3 = async (xx) => {
        const post = {
        student_code : xx ,
      };
          
      let res = axios.create();
      res.defaults.timeout = 10000;
      //--------------------------------
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.transformRequest = [
        function (data, headers) {
          var str = [];
          for (var p in data)
            if (data.hasOwnProperty(p) && data[p]) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
              );
            }
          return str.join("&");
        },
      ];
  
       res  = await axios.post(process.env.REACT_APP_BASE_URL+'/api/Product/selectstudentsstaticbycode3',
        
       post); 
     
       console.log("res.data");
       console.log(res.data); 
      
      setStudents3(res.data);
    };
  

    const rows1 = students.map((student) => ({
  
      report_type : student.report_type,
      UTC_time : student.UTC_time,
      signature : student.signature,
      id : student.id,
      report_name : student.report_name,
      
    }));
    const rows2 = students2.map((student) => ({
  
      report_type : student.report_type,
      UTC_time : student.UTC_time,
      signature : student.signature,
      id : student.id,
      report_name : student.report_name,
        }));
 
    
    const columns = [
      { field: "id", headerName: "ID", width: 90 },
      { field: "signature",headerName: "Code",width: 150, editable: true,},
      { field: "report_type",headerName: "Report",width: 150, editable: true,},
      { field: "report_name",headerName: "Report Number",width: 150, editable: true,},
      { field: "UTC_time",headerName: "Date & Time",width: 150, editable: true,},
      
    ];
    const columns2 = [
      { field: "id", headerName: "ID", width: 90 },
      { field: "signature",headerName: "Code",width: 150, editable: true,},
      { field: "report_type",headerName: "Report",width: 150, editable: true,},
      { field: "report_name",headerName: "Report Number",width: 150, editable: true,},
      { field: "UTC_time",headerName: "Date & Time",width: 150, editable: true,},
       
    ];
   
    const show_report=()=>
    {
      const rows2 = apiRef1.current.getSelectedRows();
      var xx;
      rows2.forEach((values, keys) => {
       xx=values.report_name;
       the_row_data.current=values;
       })
       if (xx===undefined)
       {
        alert("Please Select Student Row");
        return;
       }
        console.log(xx );
        cookies.set('amosmobility1rep', xx, { path: '/' });
        
        navigate("/AdminPageOcceranceReport");  
    }
    const show_report_analysis=()=>
    {
      const rows2 = apiRef1.current.getSelectedRows();
      var xx;
      rows2.forEach((values, keys) => {
       xx=values.report_name;
       the_row_data.current=values;
       })
       if (xx===undefined)
       {
        alert("Please Select Student Row");
        return;
       }
        console.log(xx );
        cookies.set('amosmobility1rep', xx, { path: '/' });
        
        navigate("/AdminPageOcceranceReport_Analysis");  
    }
    
    
    const getdata =async(xx)=>
    {
      console.log(xx);
      getStudentDetails1(xx);
      getStudentDetails2(xx);
      getStudentDetails3(xx);
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent:'center',
          //alignItems:'center',
          gap: 2,
        }}
      >
        <Box 
        sx={{color:green[700],
          display:'flex' ,
           justifyContent:"center",
           alignItems:'center',
          flexDirection:'row',
          height:'100px',
          width:'100%',
          }}   
        
       
       >
          Sms Admin DashBoard 
        </Box>
        <Box
        sx={{
          display: "flex",
          justifyContent:'center',
          flexDirection: "row",
          alignItems:"center",
          gap: 2,zIndex:100,
        }}
      >
       
       <Button
         
         sx={{
           width: "200px",
           fontSize: "15",
           fontWeight: "900",
           textTransform: "capitalize",
           backgroundColor: green[200],
         }}
         onClick={() => {
          // navigate("/addstudent");  updatestudentsstaticaccept  student_code  
        
          show_report();
         }}
       >
         Get Selected Report
       </Button>
       <Button
         
         sx={{
           width: "200px",
           fontSize: "15",
           fontWeight: "900",
           textTransform: "capitalize",
           backgroundColor: green[200],
         }}
         onClick={() => {
          // navigate("/addstudent");  updatestudentsstaticaccept  student_code  
        
          show_report_analysis();
         }}
       >
         Analyze Selected Report
       </Button>
 
        </Box>

        <Box
        sx={{
          display: "flex",
         // justifyContent:'center',
          flexDirection: "column",
         // alignItems:"center",
          gap: 2,
          width:'100%',
         
        }}
      >
  
       
          <div style={{ height: "80%", width: "100%",marginBottom:"50px" }}>
          Opened Reports
            <DataGrid
              apiRef={apiRef1}
              rows={rows1}
              columns={columns}
              density="standard"
              sx={{
                [`& .${gridClasses.item}`]: {
                  bgcolor: theme.palette.mode === "light" ? green : red,
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              slots={{ toolbar: GridToolbar }}
              getRowSpacing={(Params) => ({
                top: Params.isFirstVisible ? 0 : 5,
                bottom: Params.isLastVisible ? 0 : 5,
              })}
              onRowSelectionModelChange={(x) => {
                setRowId(x);
                console.log(rowid);
              }}
            />
          </div>
         
          <div style={{ height: "80%", width: "100%",marginBottom:"50px" }}>
          Closed Reports
            <DataGrid
              apiRef={apiRef2}
              rows={rows2}
              columns={columns2}
              density="standard"
              sx={{
                [`& .${gridClasses.item}`]: {
                  bgcolor: theme.palette.mode === "light" ? green : red,
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              slots={{ toolbar: GridToolbar }}
              getRowSpacing={(Params) => ({
                top: Params.isFirstVisible ? 0 : 5,
                bottom: Params.isLastVisible ? 0 : 5,
              })}
              onRowSelectionModelChange={(x) => {
                setRowId(x);
                console.log(rowid);
              }}
            />
          </div>
       
      </Box> 
      
      </Box>
    );
  
   
  }
  