import { CloudUpload } from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import {
  Box,
  Button,
  Select,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";


import Country from "../../country.js";
import Resource from "../../resource.js";
import { green } from "@mui/material/colors";
import axios from "axios";
import { date } from "yup";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Occurrence_Category = [
  {
  value: "Select Occurrence Category",
  label: "Select Occurrence Category",
},{
  value: "Grounded",
  label: "Grounded",
},
{
  value: "Delay",
  label: "Delay",
},
{
  value: "Return to Stand",
  label: "Return to Stand",
},
{

  value: "Divert",
  label: "Divert",
},
{
  value: "Return  from flight",
  label: "Return  from flight",
},]

const Detection_Phase = [
  {
  value: "Select Detection Phase",
  label: "Select Detection Phase",
},
  {
    value: "Operation",
    label: "Operation",
  },
  {
    value: "Taxi",
    label: "Taxi",
  },
  {
    value: "Take-off",
    label: "Take-off",
  },
  {
    value: "Climb",
    label: "Climb",
  },
  {
    value: "Enroute",
    label: "Enroute",
  },
  {
    value: "Descent",
    label: "Descent",
  },
  {
    value: "Approach",
    label: "Approach",
  },
  {
    value: "Landing",
    label: "Landing",
  },
  {
    value: "Hovering",
    label: "Hovering",
  },
  {
    value: "Ground Handling",
    label: "Ground Handling",
  },
  {
    value: "Other",
    label: "Other",
  },
]

const Occurrence_Class = [
  
  {
    value: "Select Occurrence Class",
    label: "Select Occurrence Class",
  },
  {
    value: "Accident",
    label: "Accident",
  },
  {
    value: "Incidet",
    label: "Incidet",
  },
  {
    value: "Serious Incident",
    label: "Serious Incident",
  },
  {
    value: "Technicaal Occurrence Report",
    label: "Technicaal Occurrence Report",
  },
]
const Report_Type = [
  
  {
    value: "Select Report Type",
    label: "Select Report Type",
  },
  {
    value: "Occurrence report ( F118 )",
    label: "Occurrence report ( F118 )",
  },
  {
    value: "Voluntary/ Hazard report ( F117 )",
    label: "Voluntary/ Hazard report ( F117 )",
  },
  {
    value: "Internal investigation report",
    label: "Internal investigation report",
  },
  {
    value: "Internal audit",
    label: "Internal audit",
  },
  {
    value: "External audit",
    label: "External audit",
  },
  {
    value: "Technical services study",
    label: "Technical services study",
  },
  {
    value: "Air safety report",
    label: "Air safety report",
  },
  {
    value: "External investigation report",
    label: "External investigation report",
  },
  {
    value: "Customer complaint",
    label: "Customer complaint",
  },
   
]
const Resource_ac_reg=[
  {
  value: "Select Aircraft Registeration",
  label: "Select Aircraft Registeration",
  }
]
var Reports= [
    
  {
      "report_name": "Select Report"
  }
]

export default function UpdateOcceranceReport() {

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_BASE_URL+"/api/Product/posted_filesAsyncupdate";
  const API_URL_rprt = process.env.REACT_APP_BASE_URL+"/api/Product/selectreportslistbycode";
  const API_URL_rprt_action_attachment = process.env.REACT_APP_BASE_URL+"/api/Product/getattachmentforreportbyrpnum";

  const API_METHOD = 'POST'
  const STATUS_IDLE = 0
  const STATUS_UPLOADING = 1
  const [files, setFiles] = React.useState([]);

  //=================================
  const [name_from_code, setname_from_code] = React.useState('Waiting...');
  const [ata, setata] = React.useState('');
  const [Phone, setPhone] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Code, setCode] = React.useState('');
  const [mandornot, setmandornot] = React.useState('');
  const [reporter_action, setreporter_action] = React.useState('');
  const [pndesc, setpndesc] = React.useState('');
 
  
   {/*        */}

  const [Tech_Log_Ref_WO, setTech_Log_Ref_WO] = React.useState('');
  const [Type_of_Maintenance, setType_of_Maintenance] = React.useState('');
  const [shift_type, setshift_type] = React.useState('');
  const [section, setsection] = React.useState('');

  //setTech_Log_Ref_WO setType_of_Maintenance setshift_type setsection
  const [dat, setdat] = React.useState('');
  const [tim, settim] = React.useState('');
  const [country, setcountry] = React.useState('');
  const [cityval, setcityval] = React.useState('');
  const [location, setlocation] = React.useState('');

  const [Directorate, setDirectorate] = React.useState('');
  const [Department, setDepartment] = React.useState('');
 

  const [headline, setheadline] = React.useState('');
  const [type, settype] = React.useState('');
  const [theclass, setclass] = React.useState('');
  const [phase, setphase] = React.useState('');
  const [category, setcategory] = React.useState('');
  const [highestd, sethighestd] = React.useState('');
  const [thirdpd, setthirdpd] = React.useState('');
  const [injury, setinjury] = React.useState('');
  const [acregis, setacregis] = React.useState('');
  const [stateofreg, setstateofreg] = React.useState('');
  const [thours, setthours] = React.useState('');
  const [tcycles, settcycles] = React.useState('');
  const [pnum, setpnum] = React.useState('');
  const [ser, setser] = React.useState('');
  const [NARRATIVE ,setNARRATIVE] = React.useState('');
  //=================================
  const [action ,setaction] = React.useState([]);
  const [attachment ,setattachment] = React.useState([]);
  const  theaction = useRef([]);
  const  theattachment = useRef([]);
  const  theselectedreport  = useRef('');
  //================================
  const [ac, setac] = React.useState([]);
  const [thecity, setcity] = React.useState([]);
  const [hours, sethours] = React.useState('');
  const [cycles, setcycles] = React.useState('');


 
  const [acval,setacval]= React.useState('Select A/C');

  const [Cityval, setCityval] = React.useState('Select City');
  const [Countryval, setCountryval] = React.useState('Select Country');
  const [Reportval, setReportval] = React.useState('Select Report');


  const [status, setStatus] = React.useState(STATUS_IDLE)
  const [theres, settheres] = React.useState("");
  const ac_lines=useRef([]);
  const theindex=useRef(0);
  
  const the_Reports=useRef([{"report_name": "Select Report" } ]);
  const all_the_Reports=useRef([]);
  const [the_Reports_us, setthe_Reports_us] =
                 React.useState([{"report_name": "Select Report" } ]);
  

  const TheOtherAc=useRef("");

  const [ShowOtherAC, setShowOtherAC] = React.useState(false);

  const city = React.useRef( [ {"city": "Select City"},{"city": "cairo"},]);

  const uploadFiles = async (data)=> {
      setStatus(STATUS_UPLOADING);

      const response1 = await fetch(API_URL, {
          method: API_METHOD,
          body: data,
      })
      .then((res) => res.json())
          
      .then((data) => {
        console.log(data);
     
        console.log(data[0].val);
      })
      .catch((err) => console.error(err))
      .finally(() => 
      {
        setStatus(STATUS_IDLE);
       
        alert("Upload Update done"+theres);
      })
      navigate("/OcceranceReport");
     // const result = await response1.json();console.log(result);  
  }

  const packFiles = (files,data1)=> {
      const data = new FormData();
      
      [...files].forEach((file, i) => {
          data.append(`file-${i}`, file, file.name)
      })
      console.log("data1 before append");
      console.log(data1);
      data.append('occ_date', data1.occ_date);
      data.append('occ_time', data1.occ_time);
      data.append('country', Countryval);
      data.append('city', Cityval);
      data.append('ext_occno', data1.ext_occno);
      data.append('header', data1.header);
      data.append('occ_class', data1.occ_class);
      data.append('detection_phase', data1.detection_phase);
      data.append('occ_category', data1.occ_category);
      data.append('highest_damage', data1.highest_damage);
      //data.append('third_party_damage', data1.third_party_damage);
      data.append('injury_level', data1.injury_level);
      data.append('ac_reg', acval);
      data.append('state_reg', data1.state_reg);
      data.append('ac_total_h', hours);
      data.append('ac_total_c', cycles);
      data.append('part_number', data1.part_number);
      data.append('serial_number', data1.serial_number);
      //--------------------------------------------------
      var currentdate = new Date(); 
      var datetime = " : " + currentdate.getDate() + "/"
                      + (currentdate.getMonth()+1)  + "/" 
                      + currentdate.getFullYear() + " @ "  
                      + currentdate.getHours() + ":"  
                      + currentdate.getMinutes() + ":" 
                      + currentdate.getSeconds();
      //------------------------------------------------------
      data.append('third_party_damage'," added at "+ datetime+" "+ data1.narrative_text);
      data.append('report_type', data1.report_type);
      data.append('OtherAc', data1.OtherAc );//   TheOtherAc.current);
 
      data.append('user_id', '65479');
      data.append('rpnum',  theselectedreport.current);
      console.log("data"); 
      console.log(data);
      return data
  }

  const handleUploadClick = () => {
      if (files.length) {
          const data = packFiles(files)
          uploadFiles(data)
      }
  }

  const renderFileList = () => (
  <ol className="flist">
      {[...files].map((f, i) => (
          <li key={i}>
            {f.name} 
            {/* - {f.type} */}
            </li>
      ))}
  </ol>)

  const renderButtonStatus = () => (
      (status === STATUS_IDLE) ? 
          'Send to server' : 
          <img src = "load.svg" style={{height:"40px"}} />
  )
 
  const get_name_from_code=async(datac)=>
    {
         // https://inventory.internet-online.net/api/Product/selectcitiesforcountry
      
         const post = {
          code : datac,
        };
        
      
         const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/getnamefromcode";
         let res = axios.create();
         res.defaults.timeout = 10000;
         //--------------------------------
         axios.defaults.headers.post["Content-Type"] =
           "application/x-www-form-urlencoded";
         axios.defaults.transformRequest = [
           function (data, headers) {
             var str = [];
             for (var p in data)
               if (data.hasOwnProperty(p) && data[p]) {
                 str.push(
                   encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
                 );
               }
             return str.join("&");
           },
         ];
      
          res  = await axios.post( api_ac, post); 
         let cc= res.data;//['data'];  
       
         console.log("name from code"); 
         console.log(cc); 
 
         setname_from_code(cc[0].val);
        
       
    }  

  const { register, handleSubmit } = useForm();
  const [dense, setDense] = React.useState(true);
  const the_ac_list=useRef(
    [
      {
        "ac": "Select A/C"
      },
      
  ]);

  const onSubmit = (data1) => {

    console.log(data1);

   // if (files.length) {}
      const data = packFiles(files,data1)
      uploadFiles(data)
  

  };


  const get_ac=async(data)=>
  {
    const api_ac=process.env.REACT_APP_BASE_URL+"/api/Product/get_ac_list";

    const response1 = await fetch( api_ac , {
      method: API_METHOD,
      body: data,
    })
    .then((res) => res.json())
      
    .then((data) => {

    //console.log(data[0].val);
    var data_total=data[0].val;
    ac_lines.current =data_total.split(';');
    // console.log(ac_lines);
    //  const the_ac_list=useRef([{"ac": "Select A/C"},]);
    the_ac_list.current=[];
    the_ac_list.current.push({"ac":"Select A/C"});
    for (let y=0;y<ac_lines.current.length-1;y++)
    {
      the_ac_list.current.push({"ac": ac_lines.current[y].split(',')[0]});
    }
    the_ac_list.current.splice(124, 1);
    the_ac_list.current.push({"ac": "Other"});
    console.log(the_ac_list.current);

    setac(the_ac_list.current);
    })
    .catch((err) => console.error(err))
    .finally(() => 
    {
    
    })

  }
  
  const get_city=async(datac)=>
  {
   // https://inventory.internet-online.net/api/Product/selectcitiesforcountry

   const post = {
    country : datac,
  };
  

   const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/selectcitiesforcountry";
   let res = axios.create();
   res.defaults.timeout = 10000;
   //--------------------------------
   axios.defaults.headers.post["Content-Type"] =
     "application/x-www-form-urlencoded";
   axios.defaults.transformRequest = [
     function (data, headers) {
       var str = [];
       for (var p in data)
         if (data.hasOwnProperty(p) && data[p]) {
           str.push(
             encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
           );
         }
       return str.join("&");
     },
   ];

    res  = await axios.post( api_ac, post); 
   let cc= res.data;//['data'];  
 
   console.log("cc"); 
   console.log(cc); 
   city.current=[];
   city.current.push({"city": "Select City"});
   for (let y=0;y<cc.length;y++)
   {
    city.current.push({"city": cc[y].name});
   }
   console.log(city.current);
   setcity(city.current);
   
   

  }
  
  const get_reports_list = async(dataa) => {
    var tt=dataa;
    const post = {
      code : tt,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( API_URL_rprt, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
      
     all_the_Reports.current=cc;

     console.log("cc length"); 
     console.log(cc.length); 
    
     the_Reports.current=[];
   //  the_Reports.current.push({"report_name": "Select Report" });
     for (let y=0;y<cc.length;y++)
     {
      the_Reports.current.push({"report_name": cc[y].report_name });
      console.log(the_Reports.current); 
    
     }
     setthe_Reports_us(the_Reports.current);
      //setReportval('Select Report');
  
  }
  const get_report_data = () => {

  }
  const selected_report_changed = (event) => {
    // alert ( event.target.value );
    console.log("Selected report"); 
    console.log(event.target.value); 

    theselectedreport.current=event.target.value;

    for (let pp=0;pp<all_the_Reports.current.length;pp++)
    {
     // console.log("the_Reports.current[pp]"); console.log(the_Reports.current[pp]); 
      if (all_the_Reports.current[pp].report_name===event.target.value)
      {
        theindex.current=pp;
        console.log("theindex.current"); 
        console.log(theindex.current); 

      }
    }
    console.log("all_the_Reports.current[theindex.current].UTC_date"); 
    console.log(all_the_Reports.current[theindex.current].UTC_date); 
    
    // =========  fill ==================================
    //  country cityval location headline type theclass phase category highestd thirdpd
    //  injury acregis stateofreg thours tcycles pnum ser NARRATIVE 


    /* "State_of_occ_Level1": "Antigua And Barbuda","area_of_occ_level2": "Potters Village",
      "Location name": "566","Headline": "ffff","Occurrence_class": "Accident","Detection_phase": "Approach",
      "Occurrence_category": "Grounded","highest_damage": "big","third_party_damage": "nott",
      "injury_level": "wawa","ac_registeration": "GDJ","state_of_registry": "Bangladesh",
      "Aircraft_total_time": 1275853,"Aircraft_total_cycles": 20411,"part_name": "part name",
      "part_number": "q4554","part_serial": "er1234","NARRATIVE": "treter 56546546",
      "report_type": "Voluntary/ Hazard report ( F117 )", */
    setdat(all_the_Reports.current[theindex.current].UTC_date);
    settim(all_the_Reports.current[theindex.current].UTC_time);
    setcountry(all_the_Reports.current[theindex.current].State_of_occ_Level1);
    setcityval(all_the_Reports.current[theindex.current].area_of_occ_level2);
    setlocation(all_the_Reports.current[theindex.current]['Location name']);
     
    setDepartment(all_the_Reports.current[theindex.current]['Location name'].split('*')[1]);
    setDirectorate(all_the_Reports.current[theindex.current]['Location name'].split('*')[0]);

    setheadline(all_the_Reports.current[theindex.current].Headline);
    settype(all_the_Reports.current[theindex.current].report_type);
    setclass(all_the_Reports.current[theindex.current].Occurrence_class);
    setphase(all_the_Reports.current[theindex.current].Detection_phase);
    setcategory(all_the_Reports.current[theindex.current].Occurrence_category);
    sethighestd(all_the_Reports.current[theindex.current].highest_damage);
    setthirdpd(all_the_Reports.current[theindex.current].third_party_damage);
    setinjury(all_the_Reports.current[theindex.current].injury_level);
    setacregis(all_the_Reports.current[theindex.current].ac_registeration);
    setstateofreg(all_the_Reports.current[theindex.current].state_of_registry);
    setthours(all_the_Reports.current[theindex.current].Aircraft_total_time);
    settcycles(all_the_Reports.current[theindex.current].Aircraft_total_cycles);
    

    setpndesc(all_the_Reports.current[theindex.current].part_name);

    setpnum(all_the_Reports.current[theindex.current].part_number);
    setser(all_the_Reports.current[theindex.current].part_serial);
    setNARRATIVE(all_the_Reports.current[theindex.current].NARRATIVE);

    setreporter_action(all_the_Reports.current[theindex.current].
      third_party_damage.split('#01550834905#')[0]);

    setCode(all_the_Reports.current[theindex.current].signature);
    setEmail(all_the_Reports.current[theindex.current].
      highest_damage.split('#01550834905#')[1]);

    setPhone(all_the_Reports.current[theindex.current].
      highest_damage.split('#01550834905#')[0]);

    setata(all_the_Reports.current[theindex.current].
      highest_damage.split('#01550834905#')[2]);

       //   
       setsection(all_the_Reports.current[theindex.current].
        highest_damage.split('#01550834905#')[6]);

       setshift_type(all_the_Reports.current[theindex.current].
        highest_damage.split('#01550834905#')[7]); 
        
       setType_of_Maintenance(all_the_Reports.current[theindex.current].
        highest_damage.split('#01550834905#')[8]);

       setTech_Log_Ref_WO(all_the_Reports.current[theindex.current].
        highest_damage.split('#01550834905#')[9]);

    get_mand_name_from_index(all_the_Reports.current[theindex.current].signature_name);
  
            
    get_name_from_code(all_the_Reports.current[theindex.current].signature); 
    get_action(event.target.value);

  }

  const get_action=async(trt)=>
  {
    
     
      const post = {
        rpnum : trt,
      };
      
     let res = axios.create();
       res.defaults.timeout = 10000;
       //--------------------------------
       axios.defaults.headers.post["Content-Type"] =
         "application/x-www-form-urlencoded";
       axios.defaults.transformRequest = [
         function (data, headers) {
           var str = [];
           for (var p in data)
             if (data.hasOwnProperty(p) && data[p]) {
               str.push(
                 encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
               );
             }
           return str.join("&");
         },
       ];
    
        res  = await axios.post( API_URL_rprt_action_attachment, post); 
       let cc= res.data;//['data'];  
     
       console.log("cc"); 
       console.log(cc); 
        
       theaction.current=cc;
  
       console.log("cc length"); 
       console.log(cc.length); 
      
       theaction.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theaction.current.push(cc[y].action );
        console.log("theaction.current"); 
        console.log(theaction.current); 
      
       }

       
  
       
      
       theattachment.current=[];
     
       for (let y=0;y<cc.length;y++)
       {
        theattachment.current.push(cc[y].attachements );
        console.log("theattachment.current"); 
        console.log(theattachment.current); 
      
       }

       //  attachements
       console.log("getting action"); 
      
       setaction(theaction.current);
       setattachment(theattachment.current);
    
  }
 
  const get_mand_name_from_index=async(tyu)=>
  {
    if (tyu>0){
    const api_ac = process.env.REACT_APP_BASE_URL+"/api/Product/getreportablefromindex";
     
    //-------------------------
    const post = {
      index : tyu,
    };
    
   let res = axios.create();
     res.defaults.timeout = 10000;
     //--------------------------------
     axios.defaults.headers.post["Content-Type"] =
       "application/x-www-form-urlencoded";
     axios.defaults.transformRequest = [
       function (data, headers) {
         var str = [];
         for (var p in data)
           if (data.hasOwnProperty(p) && data[p]) {
             str.push(
               encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
             );
           }
         return str.join("&");
       },
     ];
  
      res  = await axios.post( api_ac, post); 
     let cc= res.data;//['data'];  
   
     console.log("cc"); 
     console.log(cc); 
      
     theaction.current=cc;

     console.log("cc mand  length"); 
     console.log(cc.length); 
    
   
 
     console.log("mand"); 
     console.log(cc[0].reportableittem);

    setmandornot (cc[0].reportableittem);
  }else{setmandornot ("Not Mandatory");}
  }


  useEffect(()=>
  {
    get_reports_list("65479");
  //get_ac(" ");
  },[]);

  const handle_country_Change = (event) => {
    setCountryval(event.target.value);
    get_city(event.target.value);
   // alert ( event.target.value );
  };
  const handle_city_Change = (event) => {
    setCityval(event.target.value);
   // alert ( event.target.value );
  };
  
  const handle_ac_Change = (event) => {

   // hours.current cycles.current
   if (event.target.value.trim()==="Other")
   {

    alert ( "Other" );
    setacval(event.target.value);
    sethours("");
    setcycles("");
    console.log("return");
    setShowOtherAC(true);
    return ;
   }

   setShowOtherAC(false);

   console.log("not return");

   for (let y=0;y<ac_lines.current.length-1;y++)
   {
    console.log(ac_lines.current[y].split(',')[0]);
     if (ac_lines.current[y].split(',')[0]===event.target.value)
     {
      console.log('found');
      sethours(ac_lines.current[y].split(',')[1]);

      setcycles(ac_lines.current[y].split(',')[2]);
         
    
 
     }  
   }

    setacval(event.target.value);


    alert ( event.target.value );
  };
  

  return (

    <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <Box

      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}

    >
      <Paper
        elevation={17}
        sx={{
          p: "20px",
          width: "100%",
          minWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          fontSize: "25px",
          fontWeight: 800,
        }}
      >
         <Typography >Select Report</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
         
         
          <FormControl  variant="filled" style={{marginTop:0}} sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-label">Select Report</InputLabel>
          <Select 
           labelId="demo-simple-select-autowidth-label"
           
           defaultValue=""
          //  value={Reportval}
           onChange={selected_report_changed}
           
          >
          {
            the_Reports_us.map((option ) => (
            
              <MenuItem 
              key={option.report_name} 
              value={option.report_name}>
                {option.report_name }
              </MenuItem>
          
            ))
           }    
           
          </Select>
         </FormControl>

       
        </Stack>
        {/**                */}
        <Typography >Submitter Data</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
        
        <TextField
          label="Name"
          value={name_from_code}
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
           
        />
         <TextField
          label="Code"
          value={Code}
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          
        />  
        <TextField
          label="Phone"
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          value= {Phone}
           
        />

          <TextField
          label="Email"
          variant="filled"
          sx={{ flex: 1 }}
          fullWidth
          value= {Email} 
         />
          <TextField  label="ATA Chapter" 
          value= {ata}  
          variant="filled" sx={{ flex: 1 }}   
            
           />
          

        </Stack>

        {/**                */}
        <Typography >When/Where</Typography>
        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField
            label="UTC Date"
            
            variant="filled"
            value={dat}
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}
          />
          <TextField label="UTC Time" value={tim}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>

          <TextField label="country" value={country}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>

          <TextField label="cityval" value={cityval}  variant="filled"
                      InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
         
        <TextField label="Directorate" value={Directorate}  variant="filled"
                      InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
 
        <TextField label="Department" value={Department}  variant="filled"
                      InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
 
        </Stack>
        {/*------------------------------------------------------------*/}
                {/*------------------       ------------------------------------------*/}
                <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField
          label="Section" variant="filled" sx={{ flex: 1 }}
          value= {section }
          InputLabelProps={{ shrink: true }}  />
         <TextField
          label="Shift Type" variant="filled" sx={{ flex: 1 }}
          value= {shift_type }
          InputLabelProps={{ shrink: true }}  />
            
         <TextField
          label="Type of Maintenance" variant="filled" sx={{ flex: 1 }}
          value= {Type_of_Maintenance }
          InputLabelProps={{ shrink: true }}  />
            
           {/*  Tech_Log_Ref_WO  Type_of_Maintenance  shift_type section */}
         <TextField
          label="Tech Log Ref/W O" variant="filled" sx={{ flex: 1 }}
          value= {Tech_Log_Ref_WO }
          InputLabelProps={{ shrink: true }}  />
            
          
        
        </Stack>
        {/*------------------ section  shift_type Type_of_Maintenance Tech_Log_Ref_WO ------------------------------------------*/}
       
        <Typography >Mandatory Or Not</Typography>
        
        <TextField  label="Mandatory Or Not" 
        value={mandornot}
         
        variant="filled" sx={{ flex: 1 }} 
         />

        
        {/**                                             */}
        {/*------------------------------------------------------------*/}
        <Typography >What</Typography>
        <TextField label="headline" value={headline}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
        {/**                                             */}
        <Typography >Report Type</Typography>
        <TextField label="type" value={type}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
 
        {/**                                             */}
        <Typography >Occurrence</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
{//           highestd thirdpd
 //  injury acregis stateofreg thours tcycles pnum ser NARRATIVE           
}

         <TextField label="theclass" value={theclass}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
         <TextField label="Detection phase Maintenance" value={phase.split('*')[1]}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
         <TextField label="Detection phase" value={phase.split('*')[0]}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
         <TextField label="Occurrence Affect" value={category}  variant="filled"
            InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
  
        </Stack>
        {/* <Typography >Damage</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

        { //            
          //  injury acregis stateofreg thours tcycles pnum ser NARRATIVE           
        }

        <TextField label="highestd" value={highestd}  variant="filled"
        InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
        <TextField label="thirdpd" value={thirdpd}  variant="filled"
        InputLabelProps={{ shrink: true }}  sx={{ flex: 1 }}/>
                
        </Stack> */}
        <Typography >Injuries</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>

        { //            
          //   acregis stateofreg thours tcycles pnum ser NARRATIVE           
        }

        <TextField label="injury" value={injury}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>

        </Stack>
        <Typography >Aircraft Identification</Typography>

        <Stack sx={{ gap: 2 }} direction={"row"}>
   
      
        <TextField label="acregis" value={acregis}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>

        <TextField label="stateofreg" value={stateofreg}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
   
        <TextField label="thours" value={thours}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
   
       <TextField label="tcycles" value={tcycles}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
     
       
        </Stack>

        <Typography >(ENGINE / UNIT / TOOL ) INFORMATION</Typography>


        <Stack sx={{ gap: 2 }} direction={"row"}>
        <TextField
            label="Description  "
            sx={{ flex: 1 }}
            variant="filled"
            value={pndesc} 
          />
        <TextField label="part number" value={pnum}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
         
         <TextField label="serial" value={ser}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
{       //                    
        }

        </Stack>
        <Typography >Description ( NARRATIVE )</Typography>

        <Stack sx={{ gap: 2 }} direction={"column"}>
        <TextField 
         multiline
         minRows={4}
        label="NARRATIVE" value={NARRATIVE}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>

        <TextField 
         multiline
         minRows={4}
        label="Action" value={reporter_action}  variant="filled"
        InputLabelProps={{ shrink: true }} sx={{ flex: 1 }}/>
        </Stack>
{}

        <Box sx={{ 
          textAlign: "center",
          width:"100%" ,
          display:"flex",
          justifyContent:'center',
          flexDirection:'column',
          fontSize:"14px",
          }}>
          Added Actions:
           <Box sx={{ textAlign: "center" ,color:green[800],width:"90%"  }}>

           <ol className="flist">
            {[...action].map((f, i) => (
                <li key={i}>
                  {f.split('#01550834905#')[0]} 
                
                  </li>
            ))}
            </ol>
           
           </Box>

           Added Attachments:
           <Box sx={{ textAlign: "center" ,color:green[800],width:"90%"  }}>

           <ol className="flist">
            {[...attachment].map((f, i) => (
                <li key={i}>
                <a href='https://www.google,com' > {f} </a>
                  
                
                  </li>
            ))}
            </ol>
           
           </Box>
        </Box>
        
{}
        <Stack sx={{ gap: 2 }} direction={"row"}>
          <TextField
            label="Action  text"
            sx={{ flex: 1 }}
            multiline
            minRows={4}
            variant="filled"
            {...register("narrative_text")}
          />

        </Stack>
        <Stack direction={'row'}>
        <label  id="file-upload" className="file-upload-c">
         <input type="file" multiple 
         onChange={(e)=> setFiles(e.target.files)}/>
         Select Files
        </label>
          {/* <input type="file" multiple /> 
          <Button 
          onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}
          component="label"  variant="contained" sx={{ width: '250px' }} startIcon={<CloudUpload />}>
          {renderButtonStatus()}   
          </Button>*/}
        </Stack>
        {/* <TextField
          label="Attached files"
          sx={{ flex: 1 }}
          multiline
          minRows={4}
          variant="filled"
          {...register("attached_files")}
        /> ,*/}
        <Box sx={{ 
          textAlign: "center",
          width:"100%" ,
          display:"flex",
          justifyContent:'center',
          fontSize:"14px",
          }}>
        
           <Box sx={{ textAlign: "center" ,color:green[800],width:"50%"  }}>
           {renderFileList()}
           </Box>
        </Box>
        
        <Box sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<CloudUpload />}
          >
           Save
          </Button>
        </Box>
      </Paper>
    </Box>
    </form>

  );
}
